import { useCallback } from 'react';
import { getCookie } from '../core/services';
import { REST_API_URL } from '../custom.config';
import { ID } from '../config';
import { DocumentFilter, DocumentList, DocumentWithStatusList } from '../data-structures/DocumentType';
import { Toast } from '../core/components/layout';
import { t } from 'i18next';

type DocumentsApi = {
  getDocumentsStatus: (activeFilters: DocumentFilter) => Promise<DocumentWithStatusList>;
  getDocuments: () => Promise<DocumentList>;
  getUserOnDocumentList: (namespaces_ids: string[] | undefined) => Promise<{users: string[]}>;
};

export default function useDocumentsApi(): DocumentsApi {
  const getDocumentsStatus = useCallback(async (activeFilters: DocumentFilter) => {
    let baseUrl = new URL(`${REST_API_URL}/api/hyperchat/handle_documents/retrieve_staging`);
    let params = new URLSearchParams();
    Object.entries(activeFilters).map(([key, value]) => {
      if (value !== undefined && value !== null && typeof(value) !== "object"){
        params.append(key, value);
      };
      if (value !== undefined && value !== null && typeof(value) === "object" && key !== "namespaces_names") {
        value.every((element) => {
          params.append(key, element);
          return true;
        });
      }
    });
    const response = await fetch(`${baseUrl}?${params}`,
      {
        headers: {
          "Accept": "application/json",
          "Content-Type": 'application/json',
          "Authorization": `Bearer ${getCookie(`token_${ID}`)}`
      },
      method: 'GET',
    });

    // Check response
    if (response.ok) {
      return await response.json();
    } else {
      let err = await response.json();
      console.log(err);
      Toast("error", t(`errors.documents.retrieve.${err.code}`));
      return [];
    }
  }, []);

  const getDocuments = useCallback(async () => {
    const response = await fetch(`${REST_API_URL}/api/hyperchat/handle_documents/retrieve_documents`, {
      headers: {
          "Authorization": `Bearer ${getCookie(`token_${ID}`)}`
      }
    });

    // Check response
    if (response.ok) {
      return await response.json();
    } else {
      let err = await response.json();
      console.log(err);
      Toast("error", t(`errors.documents.retrieve.${err.code}`));
      return [];
    }
  }, []);

  const getUserOnDocumentList = useCallback(async (namespaces_ids: string[] | undefined) => {
    let baseUrl = new URL(`${REST_API_URL}/api/hyperchat/handle_documents/retrieve_staging_users`);
    let params = new URLSearchParams();
    if (namespaces_ids !== undefined) {
      namespaces_ids.every((element) => {
        if (element !== "" && element !== null && element !== undefined) params.append("_id_namespaces", element);
        return true;
      });
    }

    const response = await fetch(`${baseUrl}?${params}`, {
      headers: {
          "Authorization": `Bearer ${getCookie(`token_${ID}`)}`
      },
    });

    // Check response
    if (response.ok) {
      return await response.json();
    } else {
      let err = await response.json();
      console.log(err);
      Toast("error", t(`errors.users.retrieve.${err.code}`));
      return [];
    }
  }, []);

  return {
    getDocumentsStatus,
    getDocuments,
    getUserOnDocumentList,
  };
}
