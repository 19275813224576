import React from "react";
import { Button, Modal } from "../../../../core/components/layout";
import { Row, Col } from "react-bootstrap";
import { ActiveNamespace, OnDeleteUsersGrantsFromNamespace, OnSetShowModalNamespaceUsersDeletion, SelectedUsers, ShowModalNamespaceUsersDeletion } from "../../types";
import { FaRegTrashCan } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

interface ModalNamespacesUsersDeleteProps {
    showModalNamespaceUsersDeletion: ShowModalNamespaceUsersDeletion,
    activeNamespace: ActiveNamespace,
    selectedUsers: SelectedUsers,
    onDeleteUsers: OnDeleteUsersGrantsFromNamespace,
    onSetShowModalNamespaceUsersDeletion: OnSetShowModalNamespaceUsersDeletion,
}

const ModalNamespacesUsersDeleteComponents:React.FC<ModalNamespacesUsersDeleteProps> = ({
    showModalNamespaceUsersDeletion,
    activeNamespace,   
    selectedUsers,
    onDeleteUsers,
    onSetShowModalNamespaceUsersDeletion

}) => {

    const { t } = useTranslation();

    /**
     *@descr function used as callback to close the deletion modal
     */    
    const onCloseModal = () => {
        onSetShowModalNamespaceUsersDeletion(false);
    }

    /**
     * @descr function used as callback to delete the selected namespaces data
     */
    const onClickDelete = () => {
        onDeleteUsers(activeNamespace, selectedUsers);
        onSetShowModalNamespaceUsersDeletion(false);
    }

    return (
        <Modal
            visible={showModalNamespaceUsersDeletion}
            size="lg"
            onHideHandler={onCloseModal}
        >
            <Row>
                <Col className="text-center">
                    <FaRegTrashCan style={{fontSize: 24, marginTop: "2rem", color: "#C2C2C2"}} />
                </Col>
            </Row>
            <Row>
                <Col className="text-center" style={{padding: "0 5rem", margin: "2rem 0"}}>
                    <h5>
                        {selectedUsers.length === 1 &&
                            <div>
                                <span>{t('views.namespace.grants.modals.delete.single')}</span><br/><br/>
                                <small style={{margin: "1rem 0"}}>{selectedUsers[0].email}</small>
                            </div>
                        }
                        {selectedUsers.length !== 1 &&
                            <div>
                                <span>{t('views.namespace.grants.modals.delete.bulk')}</span><br/><br/>
                                <small style={{margin: "1rem 0"}}>
                                    {selectedUsers.map((item:any, index:any) => {
                                                                if (index < selectedUsers.length-1) return item.email + ", ";
                                                                return item.email + "." })
                                                            }
                                </small>
                            </div>
                        }
                    </h5>
                </Col>
            </Row>
            <Row>
                <Col className="text-center" style={{margin: "0 0 2rem 0"}}>
                    <Button text={t('views.namespace.grants.modals.delete.backButtonText')} variant="secondary" outline={true} onClickHandler={() => onCloseModal()} style={{width: "8rem", marginRight: "1rem"}} /> 
                    <Button text={t('views.namespace.grants.modals.delete.deleteButtonText')} onClickHandler={() => onClickDelete()} style={{width: "8rem"}} /> 
                </Col>
            </Row>
        </Modal>
    );
}

export default ModalNamespacesUsersDeleteComponents;