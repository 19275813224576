import React from "react";
import { Row, Col, Container, Accordion, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getFromStorage } from "../core/services";
import { ID } from "../config";
import { FaListUl, FaRegFolderOpen, FaUserPlus } from "react-icons/fa";
import { GrStatusGood } from "react-icons/gr";
import { VscNewFile } from "react-icons/vsc";
import { BsFileEarmarkCheck } from "react-icons/bs";
import { LuAtom } from "react-icons/lu";

const DOCUMENT_MANAGEMENT_AUTH:any = ["SUPERVISOR", "ADMIN"];
const USER_PERMISSION_MANAGEMENTE_AUTH:any  = ["ADMIN"];


const SidebarFooter:React.FC = () => {

    const { t } = useTranslation();
    const user_data = getFromStorage(`user_${ID}`);

    return (
        <Container>
            <Row>
                <Col>
                    {(DOCUMENT_MANAGEMENT_AUTH.includes(user_data.role.toUpperCase()) ||
                        USER_PERMISSION_MANAGEMENTE_AUTH.includes(user_data.role.toUpperCase())) &&
                            <Accordion className="sidebar-body-accordion" flush={true}>
                                {DOCUMENT_MANAGEMENT_AUTH.includes(user_data.role.toUpperCase()) &&
                                    <Accordion.Item eventKey={"0"} key={"0"}>
                                        <Accordion.Header>
                                            <div style={{fontSize: 16}}>
                                                <FaRegFolderOpen/>
                                                <small style={{marginLeft: ".5rem"}}> {t(`sidebar.documents.title`)} </small>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body style={{color: "#FFF"}}>
                                            <Row style={{marginBottom: "0.5rem"}}>
                                                <Col className="text-start" style={{marginLeft: "22px"}}>
                                                    <Nav.Link href="/documents?add=true">
                                                        <VscNewFile style={{fontSize: 16, marginTop: "-.3rem"}}/>
                                                        <small style={{marginLeft: ".5rem"}}> {t(`sidebar.documents.add`)} </small>
                                                    </Nav.Link>
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: "0.5rem"}}>
                                                <Col className="text-start" style={{marginLeft: "22px"}}>
                                                    <Nav.Link href="/documents">
                                                        <FaListUl style={{fontSize: 16, marginTop: "-.3rem"}}/>
                                                        <small style={{marginLeft: ".6rem"}}> {t(`sidebar.documents.list`)} </small>
                                                    </Nav.Link>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-start" style={{marginLeft: "22px"}}>
                                                    <Nav.Link href="/documents/status">
                                                        <GrStatusGood style={{fontSize: 16, marginTop: "-.3rem"}}/>
                                                        <small style={{marginLeft: ".6rem"}}> {t("sidebar.documents.status")} </small>
                                                    </Nav.Link>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                }
                                {USER_PERMISSION_MANAGEMENTE_AUTH.includes(user_data.role.toUpperCase()) &&
                                    <Accordion.Item eventKey={"1"}>
                                        <Accordion.Header>
                                            <div style={{fontSize: 16}}>
                                                <BsFileEarmarkCheck/>
                                                <small style={{marginLeft: ".5rem"}}> {t(`sidebar.userNamespace.title`)} </small>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body style={{color: "#FFF"}}>
                                            <Row style={{marginBottom: "0.5rem"}}>
                                                <Col className="text-start" style={{marginLeft: "22px"}}>
                                                    <Nav.Link href="/namespaces">
                                                        <LuAtom style={{fontSize: 16}}/>
                                                        <small style={{marginLeft: ".5rem"}}> {t(`sidebar.userNamespace.namespaces`)} </small>
                                                    </Nav.Link>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-start" style={{marginLeft: "22px"}}>
                                                    <Nav.Link href="/users">
                                                        <FaUserPlus style={{fontSize: 16}}/>
                                                        <small style={{marginLeft: ".5rem"}}> {t(`sidebar.userNamespace.users`)} </small>
                                                    </Nav.Link>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                }
                            </Accordion>
                    }
                </Col>
            </Row>
        </Container>
    );
}

export default SidebarFooter;