const initialState = {
    isPendingRequest: false,
    requestError: null,
    requestResponse: null,
    showModalDocumentsConflict: false,
    showModalDocumentIngestion: false,
    showModalDocumentDescription: false,
    showModalDocumentDeletion: false,
    showModalUnregisteredUser: false,
    showFilterSidebar: false,
    userToken: null,
    userInfo: null,
    chatsData: [],
    usersData: [],
    documentsData: [],
    documentsDataConflicts: [],
    namespacesData: [],
    selectedDocuments: [],
    uploadedDocuments: [],
    activeDocument: null,
    filters: {}
}

export default initialState;