import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RxPencil2 } from "react-icons/rx";
import { IoCloudDownload } from "react-icons/io5";
import { Grid, IconButton, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { Nav } from "react-bootstrap";
import { FaRegTrashCan } from "react-icons/fa6";
import Iconify from "../components/iconify";
import CustomPopover, { usePopover } from "../layout/components/custom-popover";
import { Button } from "../core/components/layout";
import { useOutsideClick } from "../hooks/use-outsideClick";
import { Chat } from "../data-structures/ChatType";
import UserInputModal from "./UserInputModal";

type Props = {
  chat_index: number;
  chat: Chat;
  onClickDeleteChat: (chat_id: string) => void;
  handleRename: (chat_id: string, new_title: string) => void;
  handleDownloadChat: (chat_id: string, file_name: string) => void;
};

export default function ChatItem({chat_index, chat, onClickDeleteChat, handleRename, handleDownloadChat}: Props) {
  const popover = usePopover();
  const { t } = useTranslation();
  const [openRename, setOpenRename] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>("");
  const [openDownloadForm, setOpenDownloadForm] = useState<boolean>(false);
  const [chatToDownload, setChatToDownload] = useState<string>("");

  const ref = useOutsideClick(() => {
    setOpenRename(false);
    setNewName("");
  });

  const checkStringLength = (str:string, size:number) => {
    if (str.length > size) return str.slice(0, size) + "...";
    return str;
  };

  const changeNameHandler = (event: any) => {
    setNewName(event.target.value.trim());
  };

  const onClickRename = (event: any, chat_id: string) => {
    event.preventDefault();
    if (newName === "") {
      setOpenRename(false);
      return;
    };
    handleRename(chat_id, newName);
    setOpenRename(false);
  };

  const handleDownload = (chat_id: string) => {
    popover.onClose();
    setChatToDownload(chat_id);
    setOpenDownloadForm(true);
  };

  const handleConfirmDownload = (input: string) => {
    let name = input;
    if (input.split(".").at(-1) === "txt") name = input.split('.').slice(0, -1).join('.');
    setOpenDownloadForm(false);
    handleDownloadChat(chatToDownload, name);
  };

  return (
    <Grid key={chat_index} container sx={{alignItems: "center"}}>
        <Grid item xs={openRename ? 12 : 10}>
          <Stack spacing={1} ref={ref} sx={!openRename ? {paddingLeft: "30px"} : {}}>
          {openRename ?
          <TextField
          autoComplete="off"
          onKeyDown={(event) => {if (event.key === "Enter") onClickRename(event, chat.chat_id)}}
          placeholder={t("sidebar.chat.rename")}
          InputLabelProps={{style: {color: "#adb5bd"}}}
          InputProps={{endAdornment: (
            <Stack sx={{alignItems: "center", paddingRight: 1}}>
              <Grid container>
                <Grid item xs={6}>
                  <IconButton title={t("sidebar.chat.confirm")} onClick={(event) => onClickRename(event, chat.chat_id)} sx={{width: "40", height: "40"}}>
                    <Iconify icon={"ic:baseline-check-circle"} sx={{color: "#12b76a"}}/>
                  </IconButton>
                </Grid>
                <Grid item xs={6}>
                  <IconButton title={t("sidebar.chat.cancel")} onClick={() => {
                    setOpenRename(false);
                    setNewName("");
                  }} sx={{width: "40", height: "40"}}>
                    <Iconify icon={"ic:baseline-cancel"} sx={{color: "#f04438"}}/>
                  </IconButton>
                </Grid>
              </Grid>

              <Typography variant="body2" color={"#adb5bd"} fontSize={"0.6rem"} textAlign={"right"}>
                {`${256 - newName.length} ${t("sidebar.chat.maxLength")}`}
              </Typography>
            </Stack>
            ), style: {color: "#FFF", fontSize: "0.75rem", paddingRight: 0}}}
          inputProps={{maxLength: 256}}
          onChange={changeNameHandler}
          variant="outlined"/>

          :

          <Nav.Link title={chat.chat_title} href={`/chat/${chat.chat_id}`} style={{marginTop: "0.15rem"}}>
              <small style={{color: "#FFF", fontSize: "0.75rem"}}>
                  {chat.chat_title !== null ? checkStringLength(chat.chat_title, 20) : ''}
              </small>
          </Nav.Link>
          }
          </Stack>
        </Grid>
        {!openRename &&
        <Grid item xs={2}>
            <IconButton onClick={popover.onOpen} sx={{width: "40", height: "40"}}>
              <Iconify icon={"flowbite:dots-vertical-outline"} sx={{color: "#FFF"}}/>
            </IconButton>
        </Grid>
        }
        <CustomPopover
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            open={popover.open}
            onClose={popover.onClose}
            sx={{ p: 0, width: 160 }}>
            <MenuItem sx={{fontWeight: 'fontWeightBold', borderRadius: 50}}>
                <Button
                  variant="light"
                  style={{border: "none", color: "red", backgroundColor: "#FFF"}}
                  onClickHandler={() => {
                    onClickDeleteChat(chat.chat_id);
                    popover.onClose();
                  }}>
                  <FaRegTrashCan style={{fontSize: 14, marginRight: ".5rem", textAlign: "left", marginBottom: ".3rem"}} />
                  <small> {t(`sidebar.chat.popupChatAction.delete`)} </small>
                </Button>
            </MenuItem>
            <MenuItem sx={{fontWeight: 'fontWeightBold', borderRadius: 50}}>
                <Button
                  variant="light"
                  style={{border: "none", backgroundColor: "#FFF"}}
                  onClickHandler={() => {
                    setOpenRename(true);
                    popover.onClose();
                  }}>
                  <RxPencil2 style={{fontSize: 14, marginRight: ".5rem", textAlign: "left", marginBottom: ".3rem"}} />
                  <small> {t(`sidebar.chat.popupChatAction.rename`)} </small>
                </Button>
            </MenuItem>
            <MenuItem>
              <Button
                variant="light"
                style={{border: "none", backgroundColor: "#FFF"}}
                onClickHandler={() => handleDownload(chat.chat_id)}>
                  <IoCloudDownload style={{fontSize: 14, marginRight: ".5rem", textAlign: "left", marginBottom: ".3rem"}}/>
                  <small> {t(`sidebar.chat.download`)} </small>
              </Button>
            </MenuItem>
        </CustomPopover>

        <UserInputModal
          singleButton={false}
          button2Text="sidebar.chat.cancel"
          button1Text="sidebar.modals.settings.saveButtonText"
          title="sidebar.chat.title"
          textList={[
              "sidebar.chat.saveText1",
              "sidebar.chat.saveText2"
          ]}
          showModal={openDownloadForm}
          onConfirm={handleConfirmDownload}
          onCloseModal={() => setOpenDownloadForm(false)}/>
    </Grid>
  );
}