import React, { useState, useEffect } from "react";
import { Row, Col, Offcanvas  } from "react-bootstrap";
import InputSearch from "../../../../core/components/form/InputSearch/InputSearch";
import { Button } from "../../../../core/components/layout";
import { Scrollbar } from "react-scrollbars-custom";
import Calendar from "react-calendar";
import { ShowFilterUsersSidebar,OnSetShowFilterUsersSidebar, UserFilters, OnSetUserFilters } from "../../types";
import { useTranslation } from "react-i18next";
import { BsCalendar4 } from "react-icons/bs";
import { CgCloseO } from "react-icons/cg";
import { FaRegUser } from "react-icons/fa6";
import { timestampToDate } from "../../../../core/services/date.services";

const ROLE_OPT = [
    'user',
    'supervisor'
];

// const DEFAULT_FILTER = {
//     date_start: null,
//     date_end: null,
//     policy: ['']
// }

const DEFAULT_FILTER = {};

interface SidebarFiltersProps {
    userFilters: UserFilters,
    showFilterUsersSidebar: ShowFilterUsersSidebar,
    onSetUserFilters: OnSetUserFilters,
    onSetShowFilterUsersSidebar: OnSetShowFilterUsersSidebar
}


const SidebarFiltersUsersComponent:React.FC<SidebarFiltersProps> = ({
        userFilters,
        showFilterUsersSidebar,
        onSetUserFilters,
        onSetShowFilterUsersSidebar,

    }) => {

    const { t } = useTranslation();
    const [advancedFilter, setAdvancedFilter] = useState<any>(DEFAULT_FILTER);
    const [roleOpt, setRoleOpt] = useState<any>(ROLE_OPT);


    /**
     * @descr Omit a specific field from an object passed as an argument
     * @param key field to omit
     * @param obj target object
     * @returns object without the field identify
    */
    function omit(key:any, obj:any) {
        const { [key]: omitted, ...rest } = obj;
        return rest;
    }

    /**
     * @descr function used to hide the advance filter sidebar
     * @param {Object} event click event trigger
     */
    const onClickCloseFilter = (event:any) => {
        onSetShowFilterUsersSidebar(false);
    }

    /**
     * @desc function used to update the filters using search inputs
     * @param {object} data, filter data value
     * @param {string} field, filter item string value
    **/
    const onChangeAdvancedFilter = (field:any, data:any) => {
        if (data.length === 0) {
            let advanced_fitlers:any = omit(field, advancedFilter);
            setAdvancedFilter(advanced_fitlers);
        }
        if (data.length > 0) {
            let advanced_fitlers:any = {...advancedFilter, [field]: data}
            setAdvancedFilter(advanced_fitlers);
        }
    }

    /**
     * @desc function used to update the filters using calendar component
     * @param {object} data, filter data value
     * @param {string} field, filter item string value
    **/
    const onChangeAdvancedDateFilter = (field:any, data:any) => {
        let date_timestamp = data.getTime();
        let advanced_fitlers = {...advancedFilter, [field]: date_timestamp}
        setAdvancedFilter(advanced_fitlers);
    }

    /**
     * @desc function used to save filter object
    **/
    const onClickConfirm = () => {
        onSetUserFilters(advancedFilter);
        onSetShowFilterUsersSidebar(false);
    }

     /**
     * @desc function used to reset filter object
    **/
    const onClickBack = () => {
        if (userFilters.email !== undefined) onSetUserFilters({email: userFilters.email});
        if (userFilters.email === undefined) onSetUserFilters({});
        setAdvancedFilter(DEFAULT_FILTER);
        onSetShowFilterUsersSidebar(false);
    }

    useEffect (() => {
        if (Object.keys(userFilters).length > 0) setAdvancedFilter(userFilters);
        if (Object.keys(userFilters).length === 0) setAdvancedFilter(DEFAULT_FILTER);
    }, [userFilters]);

    useEffect (() => {
        // let languages_opt_translated = ROLE_OPT.map((role:any) => t(`views.namespace.list.sidebar.languageSelection.${role}`));
        setRoleOpt(ROLE_OPT); // TODO tranlations
    }, []);

    return (
        <Offcanvas placement="end" show={showFilterUsersSidebar} style={{zIndex: 99999}} onHide={onClickCloseFilter}>
            <Row style={{margin: "0 0.15rem", padding: "1rem 0 0.5rem 0", borderBottom: "1px solid #CCC"}}>
                <Col>
                    <h6><b>{t('views.namespace.grants.sidebar.title')}</b></h6>
                </Col>
                <Col style={{textAlign: "right"}}>
                    <Button onClickHandler={onClickCloseFilter} variant="light">
                        <CgCloseO style={{fontSize: 20}}/>
                    </Button>
                </Col>
            </Row>

            {/* Filters inputs */}
            <Scrollbar style={{height: "calc(100vh - 115px)"}}>
                <Row style={{margin: "15px 0 0 15px"}}>
                    <Col>
                        <small>
                            <FaRegUser style={{marginRight: ".3rem", fontSize: 14}}/>
                            <b>{t('views.namespace.grants.sidebar.inputLabel')}</b>
                        </small>
                    </Col>
                </Row>
                <Row style={{margin: "10px 0 15px 15px"}}>
                    <Col>
                        <InputSearch
                            label=''
                            placeholder={t('views.namespace.grants.sidebar.inputPlaceholder')}
                            options={roleOpt}
                            selected={advancedFilter.policy}
                            style={{fontSize: 14}}
                            onChangeHandler={(item:any) => { onChangeAdvancedFilter('policy', item)}}
                        />
                    </Col>
                </Row>
                <Row style={{margin: "0 0 0 15px"}}>
                    <Col>
                        <small style={{fontSize: 14}}>
                            <BsCalendar4 style={{marginRight: ".3rem", fontSize: 14}}/>
                            <b>{t('views.namespace.grants.sidebar.dateFrom')} </b>
                            {timestampToDate(advancedFilter.date_start, {format: "YYYY-MM-DD"}) !== null ?
                                `${timestampToDate(advancedFilter.date_start, {format: "YYYY-MM-DD"})}` :
                                ""
                            }
                        </small>
                    </Col>
                </Row>
                <Row style={{margin: "0 0 15px 0"}}>
                    <Col>
                        <div style={{transform: "scale(0.8)"}}>
                            <Calendar
                                value={timestampToDate(advancedFilter.date_start)}
                                onChange={(e:any) => onChangeAdvancedDateFilter('date_start', e)}
                            />
                        </div>
                    </Col>
                </Row>
                <Row style={{margin: "0 15px"}}>
                    <Col >
                        <small style={{fontSize: 14}}>
                            <BsCalendar4 style={{marginRight: ".3rem", fontSize: 14}}/>
                            <b>{t('views.namespace.grants.sidebar.dateTo')} </b>
                            {timestampToDate(advancedFilter.date_end, {format: "YYYY-MM-DD"}) !== null ?
                                `${timestampToDate(advancedFilter.date_end, {format: "YYYY-MM-DD"})}` :
                                ""
                            }
                        </small>
                    </Col>
                </Row>
                <Row style={{margin: "0 0 15px 0"}}>
                    <Col>
                        <div style={{transform: "scale(0.8)"}}>
                            <Calendar
                                value={timestampToDate(advancedFilter.date_end)}
                                onChange={(e:any) => onChangeAdvancedDateFilter('date_end', e)}
                            />
                        </div>
                    </Col>
                </Row>
            </Scrollbar>

            {/* Footer buttons */}
            <Row style={{marginTop: "15px"}}>
                <Col>
                    <Button text={t('views.namespace.grants.sidebar.backButtonText')} variant="secondary" style={{color: "#000", width: "45%", marginLeft: "3%", marginRight: "2%"}} onClickHandler={() => onClickBack()} />
                    <Button text={t('views.namespace.grants.sidebar.confirmButtonText')} variant="primary" style={{width: "45%", marginLeft: "2%"}} onClickHandler={() => onClickConfirm()} />
                </Col>
            </Row>
        </Offcanvas>
    );
}

export default SidebarFiltersUsersComponent;