export const NAMESPACE_ICONS = [
    {
        icon: "#FF4F16",
    },
    {
        icon: "#EB8A06",
    },
    {
        icon: "#E2E200",
    },
    {
        icon: "#9BCA42",
    },
    {
        icon: "#2C950F",
    },
    {
        icon: "#19E5EE",
    },
    {
        icon: "#313DFF",
    },
    {
        icon: "#9831FF",
    }
];