import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { m } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { store } from '../../store/app.store';
import { setRequestResponse } from '../../views/login/sources/login.reducer';
import { deleteCookie, deleteFromStorage, getFromStorage } from '../../core/services';
import { ID } from '../../config';
import CustomPopover, { usePopover } from './custom-popover';
import { useRouter } from '../../hooks/use-router';
import { IconButton, Stack, Tooltip } from '@mui/material';
import LanguagePopover from './language-popover';
import Iconify from '../../components/iconify';

const OPTIONS = [
  {
    label: 'Account',
    linkTo: '',
  },
];

const varHover = (hover = 1.09, tap = 0.97) => ({
  hover: { scale: hover },
  tap: { scale: tap },
});

export default function AccountPopover() {
  const router = useRouter();
  const popover = usePopover();
  const navigate = useNavigate();
  const user_data = getFromStorage(`user_${ID}`);

  const handleLogout = () => {
    store.dispatch(setRequestResponse(null))
    let storage:any = deleteFromStorage(`user_${ID}`),
    cookie:any = deleteCookie(`token_${ID}`);
    if (storage && cookie) navigate('/login');
  }

  const handleClickItem = (path: string) => {
    popover.onClose();
    router.push(path);
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: "40",
          height: "40",
          background: "(theme: { palette: { grey: string[]; }; }) => alpha(theme.palette.grey[500], 0.08)",
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          alt={user_data.email}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }} />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 300, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Tooltip title={user_data.email}>
            <Typography variant="subtitle2" noWrap>
              {user_data.email}
            </Typography>
          </Tooltip>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ m: 1, fontWeight: 'fontWeightBold', borderRadius: 50}}>
          <LanguagePopover />
        </MenuItem>

        {/* <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <hr style={{width: "90%", marginLeft: "5%"}} />

        <MenuItem
          sx={{ m: 1, fontWeight: 'fontWeightBold', borderRadius: 50}}
          onClick={handleLogout}>
          <Stack direction={'row'}>
            <Iconify icon="mdi:logout" />
            <Typography variant="subtitle2">
              Logout
            </Typography>
          </Stack>
        </MenuItem>
      </CustomPopover>
    </>
  );
}
