import {
    setIsPendingRequest,
    setRequestError,
    setRequestResponse
} from "./login.reducer";

import { getUserTokenData } from "./services";


/**
  * Requests to get user login data (ids)
  * @return dispatch redux action
*/
export function requestLoginUser(user_data:any) {

    return async (dispatch:any) => {

        dispatch (setIsPendingRequest(true));
        let user_token_response = await getUserTokenData(user_data);
        if(user_token_response.error !== undefined) {
            return(dispatch(setRequestError(user_token_response.error_description)))
        }

        dispatch(setRequestResponse(user_token_response));

    }
}