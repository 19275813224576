import React, { useRef } from "react"
import {
    Disabled,
    Name,
    ChangeHandler,
    Placeholder,
    Value,
    Required,
    TailComponent,
    Style,
    HeadComponent,
    ChangeNameSpace,
    Label
} from "./types";
import { Form, InputGroup} from "react-bootstrap";
import { IconButton } from "@mui/material";
import Iconify from "../../../../components/iconify";


interface InputTextProps {
    placeholder?: Placeholder,
    name?: Name,
    label?: Label,
    value?: Value,
    disabled?: Disabled,
    required?: Required,
    headComponent?: HeadComponent,
    tailComponent?: TailComponent,
    style?: Style,
    groupStyle?: Style,
    labelStyle?: Style,
    hideAction?: boolean,
    maxLength?: number,
    onChangeHandler?: ChangeHandler,
    onChangeNameSpace?: ChangeNameSpace,
    action?: () => void,
}

const InputTextComponent:React.FC<InputTextProps> = ({
        label = "Text",
        labelStyle = {},
        placeholder = "Type a text",
        name = "",
        value = "",
        tailComponent,
        disabled = false,
        required = false,
        style = {},
        maxLength = undefined,
        headComponent,
        hideAction = false,
        onChangeHandler = event => console.log("onChange event fired"),
        action,
    }) => {
    const dummy = useRef<HTMLInputElement>(null);
    const clearValue = () => {
        action && action();
        if (dummy.current) dummy.current.value = "";
    }

    return (
        <React.Fragment>
            {label !== "" &&
                <Form.Label style={{...labelStyle}}>
                    {label}
                </Form.Label>
            }
            <InputGroup style={style}>
                {(headComponent !== null) &&
                    <InputGroup.Text style={{backgroundColor: "transparent"}}>
                        {headComponent}
                    </InputGroup.Text>
                }
                <Form.Control
                    maxLength={maxLength}
                    ref={dummy}
                    type="text"
                    name={name}
                    disabled={disabled}
                    placeholder={placeholder}
                    required={required}
                    defaultValue={value}
                    style={style}
                    onChange={onChangeHandler}
                />
                {tailComponent &&
                    <InputGroup.Text>
                        {tailComponent}
                    </InputGroup.Text>
            }
                {!hideAction &&
                    <IconButton onClick={clearValue} style={{...labelStyle}}>
                        <Iconify icon="mdi:clear-circle-outline" />
                    </IconButton>
                }
            </InputGroup>

        </React.Fragment>
    );
}

export default InputTextComponent;