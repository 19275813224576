import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { getCookie } from "../core/services";
import { Loader, PrivateComponent } from "../core/components/layout";
import { ID } from "../config";
import { Login } from "../views/login";
import { Chat } from "../views/chat";
import { Documents } from "../views/documents";
import { Namespaces } from "../views/namespace";
import { Users } from "../views/users";
import MainLayout from "../layout/main-layout";
import { DocumentsStatus } from "../views/documents-status";


/**
 * Global App Router
*/

const Router = () => {


    const checkUser = (users?:any) => {

        let user_token = getCookie(`token_${ID}`);

        if(user_token === null) return null;
        if(user_token !== null) return true;
    }

    return (
        <BrowserRouter basename={"/"}>
            <Routes>
                <Route
                    path="/login"
                    Component={(props:any) => { return <Login {...props} /> }}
                />
                <Route
                    path="/*"
                    element={
                        <PrivateComponent
                            redirectionUrl="/login"
                            onRedirection={ () => checkUser(["admin", "supervisor", "user"]) }
                        >
                            <MainLayout>
                                <Suspense fallback={<Loader absolute={true} />}>
                                    <Chat />
                                </Suspense>
                            </MainLayout>
                        </PrivateComponent>
                    }
                />
                <Route
                    path="/chat/*"
                    element={
                        <PrivateComponent
                            redirectionUrl="/login"
                            onRedirection={ () => checkUser(["admin", "supervisor", "user"]) }
                        >
                            <MainLayout>
                                <Suspense fallback={<Loader absolute={true} />}>
                                    <Chat />
                                </Suspense>
                            </MainLayout>
                        </PrivateComponent>
                    }
                />
                <Route
                    path="/documents"
                    element={
                        <PrivateComponent
                            redirectionUrl="/login"
                            onRedirection={ () => checkUser(["admin", "supervisor"]) }
                        >
                            <MainLayout>
                                <Documents />
                            </MainLayout>
                        </PrivateComponent>
                    }
                />
                <Route
                    path="/documents/status"
                    element={
                        <PrivateComponent
                            redirectionUrl="/login"
                            onRedirection={ () => checkUser(["admin", "supervisor"]) }
                        >
                            <MainLayout>
                                <DocumentsStatus />
                            </MainLayout>
                        </PrivateComponent>
                    }
                />
                <Route
                    path="/namespaces"
                    element={
                        <PrivateComponent
                            redirectionUrl="/login"
                            onRedirection={ () => checkUser(["admin"]) }
                        >
                            <MainLayout>
                                <Namespaces />
                            </MainLayout>
                        </PrivateComponent>
                    }
                />
                <Route
                    path="/users"
                    element={
                        <PrivateComponent
                            redirectionUrl="/login"
                            onRedirection={ () => checkUser(["admin"]) }
                        >
                            <MainLayout>
                                <Users />
                            </MainLayout>
                        </PrivateComponent>
                    }
                />
                <Route path="*" element={<>NOT FOUNT PAGE</>} />
            </Routes>
        </BrowserRouter>
    );

}

export default Router;