import React from "react";
import { Button, Modal } from "../../../../core/components/layout";
import { Row, Col } from "react-bootstrap";
import { ActiveUser, OnDeleteUserGrantsFromNamespaces, OnSetShowModalNamespaceDeletion, SelectedNamespaces, ShowModalNamespaceDeletion } from "../../types";
import { FaRegTrashCan } from "react-icons/fa6";
import { useTranslation } from "react-i18next";


interface ModalNamespacesDeleteProps {
    showModalNamespaceDeletion: ShowModalNamespaceDeletion,
    activeUser: ActiveUser,
    selectedNamespaces: SelectedNamespaces,
    onDeleteUserGrantsFromNamespaces: OnDeleteUserGrantsFromNamespaces,
    onSetShowModalNamespaceDeletion: OnSetShowModalNamespaceDeletion,
}

const ModalNamespaceDeleteComponents:React.FC<ModalNamespacesDeleteProps> = ({
        showModalNamespaceDeletion,  
        activeUser, 
        selectedNamespaces,
        onDeleteUserGrantsFromNamespaces,
        onSetShowModalNamespaceDeletion

    }) => {

    const { t } = useTranslation();

    /**
     * @desc function used as callback of close modal event, useful to reset the component state
    **/
    const onCloseModal = () => {
        onSetShowModalNamespaceDeletion(false);
    }

    /**
     * @desc function used to delete user grants from namespaces
    **/
    const onClickDelete = () => {
        onDeleteUserGrantsFromNamespaces(activeUser, selectedNamespaces);
        onSetShowModalNamespaceDeletion(false);
    }

    return (
        <Modal
            visible={showModalNamespaceDeletion}
            size="lg"
        >
            <Row>
                <Col className="text-center">
                    <FaRegTrashCan style={{fontSize: 24, marginTop: "2rem", color: "#C2C2C2"}} />
                </Col>
            </Row>
            <Row>
                <Col className="text-center" style={{padding: "0 5rem", marginTop: "1rem"}}>
                    <h5>
                        {selectedNamespaces.length === 1 &&
                            <div>
                                <span>{t('views.users.grants.modals.delete.single')}</span><br/><br/>
                                <small style={{margin: "1rem 0"}}>{selectedNamespaces[0].name}</small>
                            </div>
                        }
                        {selectedNamespaces.length !== 1 &&
                            <div>    
                                <span>{t('views.users.grants.modals.delete.bulk')}</span><br/><br/>
                                <small style={{margin: "1rem 0"}}>
                                    {selectedNamespaces.map((item:any, index:any) => {
                                                              if (index < selectedNamespaces.length-1) { 
                                                                return (
                                                                    <div>
                                                                        <span>{item.name + ", "}</span><br/>
                                                                    </div> 
                                                                )
                                                            };
                                                            return <span>{item.name + "."}</span> 
                                                        })
                                    }
                                </small>
                            </div>
                        }
                    </h5>
                </Col>
            </Row>
            <Row>
                <Col className="text-center" style={{margin: "2rem 0"}}>
                    <Button text={t('views.users.grants.modals.delete.backButtonText')} variant="secondary" outline={true} onClickHandler={() => onCloseModal()} style={{width: "8rem", marginRight: "1rem"}} />
                    <Button text={t('views.users.grants.modals.delete.deleteButtonText')} onClickHandler={() => onClickDelete()} style={{width: "8rem"}} />
                </Col>
            </Row>
        </Modal>
    );
}

export default ModalNamespaceDeleteComponents;