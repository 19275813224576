import React from "react";
import Form from "react-bootstrap/Form";
//Import input types
import type { Label, CheckLabel, CheckName, Value, Disabled, Required, Checked, Inline, Style, ChangeHandler } from "./types";

interface InputCheckboxProps {
  label?: Label,
  checkLabel?: CheckLabel,
  checkName?: CheckName,
  value?: Value,
  disabled?: Disabled,
  required?: Required,
  checked?: Checked,
  inline?: Inline,
  style?: Style,
  onChangeHandler?: ChangeHandler
}

/*
 * InputCheckbox component
*/
const InputCheckbox: React.FC<InputCheckboxProps> = ({
        label = "",
        checkName = "",
        checkLabel = "Check element",
        value = "",
        disabled = false,
        required = false,
        checked = false,
        inline = false,
        style = {},
        onChangeHandler = event => console.log("onChange event fired")
    }) => {


    return(
        <React.Fragment>

            {label !== "" &&
                <Form.Label>
                {label}
                </Form.Label>
            }
            
            <Form.Check
                type="checkbox"
                label={checkLabel}
                name={checkName}
                defaultChecked={checked}
                disabled={disabled}
                required={required}
                inline={inline}
                defaultValue={value}
                style={style}
                onChange={onChangeHandler} 
            />


        </React.Fragment>
    );
    
}

export default InputCheckbox;