import { connect } from "react-redux";
import { RootState } from "../../../../store/app.store";
import { 
    requestGetNamespacesData,
    requestGetNamespacesIcon,
    requestSetShowFilterSidebar,
    requestSetShowFilterUsersSidebar, 
    requestSetSelectedNamespaces,
    requestSetSelectedUsers,
    requestSetActiveNamespace,
    requestSetShowModalNamespaceConflict,
    requestSetShowModalNamespaceDeletion,
    requestSetShowModalNamespaceCreation,
    requestSetShowModalNamespaceMetadata,
    requestSetShowModalNamespaceUsersDeletion,
    requestSetShowModalUnregisteredUser,
    requestAddNamespace,
    requestAddNamespaceUser,
    requestUpdateNamespaceUsersGrant,
    requestUpdateNamespaceMetadata,
    requestDeleteNamespace,
    requestDeleteUsersGrantsFromNamespace,
    requestSetFilters,
    requestSetUserSession,
    requestDeleteAllChat,
    requestSetUserFilters
    

} from "../namespace.effects";


const withNamespace = connect(
    (state:RootState) => state.namespace, 
    {
        requestGetNamespacesData,
        requestGetNamespacesIcon,
        requestSetShowFilterSidebar,
        requestSetShowFilterUsersSidebar,
        requestSetActiveNamespace,
        requestSetShowModalNamespaceConflict,
        requestSetShowModalNamespaceCreation,
        requestSetShowModalNamespaceMetadata,
        requestSetShowModalNamespaceDeletion,
        requestSetShowModalNamespaceUsersDeletion,
        requestSetShowModalUnregisteredUser,
        requestSetSelectedNamespaces,
        requestSetSelectedUsers,
        requestAddNamespace,
        requestAddNamespaceUser,
        requestUpdateNamespaceUsersGrant,
        requestUpdateNamespaceMetadata,
        requestDeleteNamespace,
        requestDeleteUsersGrantsFromNamespace,
        requestSetFilters,
        requestSetUserSession,
        requestDeleteAllChat,
        requestSetUserFilters

    }
);

export default withNamespace;