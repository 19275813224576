import React, { useEffect } from "react";
import { FormComponent } from "./components";
import {
	IsPendingRequest,
	RequestError,
	RequestResponse,
} from "../types";
import "./login.scss";

interface LoginProps {
	isPendingRequest: IsPendingRequest,
	requestError: RequestError,
	requestResponse: RequestResponse,

}

const LoginContent: React.FC<LoginProps> = ({
		isPendingRequest,
		requestError,
		requestResponse,
	}) => {


	/**
     * useEffect callback used to display a Toast with success or error message
    */
	useEffect(() => {
		if(requestError !== null) console.log(requestError);
	}, [requestError, requestResponse]);

	return (
		<FormComponent
			isPendingRequest={isPendingRequest}
			requestError={requestError}
			requestResponse={requestResponse}
		/>
	);

}

export default LoginContent;