import { ID } from "../../../../config";
import { REACT_APP_REST_API_URL } from "../../../../custom.config";
import { getCookie } from "../../../../core/services";
//Check if rest api url is defined as env variable
const REST_API_URL:string = REACT_APP_REST_API_URL ? REACT_APP_REST_API_URL : `${window.location.origin}`;

const ERROR_STATUS:any = [401, 402, 403, 404, 500];

const handleError = (error_data:any, type:string) => {
    let error:any = new Error();
    error.name = "HyperChat Error";
    error.response = {
        ...error_data,
        type: type
    };

    return error;
}

/**
 * Get chats data
 * @returns {Promise<*>}
*/
export async function getChats() {
    try {

        const response = await fetch(`${REST_API_URL}/api/hyperchat/chat/list_chats`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie(`token_${ID}`)}`
            }
        });

        //Check response
        if (response.ok) {
            return await response.json();
        } else {
            if (ERROR_STATUS.includes(response.status)) {
                let res_err:any = {
                    code: response.status,
                    status: `${response.status} error`
                }
                throw handleError(res_err, 'retrieve');

            } else {
                let res_err:any = await response.json();
                throw handleError(res_err, 'retrieve');
            }
        }

    } catch (error:any) {
        return error.response;
    }
}

/**
  * @desc this function performs a refresh of the retrieving session user token
  * @return array of objects
*/
export async function getUserTokenData(data:any) {

    try {
        const response = await fetch(`https://login.microsoftonline.com/56ac8205-a37e-4856-a53f-686943febf7b/oauth2/v2.0/token`, {
            method: 'POST',
            headers: {
                "Access-Control-Allow-Origin": "*"
            },
            body: data
        });

        // Check response
        if (response.ok) {
            return await response.json();
        } else {
            if(ERROR_STATUS.includes(response.status)) {
                let res_err:any = {
                    code: response.status,
                    status: `${response.status} error`
                }
                throw handleError(res_err, 'check');
            } else {
                let res_err:any = await response.json();
                throw handleError(res_err, 'check');
            }
        }

    } catch (error:any) {
        return { success: false, message: error.message};
    }

}

/**
 * @descr This endpoint retrieve the list of active namepsaces in which the user possessed the given grant.
 * @param user_policy user policy
*/
export async function getNamespacesData(user_policy:any) {
    try {
        const response = await fetch(`${REST_API_URL}/api/hyperchat/handle_namespaces/list_namespaces`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": 'application/json',
                "Authorization": `Bearer ${getCookie(`token_${ID}`)}`
            },
            method: "POST",
            body: JSON.stringify(user_policy)
        });


        // Check response
        if (response.ok) {
            return await response.json();

        } else {
            if (ERROR_STATUS.includes(response.status)) {
                let res_err:any = {
                    code: response.status,
                    status: `${response.status} error`
                }
                throw handleError(res_err, 'retrieveNamespace');

            } else {
                let res_err:any = await response.json();
                throw handleError(res_err, 'retrieveNamespace');
            }
        }

    } catch (error:any) {
        return error.response;
    }
}

/**
 * @descr This endpoint retrieves all users data on the service.
 */
export async function getUsersData() {
    try {
        const response = await fetch(`${REST_API_URL}/api/hyperchat/handle_users/users`, {
            headers: {
                "Authorization": `Bearer ${getCookie(`token_${ID}`)}`
            }
        });

        // Check response
        if (response.ok) {
            return await response.json();
        } else {
            if(ERROR_STATUS.includes(response.status)) {
                let res_err:any = {
                    code: response.status,
                    status: `${response.status} error`
                }
                throw handleError(res_err, 'retrieve');
            } else {
                let res_err:any = await response.json();
                throw handleError(res_err, 'retrieve');
            }
        }

    } catch (error:any) {
        return error.response;
    }
}

/**
 * @descr This endpoint creates a new user with the given email address.
 * @param data email user data
 */
export async function addUser(data:any) {
    try {
        const response = await fetch(`${REST_API_URL}/api/hyperchat/handle_users/user`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": 'application/json',
                "Authorization": `Bearer ${getCookie(`token_${ID}`)}`
            },
            method: 'POST',
            body: JSON.stringify(data)
        });

        // Check response
        if (response.ok) {
            return await response.json();
        } else {
            if (ERROR_STATUS.includes(response.status)) {
                let res_err:any = {
                    code: response.status,
                    status: `${response.status} error`
                }
                throw handleError(res_err, 'add');
            } else {
                let res_err:any = await response.json();
                throw handleError(res_err, 'add');
            }
        }

    } catch (error:any) {
        return error.response;
    }
}

/**
 * @descr This endpoint deletes the user with the given email address.
 * @param data user email data to delete.
 */
export async function deleteUser(data:any) {
    try {
        const response = await fetch(`${REST_API_URL}/api/hyperchat/handle_users/user`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": 'application/json',
                "Authorization": `Bearer ${getCookie(`token_${ID}`)}`
            },
            method: 'DELETE',
            body: JSON.stringify(data)
        });

        // Check response
        if (response.ok) {
            return await response.json();
        } else {
            if (ERROR_STATUS.includes(response.status)) {
                let res_err:any = {
                    code: response.status,
                    status: `${response.status} error`
                }
                throw handleError(res_err, 'delete');
            } else {
                let res_err:any = await response.json();
                throw handleError(res_err, 'delete');
            }
        }

    } catch (error:any) {
        return error.response;
    }
}

/**
 * @descr This endpoint retrieves all grants for the user with the given email address. Grants are returned in descending order of creation date.
 * @param user user email data
 */
export async function getUserGrants(user:any) {
    try {
        const response = await fetch(`${REST_API_URL}/api/hyperchat/handle_users/user_grants?email=${user}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": 'application/json',
                "Authorization": `Bearer ${getCookie(`token_${ID}`)}`
            }
        });

        // Check response
        if (response.ok) {
            return await response.json();
        } else {
            if (ERROR_STATUS.includes(response.status)) {
                let res_err:any = {
                    code: response.status,
                    status: `${response.status} error`
                }
                throw handleError(res_err, 'grants');
            } else {
                let res_err:any = await response.json();
                throw handleError(res_err, 'grants');
            }
        }

    } catch (error:any) {
        return error.response;
    }
}


/**
 * @descr This endpoint deletes the grant for the given user, namespace and policy.
 * @param data data used to remove the relative user policy from a namespace.
 */
export async function deleteUserGrantFromNamespace(data:any) {
    try {
        const response = await fetch(`${REST_API_URL}/api/hyperchat/handle_users/grant`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": 'application/json',
                "Authorization": `Bearer ${getCookie(`token_${ID}`)}`
            },
            method: 'DELETE',
            body: JSON.stringify(data)
        });

        // Check response
        if (response.ok) {
            return await response.json();

        } else {
            if (ERROR_STATUS.includes(response.status)) {
                let res_err:any = {
                    code: response.status,
                    status: `${response.status} error`
                }
                throw handleError(res_err, 'grants');

            } else {
                let res_err:any = await response.json();
                throw handleError(res_err, 'grants');
            }
        }

    } catch (error:any) {
        return error.response;
    }
}

/**
 * @descr This endpoint creates a new grant for the given user, namespace and policy.
 * @param data data used to create the relative user policy to a namespace.
 */
export async function addUserGrantToNamespace(data:any) {
    try {
        const response = await fetch(`${REST_API_URL}/api/hyperchat/handle_users/grant`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": 'application/json',
                "Authorization": `Bearer ${getCookie(`token_${ID}`)}`
            },
            method: 'POST',
            body: JSON.stringify(data)
        });

        // Check response
        if (response.ok) {
            return await response.json();

        } else {
            if (ERROR_STATUS.includes(response.status)) {
                let res_err:any = {
                    code: response.status,
                    status: `${response.status} error`
                }
                throw handleError(res_err, 'grants');

            } else {
                let res_err:any = await response.json();
                throw handleError(res_err, 'grants');
            }
        }

    } catch (error:any) {
        return error.response;
    }
}

/**
  * @desc this function retrieve the namespaces metadata based passased as an argument
  * @arg {Object<any>} namespace_data namespaces data
*/
export async function getNamespacesMetadata(namespace_data:any) {

    try {
        const response = await fetch(`${REACT_APP_REST_API_URL}/api/hyperchat/handle_namespaces/get_namespaces_metadata`, {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie(`token_${ID}`)}`
            },
            body: JSON.stringify(namespace_data)
        });

        // Check response
        if (response.ok) {
            return await response.json();
        } else {
            if(ERROR_STATUS.includes(response.status)) {
                let res_err:any = {
                    code: response.status,
                    status: `${response.status} error`
                }
                throw handleError(res_err, 'metadata');
            } else {
                let res_err:any = await response.json();
                throw handleError(res_err, 'metadata');
            }
        }

    } catch (error:any) {
        return error.response;
    }
}

/**
  * @desc this function allows you to delete all chats owned by the user
  * @param {Object} data user owner email
*/
export async function deleteAllChat(data:any) {
    try {
        const response = await fetch(`${REST_API_URL}/api/hyperchat/chat/private/delete_user_chats`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie(`token_${ID}`)}`
            },
            method: "DELETE",
            body: JSON.stringify(data)
        });

        // Check response
        if (response.ok) {
            return await response.json();
        } else {
            if(ERROR_STATUS.includes(response.status)) {
                let res_err:any = {
                    code: response.status,
                    status: `${response.status} error`
                }
                throw handleError(res_err, 'delete');
            } else {
                let res_err:any = await response.json();
                throw handleError(res_err, 'delete');
            }
        }

    } catch (error:any) {
        return error.response;
    }

}


/**
 * @returns
 */
export async function getUserInfo() {
    try {
        const response = await fetch(`${REST_API_URL}/api/hyperchat/handle_users/user_info`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie(`token_${ID}`)}`
            }
        });

        // Check response
        if (response.ok) {
            return await response.json();
        } else {
            if(ERROR_STATUS.includes(response.status)) {
                let res_err:any = {
                    code: response.status,
                    status: `${response.status} error`
                }
                throw handleError(res_err, 'retrieve');
            } else {
                let res_err:any = await response.json();
                throw handleError(res_err, 'retrieve');
            }
        }

    } catch (error:any) {
        return error.response;
    }

}