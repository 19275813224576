import React, { useState } from "react";
import { Alert as AlertComponent } from "react-bootstrap";
//Import alert types
import type { Variant, Icon, Outline, Dismissible, Children } from "./types";
import { IoWarningOutline, IoInformationCircleOutline } from "react-icons/io5";
import { IoIosThumbsUp } from "react-icons/io";
import { FaFire } from "react-icons/fa";


interface AlertProps {
  variant?: Variant,
  icon?: Icon,
  outline?: Outline
  dismissible?: Dismissible,
  children?: Children
}

/*
 * Alert component
*/
const Alert: React.FC<AlertProps> = ({
    //default props
    variant = "primary",
    icon = true,
    outline = false,
    dismissible = false,
    children
  }) => {

    
  const [show, setShow] = useState(true);

  const switchIcon = (variant:string) => {
      switch(variant) {
        case "success":
          return <IoIosThumbsUp size={18} style={{marginRight: "0.5rem"}} />;
        case "danger":
          return <FaFire size={18} style={{marginRight: "0.5rem"}} />;
        case "warning":
          return <IoWarningOutline size={18} style={{marginRight: "0.5rem"}} />;  
        default:
          return <IoInformationCircleOutline size={18} style={{marginRight: "0.5rem"}} />;
      }
  }

  const variant_types = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"];
  const type = (variant_types.includes(variant) ? variant : "primary");

  return(

    <React.Fragment>
      {show &&
        <AlertComponent variant={(outline) ? type + " outline": type} onClose={() => setShow(false)} dismissible={dismissible}>
          {icon &&

            switchIcon(type)
          }
          {children}
        </AlertComponent>
      }
    </React.Fragment>

  );
}

export default Alert;
