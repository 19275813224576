import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import Scrollbars from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
import { IoSearch } from "react-icons/io5";
import { FaRegPenToSquare, FaRegTrashCan } from "react-icons/fa6";
import { Chats, OnClickAddNewChatHandler, OnClickDeleteAllChat } from "./types";
import useChatApi from "../api/ChatApi";
import ChatItem from "./ChatItem";
import CustomModal from "./CustomModal";
import { Button, Loader } from "../core/components/layout";
import { InputText } from "../core/components/form";
import { groupJsonByKey } from "../core/services";

interface SidebarBodyProps {
    chats: Chats,
    onClickAddNewChatHandler: OnClickAddNewChatHandler,
    onClickDeleteAllChat: OnClickDeleteAllChat,
};

const SidebarBody:React.FC<SidebarBodyProps> = ({
        onClickAddNewChatHandler,
    }) => {
    const [chatFilter, setChatFilter] = useState<any>({dailyChat: "", recentChat: ""});
    const [chatsData, setChatsData] = useState<any>({});
    const [visibleChats, setVisibleChats] = useState<any>([]);
    const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
    const [chatToDelete, setChatToDelete] = useState<string>("");
    const { t } = useTranslation();
    const { getChats, deleteChat, deleteAllChats, renameChat, downloadChat } = useChatApi();
    const location = useLocation();
    const [deleteModalMessage, setDeleteModalMessage] = useState<string>("");
    const [deleteSingleChat, setDeleteSingleChat] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const onChangeInputText = (event:any, field:string) => {
        let filter_data = {
			...chatFilter,
			[field]: event.target.value
		}
		setChatFilter(filter_data);
    };

    useEffect(() => {
        setLoading(true);
        getChats().then((data) => {
            setVisibleChats(data.chats);
            setLoading(false);
        });
    },[getChats]);

    useEffect(() => {
        if(visibleChats.length > 0) {
            let today = new Date().toISOString().split('T')[0];
            let mapped_chats = visibleChats
                                .map((item:any) => {
                                    return {...item,
                                        chat_title: item.chat_title !== null ? item.chat_title : item.chat_id.slice(0, 12),
                                        mapped_chat_date: item.chat_date.split('T')[0] === today ? "dailyChat" : "recentChat"
                                    }
                                });
            setChatsData(groupJsonByKey(mapped_chats, "mapped_chat_date"));
        }
    }, [visibleChats]);

    const clearValue = (chat_group: string) => {
        let filter_data = {
			...chatFilter,
			[chat_group]: "",
		}
		setChatFilter(filter_data);
    };

    const onClickDeleteChat = useCallback((chat_id: string) => {
        setDeleteModalMessage("views.chat.modals.singleChatDelete");
        setChatToDelete(chat_id);
        setOpenConfirmDelete(true);
    },[visibleChats]);

    const onClickDeleteAllChats = useCallback(() => {
        setDeleteSingleChat(false);
        setDeleteModalMessage("views.chat.modals.deleteAll");
        setOpenConfirmDelete(true);
    }, []);

    const confirmedChatDelete = async () => {
        if (deleteSingleChat) {
            let resp = await deleteChat(chatToDelete);
            if (resp === 200) {
                getChats().then((data) => setVisibleChats(data.chats));
            };
            setOpenConfirmDelete(false);
            if ( location.pathname === `/chat/${chatToDelete}`) {
                navigate("/chat");;
            };
            setChatToDelete("");
        } else {
            let resp = await deleteAllChats();
            if (resp === 200) {
                getChats().then((data) => setVisibleChats(data.chats));
                setOpenConfirmDelete(false);
                navigate("/chat");;
            };
        };
    };

    const onRenameChat = async (chat_id: string, new_title: string) => {
        await renameChat(chat_id, new_title);
        getChats().then((data) => setVisibleChats(data.chats));
    };

    const onDownloadChat = async (chat_id: string, file_name: string) => {
        await downloadChat(chat_id, `${file_name}.txt`);
    };

    return (
        <Container className="text-start">
            <Stack p={2} spacing={2}>
                <Button
                    variant="light"
                    style={{color: "#FFF", border: "none", padding: "0px 0px 10px 0px", display: "flex"}}
                    onClickHandler={() => navigate("/chat")}
                >
                    <h6 style={{fontFamily: "proxima_novaregular", fontSize: "1rem"}}>
                        <FaRegPenToSquare style={{fontSize: "1rem", marginRight: ".5rem"}}/>
                        {t("sidebar.chat.newChatText")}
                    </h6>
                </Button>
                <Button
                    variant="light"
                    style={{color: "#FFF", border: "none", padding: "0px 0px 10px 0px", display: "flex"}}
                    onClickHandler={onClickDeleteAllChats}
                >
                    <h6 style={{fontFamily: "proxima_novaregular", fontSize: "1rem"}}>
                        <FaRegTrashCan style={{fontSize: "1rem", marginRight: ".5rem"}} />
                        {t("sidebar.chat.popupChatAction.deleteAll")}
                    </h6>
                </Button>
                <Scrollbars
                    style={{ height: 'calc(100vh - 450px)'}}
                    renderThumbVertical={({style, ...props}) =>
                        <div {...props} style={{...style, backgroundColor: '#00CCFF', right: '2px', bottom: '2px', top: '2px', borderRadius: '3px', width: '3px', margin: "0 2.5px"}}/>
                    }
                    autoHide={true}
                >
                {loading? <div style={{display: "flex", justifyContent: "space-around"}}><Loader variant="light"/></div> : Object.keys(chatsData).map((chat_group:any, chat_group_index:any) => {
                    return (
                        <Stack key={chat_group_index} sx={{paddingBottom: 2}}>
                            <InputText
                                label={t(`sidebar.chat.${chat_group}.title`)}
                                placeholder={t(`sidebar.chat.${chat_group}.inputPlaceholder`)}
                                headComponent={<IoSearch style={{color: "#FFF", marginLeft: ".25rem"}}/>}
                                onChangeHandler={(event:any) => onChangeInputText(event, chat_group)}
                                action={() => clearValue(chat_group)}
                                labelStyle={{color: "#FFF"}}
                            />
                            {chatsData[chat_group].map((chat:any, chat_index:any) => {
                                if (chat.chat_title.includes(chatFilter[chat_group])) {
                                    return (
                                    <ChatItem
                                        key={chat_index + chat_group_index}
                                        chat={chat}
                                        chat_index={chat_index}
                                        onClickDeleteChat={onClickDeleteChat}
                                        handleRename={onRenameChat}
                                        handleDownloadChat={onDownloadChat}/>
                            )}
                            })}
                        </Stack>
                    )})
                }
                </Scrollbars>
            </Stack>

            <CustomModal
                singleButton={false}
                textList={[deleteModalMessage]}
                showModal={openConfirmDelete}
                onConfirm={confirmedChatDelete}
                onCloseModal={() => {
                    setOpenConfirmDelete(false);
                }}
            />
        </Container>
    );
}

export default SidebarBody;