
import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Button, Modal } from "../../../../core/components/layout";
import InputSearch from "../../../../core/components/form/InputSearch/InputSearch";
import InputTextComponent from "../../../../core/components/form/InputText/InputText";
import Scrollbar from "react-scrollbars-custom";
// import { useNavigate } from 'react-router-dom';
// import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import languages from "../../../../assets/languages/languages.json";
import { IsPendingRequest, NamespaceConflictObject, NamespacesData, NamespacesIcon, OnAddNamespace, OnSetShowModalNamespaceCreation, ShowModalNamespaceCreation, } from "../../types";
import { BiWorld } from "react-icons/bi";
import { NAMESPACE_ICONS } from "./NamespaceColorIcons";


const DEFAULT_FORM = {
    name: '',
    icon: null,
    language: [],
    conflict: false,
}

interface ModalNamespaceProps {
    isPendingRequest: IsPendingRequest,
    showModalNamespaceCreation: ShowModalNamespaceCreation,
    namespacesIcon: NamespacesIcon,
    namespacesData: NamespacesData,
    namespaceConflictObject: NamespaceConflictObject,
    onAddNamespace: OnAddNamespace,
    onSetShowModalNamespaceCreation: OnSetShowModalNamespaceCreation
}

const ModalNamespaceCreationComponent:React.FC<ModalNamespaceProps> = ({
        isPendingRequest,
        showModalNamespaceCreation,
        namespacesData,
        namespaceConflictObject,
        onAddNamespace,
        onSetShowModalNamespaceCreation

    }) => {

    const { t } = useTranslation();
    const form_ref:any = useRef(null);
    const [mouseHover, setMouseHover] = useState<any>({hover: false, index: -1}); // remove with scss
    const [selectedIcon, setSelectedIcon] = useState<any>({icon: null}); // remove with scss
    const [slicedNamespacesData, setSlicedNamespacesData] = useState<any>([]);
    const [languageOpt, setLanguageOpt] = useState<any>([]);
    const [formData, setFormData] = useState<any>(DEFAULT_FORM);

    const chunk = (arr:any, size:number) =>
        Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
        arr.slice(i * size, i * size + size)
    );

    /**
    * @descr function used as callback to update the namespace form data
    * @param {string} field identify the form field to update
    * @param {any} data form data value
    */
    const onChangeFormDataHandler = (field:string, data:any) => {
        let form_data = {
            ...formData,
            [field]: data,
            conflict: formData.conflict ? !(field === 'name' && data.length === 0) : formData.conflict
        };

        setFormData(form_data);
    }

    /**
    * @descr function used to update the form data with the selected namespace
    * @param {any} ns_icon selected namespace data
    */
    const onClickSelectNamespaceHandler = (ns_icon:any) => {
        let form_data = {
            ...formData,
            icon: ns_icon
        };

        setSelectedIcon({icon: ns_icon});
        setFormData(form_data);
    }

    /**
     * @descr function used to send a namespace form data to the effect for the creation of a new namespace
     * @param {Object} e click event trigger
     */
    const onClickConfirm = (e:any) => {
        e.preventDefault();
        let namespace_data = {
            name: formData.name,
            icon: formData.icon,
            language: formData.language[0]
        };

        setFormData(DEFAULT_FORM);
        onAddNamespace(namespace_data);
        if (form_ref.current !== null) form_ref.current.reset();
    }

    // FUTURE FEATURE ON CUSTOM IMG NS_ICON
    // const onDropFile = (acceptedFiles:any) => {
    //     let file_successfully_uploaded:any = [];

    //     acceptedFiles.forEach((file:any) => {
    //         const reader = new FileReader();
    //         reader.onabort = () => { console.log( `File reading was aborted`); }
    //         reader.onerror = () => { console.log(`File reading was failed`); }
    //         reader.onload = (e:any) => {
    //             const binary_file:any = reader.result;
    //             const base64 = binary_file.slice(binary_file.indexOf(',')+1);
    //             let file_data:any = {id: uuidv4(), name: file.name, file: base64, conflict: false};
    //             file_successfully_uploaded.push(file_data);
    //             setFilesUploaded(file_successfully_uploaded);
    //         }
    //         reader.readAsDataURL(file);
    //     });
    // }

    /**
     * @desc function used as callback of close modal event, useful to reset the component state
    **/
     const onCloseModal = () => {
        if (!isPendingRequest) {
            setFormData(DEFAULT_FORM);
            setMouseHover({hover: false, index: -1});                       // remove with scss
            setSelectedIcon({icon: null});                                  // remove with scss
            onSetShowModalNamespaceCreation(!showModalNamespaceCreation);
            if (form_ref.current !== null) form_ref.current.reset();
        }

    }

    // Split the namespaces 'icons' data in group by 4 elements.
    useEffect (() => {
        if (form_ref.current !== null) form_ref.current.reset();
        setSlicedNamespacesData(chunk(NAMESPACE_ICONS, 4));
    }, []);

    // Reset the form data on a successfull namespace creation
    useEffect (() => {
        if (form_ref.current !== null) form_ref.current.reset();
        setFormData(DEFAULT_FORM);
        setMouseHover({hover: false, index: -1});                       // remove with scss
        setSelectedIcon({icon: null});                                  // remove with scss
    }, [namespacesData]);

    // Set and notify to the user if any conflict was detected when entering the chosen name
    useEffect (() => {
        if (form_ref.current !== null) form_ref.current.reset();
        if (namespaceConflictObject !== null) setFormData(namespaceConflictObject);
    }, [namespaceConflictObject]);

    // Set the language options
    useEffect (() => {
        if (form_ref.current !== null) form_ref.current.reset();
        let languages_opt_translated = Object.values(languages).map((language:any) => language.extendedCode);
        setLanguageOpt(languages_opt_translated);

    }, []);

    useEffect(() => {
        setFormData(DEFAULT_FORM);
    }, [namespaceConflictObject]);

    return (
        <Modal
            visible={showModalNamespaceCreation}
            size="lg"
            onHideHandler={onCloseModal}
            title={<h5>{t('views.namespace.list.modals.create.title')}</h5>}
        >
            <Form ref={form_ref}>

                {/* Namespace input name */}
                <Row style={{margin: "15px 15px"}}>
                    <InputTextComponent
                        label={t('views.namespace.list.modals.create.inputLabel')}
                        name={'name'}
                        placeholder={t('views.namespace.list.modals.create.inputPlaceholder')}
                        value={formData.name}
                        style={{color: formData.conflict ? 'red' : 'black'}}
                        onChangeHandler={(e:any) => onChangeFormDataHandler("name", e.target.value)}
                    />
                </Row>

                {/* Namespace icon grid form */}
                <Scrollbar style={{height: '30vh'}}>
                    {slicedNamespacesData.map((row_items:any, row_index:any) => {
                        return (
                            <Row style={{margin: "15px 15px"}} key={row_index}>
                                {row_items.map((item:any, col_index:any) => {
                                    let consecutive_item_index = (slicedNamespacesData[row_index].length*row_index)+col_index;
                                    return (
                                        <Col xs={3} sm={3} md={3} lg={3} style={{display: "grid"}} key={col_index}>
                                            <div
                                                // className="namespace-item" // SCSS per la gestione del onHover da scommentare dopo la pull
                                                // key={consecutive_item_index}
                                                style={{
                                                    height: "4rem",
                                                    width: "4rem",
                                                    placeSelf: "center",
                                                    margin: "20px 0",
                                                    backgroundColor: item.icon,
                                                    borderRadius: 90,
                                                    borderColor: "#FFFFFF",
                                                    borderStyle: (mouseHover.hover && mouseHover.index === consecutive_item_index || selectedIcon.icon === item.icon) ? "solid" : "",
                                                    transform: (mouseHover.hover && mouseHover.index === consecutive_item_index || selectedIcon.icon === item.icon) ? "scale(1.2)" : "",
                                                    boxShadow: (mouseHover.hover && mouseHover.index === consecutive_item_index || selectedIcon.icon === item.icon) ? "0px 0px 15px #4195fc" : ""
                                                }}
                                                onMouseEnter={() => setMouseHover({hover: true, index: consecutive_item_index})}
                                                onMouseLeave={() => setMouseHover({hover: false, index: -1})}
                                                onClick={() => onClickSelectNamespaceHandler(item.icon)}
                                            />
                                        </Col>
                                    )
                                })}
                            </Row>
                        )})
                    }
                </Scrollbar>
                <Row style={{margin: "30px 0 15px 15px"}}>
                    <Col xs={4} sm={4} md={4} lg={4}>
                        <BiWorld style={{marginRight: ".3rem", fontSize: 16}}/>
                        <InputSearch
                            label={t('views.namespace.list.modals.create.languageLabel')}
                            placeholder={t('views.namespace.list.modals.create.languagePlaceholder')}
                            options={languageOpt}
                            selected={formData.language.length > 0 ? formData.language : []}
                            style={{fontSize: 14}}
                            onChangeHandler={(item:any) => onChangeFormDataHandler('language', item)}
                        />
                    </Col>
                </Row>

                {/* Namespace footer buttons */}
                <Row>
                    <Col className="text-end">
                        <Button
                            text={t('views.namespace.list.modals.create.addButtonText')}
                            variant={selectedIcon.icon === null || formData.name.length === 0 || formData.language.length === 0 ? "secondary" : "primary"}
                            className={"modal-upload-button"}
                            style={{width: '15%'}}
                            disabled={selectedIcon.icon === null || formData.name.length === 0 || formData.language.length === 0}
                            onClickHandler={onClickConfirm}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>

    );
}

export default ModalNamespaceCreationComponent;