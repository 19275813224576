import React, { forwardRef } from "react";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
//Import button types
import type { Label, Placeholder, Name, Value, Options, LabelKey, Selected, Multiple, Disabled, Required, CustomFilter, ChangeHandler, InputChangeHandler, CustomFilterHandler, Style } from "./types";
import "react-bootstrap-typeahead/css/Typeahead.css";

interface InputSearchProps {
  label?: Label,
  placeholder?: Placeholder,
  name?: Name,
  value?: Value,
  options: Options,
  labelKey?: LabelKey,
  selected?: Selected,
  multiple?: Multiple,
  disabled?: Disabled,
  required?: Required,
  customFilter?: CustomFilter,
  style?: Style,
  onChangeHandler?: ChangeHandler
  onInputChangeHandler?: InputChangeHandler
  onCustomFilterHandler?: CustomFilterHandler
}

/*
 * InputSearch component
*/
const InputSearch: React.FC<InputSearchProps> = forwardRef(({
        label = "Search",
        placeholder = "Type a text",
        value = "",
        options = [],
        labelKey = null,
        selected = [],
        multiple= false,
        disabled = false,
        required = false,
        customFilter = false,
        style={},
        onChangeHandler,
        onInputChangeHandler,
        onCustomFilterHandler = (option:any, props:any) => option
    }, ref) => {

    return(
        <React.Fragment>

            {label !== "" &&
                <Form.Label style={style}>
                {label}
                </Form.Label>
            }

            <Typeahead
                id={`search-${Math.random()}`}
                multiple={multiple}
                labelKey={labelKey}
                options={options}
                disabled={disabled}
                inputProps={{ required: required }}
                placeholder={placeholder}
                selected={selected}
                defaultInputValue={value}
                style={style}
                onChange={ onChangeHandler }
                onInputChange={onInputChangeHandler}
                filterBy={ customFilter ? onCustomFilterHandler : undefined}
            />

        </React.Fragment>
    );

})

export default InputSearch;