/**
  * @desc this function stores JSON data in the local storage with a specific key
  * 
  * @param key, key of data
  * @param value, JSON data
  * 
  * @return true
*/
export const saveToStorage = (key:string, value:any) => {

    if(typeof(key) !== "string" || typeof(value) !== "object") return null;
  
    localStorage.setItem(key, JSON.stringify(value))
  
    return true;
  
}

/**
  * @desc this function retrieves data from local storage by key
  * 
  * @param key, key of data
  * 
  * @return
  * null is key is not defined
  * the data value if is defined
*/
export const getFromStorage = (key:string) => {

    if(typeof(key) !== "string") return null;

    let data = localStorage.getItem(key);
  
    return (data !== null) ? JSON.parse(data) : data;
  
}

/**
  * @desc this function deletes data from local storage by key
  * 
  * @param key, key if data
  * 
  * @return true
*/
export const deleteFromStorage = (key:string) => {

    if(typeof(key) !== "string") return null;
  
    localStorage.removeItem(key);

    return true;

}

/**
  * @desc this function cleares all data stored in the local storage 
  * 
  * @return
  * null is key is not defined
  * the data value if is defined
*/
export const clearStorage = () => {

    localStorage.clear();

    return true;
  
}