import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GrStatusGood } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { Button, Loader } from "../../../../core/components/layout";
import { InputText } from "../../../../core/components/form";
import { dateToTimestamp, sortJsonByKey } from "../../../../core/services";
import { useTranslation } from "react-i18next";
import {
    IsPendingRequest,
    DocumentsData,
    SelectedDocuments,
    OnSetSelectedDocuments,
    OnSetShowModalDocumentIngestion,
    OnSetShowModalDocumentDescription,
    OnSetShowModalDocumentDeletion,
    OnSetShowFilterSidebar,
    Filters,
    OnSetFilters,
    OnDownloadDocument,
    NamespacesData,
} from "../../types";
import { IoSearch } from "react-icons/io5";
import { BsFileEarmarkPlus } from "react-icons/bs";
import { FaListUl } from "react-icons/fa6";
import { FiFilter } from "react-icons/fi";
import { FaFilter } from "react-icons/fa";
import DocumentsTable from "./DocumentsTable";
import { TableData } from "./DocumentsType";
import CustomModal from "../../../../commons/CustomModal";
import { deleteDocuments } from "../../sources/services";
import { Grid } from "@mui/material";



interface TableDocumentsProps {
    isPendingRequest: IsPendingRequest,
    documentsData: DocumentsData,
    filters: Filters,
    selectedDocuments: SelectedDocuments,
    namespacesData: NamespacesData,
    onSetFilters: OnSetFilters,
    onSetSelectedDocuments: OnSetSelectedDocuments
    onSetShowModalDocumentIngestion: OnSetShowModalDocumentIngestion,
    onSetShowFilterSidebar: OnSetShowFilterSidebar,
    onSetShowModalDocumentDescription: OnSetShowModalDocumentDescription,
    onSetShowModalDocumentDeletion: OnSetShowModalDocumentDeletion,
    onDownloadDocument: OnDownloadDocument
}

const TableDocumentsComponent:React.FC<TableDocumentsProps> = ({
        isPendingRequest,
        documentsData,
        filters,
        selectedDocuments,
        namespacesData,
        onSetFilters,
        onSetSelectedDocuments,
        onSetShowModalDocumentIngestion,
        onSetShowFilterSidebar,
        onSetShowModalDocumentDescription,
        onSetShowModalDocumentDeletion,
        onDownloadDocument
    }) => {

    const [tableData, setTableData] = useState<any>([]);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [batchDeleteModal, setBatchDeleteModal] = useState<boolean>(false);
    const [itemsToBeDeleted, setItemsToBeDeleted] = useState<TableData[]>([]);
    const [batchDeleteError, setBatchDeleteError] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);
    const navigate = useNavigate();

    const batchDelete = useCallback((selected: TableData[]) => {
        setBatchDeleteModal(true);
        setItemsToBeDeleted(selected);
    }, []);

    const confirmedBatchDelete = () => {
        setBatchDeleteModal(false);
        let notDeleted: TableData[] = [];
        itemsToBeDeleted.every(async (item) => {
            setIsLoading(true);
            let resp = await deleteDocuments({title: item.title, _id_namespace: item._id_namespace});
            if (resp.code !== undefined) {
                notDeleted.push(item);
            }
            setBatchDeleteModal(false);
            setItemsToBeDeleted([]);
            if (notDeleted.length > 0) {
                setErrors(notDeleted.map((item) => item.title));
                setBatchDeleteError(true);
            } else {
                window.location.reload();
            }
        });
    };

    /**
     * @descr function that returns a string of a desired length (if specified) otherwise returns it in full
     * @param {string|null} str string data
     * @param {number} size desired length
     */
    const truckStringToSize = (str:string|null, size?:number) => {
        if (str === null) return '';
        if (size && str.length <= size) return str;
        return str.slice(0, size ? size : str.length) + "...";
    }


    /**
     * @desc function used to set selected document from table (used for deletion)
     * @param {number} id, document id
    **/
    const setSelectedDocuments = (id:number) => {
        if (selectedDocuments.some((item:any) => item.id === id)) {
            let selected_documents = selectedDocuments.filter((item:any) => item.id !== id);
            return (selected_documents);
        } else {
            let selected_documents = tableData.filter((item:any) => item.id === id);
            return (selectedDocuments.concat(selected_documents));
        }
    }

    /**
     * @descr Create a temporary anchor element to allow downloading of files of a given format.
     * @param {Object} data data
     * @param {string} filename file name
     * @param {string} format format of the file to download
     */
    const downloadFile = (data:any) => {

        const { original_link, title } = data;
        let link = document.createElement("a");

        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            link.setAttribute("href", original_link);
            link.setAttribute("download", title);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        return true;
    }

    /**
     * @desc function used to delete a document
     * @param {object} event, click event object
     * @param {object} document_data, document data object
    **/
    const onClickDeleteDocument = (event:any, document_data?:any) => {
        event.preventDefault();
        if (document_data !== undefined) {
            const { id } = document_data;
            let selected_documents = setSelectedDocuments(id);
            onSetShowModalDocumentDeletion(true, selected_documents);
        }
        if (document_data === undefined) {
            onSetShowModalDocumentDeletion(true);
        }
    }

    /**
     * @desc function used to update a document
     * @param {object} event, click event object
     * @param {object} document_data, document data object
    **/
    const onClickEditDocument = (event:any, document_data:any) => {
        event.preventDefault();
        onSetShowModalDocumentDescription(true, document_data);
    }

    /**
     * @desc function used to add a document (show document modal ingestion)
     * @param {object} event, click event object
    **/
    const onClickAddDocument = (event:any) => {
        event.preventDefault();
        onSetShowModalDocumentIngestion(true);
    }

    /**
     * @desc function used to download a document
     * @param {object} event, click event object
     * @param {object} document_data, document data object
    **/
    const onClickDownloadDocument = (event:any, document_data:any) => {
        event?.preventDefault();

        const { original_link, title } = document_data;
        window.open(original_link, '_blank');
        // const link = document.createElement("a");
        // link.href = original_link;
        // link.download = title;
        // link.click();
    }

    /**
     * @descr Omit a specific field from an object passed as an argument
     * @param key field to omit
     * @param obj target object
     * @returns object without the field identify
     */
    function omit(key:any, obj:any) {
        const { [key]: omitted, ...rest } = obj;
        return rest;
    }


    /**
     * @desc function used to update advanced filter
     * @param {object} event, input change event value
     * @param {string} field, filter string value
    **/
    const onChangeAdvancedFilter = (event:any, field:string) => {
        let advanced_fitlers = (event.target.value === "") ? omit('title', filters) : {...filters, [field]: event.target.value}
        onSetFilters(advanced_fitlers);
    }

    /**
     * @desc function used to set the visibility of filter sidebar
     * @param {object} event, click event value
    **/
    const onClickFilter = (event:any) => {
        event.preventDefault();
        onSetShowFilterSidebar(true)
    }

    /**
     * useEffect callback used to highlight selected rows of the table
    **/
    useEffect (() => {
        if (selectedDocuments.length > 0) {
            let table_data = tableData
                                .map((item:any) => {
                                    if(selectedDocuments.some((el:any) => item.id === el.id) === true) return {...item, selected: true};
                                    if(selectedDocuments.some((el:any) => item.id === el.id) === false) return {...item, selected: false};
                                });
            setTableData(table_data);
        }
        if (selectedDocuments.length === 0) {
            let table_data:any = tableData.map((item:any) => { return {...item, selected: false}})
            setTableData(table_data);
        }
    }, [selectedDocuments]);

    /**
     * useEffect callback used to set the document table content
    **/
    useEffect (() => {
        if (documentsData !== null && documentsData.length > 0) {
            const {title, users, namespaces, date_start, date_end} = filters;

            let table_data:any = sortJsonByKey(
                                                documentsData
                                                    .map((item:any, index:any) => {
                                                        return {
                                                            ...item,
                                                            id: index,
                                                            selected: false,
                                                            namespace_name: namespacesData.filter((ns:any) => ns._id === item._id_namespace)[0].name,
                                                            timestamp: dateToTimestamp(item.upload_date, {format: "YYYY-MM-DD"})
                                                        }
                                                    })
                                                    .map((item:any) => {
                                                        if(selectedDocuments.some((el:any) => item.id === el.id) === true) return {...item, selected: true};
                                                        if(selectedDocuments.some((el:any) => item.id === el.id) === false) return {...item, selected: false};
                                                    })
                                                    .filter((item:any) => {
                                                        if (title === '' || title === undefined || item.title.toLowerCase().includes(title.toLowerCase())) { return item; }
                                                    })
                                                    .filter((item:any) => {
                                                        if (users === undefined || users.length === 0 || users.includes(item.user_id)) { return item; }
                                                    })
                                                    .filter((item:any) => {
                                                        if (namespaces === undefined || namespaces.length === 0 || namespaces.includes(item.namespace_name)) { return item; }
                                                    })
                                                    .filter((item:any) => {
                                                        if (date_start === undefined || date_start === null || item.timestamp >= date_start) { return item; }
                                                    })
                                                    .filter((item:any) => {
                                                        if (date_end === undefined || date_end === null || item.timestamp <= date_end) { return item; }
                                                    }),
                                                "timestamp"
                                            );
            if (table_data) setTableData(table_data);

        }
        if (documentsData === null || documentsData.length === 0) {
            setTableData([]);
        }

    }, [documentsData, namespacesData, filters]);

    const clearValue = () => {
        let advanced_fitlers:any = omit('title', filters);
        onSetFilters(advanced_fitlers);
    };

    return (
        <Container>
            {isPendingRequest &&
                <Loader absolute={true} />
            }
            {/* Header info */}
            <Row style={{margin:"0 0 1rem -1rem"}}>
                <Col className="text-start">
                    <h3 style={{fontSize: "1.3rem"}}>
                        <FaListUl style={{marginRight: ".5rem", marginBottom: ".2rem", fontSize: 18}} />
                        {t("views.documents.list.title")}
                    </h3>
                </Col>
            </Row>

            {/* Documents filter */}
            <Row>
                <Grid container>
                    <Grid item xs={6}>
                        <InputText
                            label=""
                            placeholder={t("views.documents.list.inputPlaceholder")}
                            value={filters.title !== undefined ? filters.title : ''}
                            headComponent={<IoSearch style={{color: "#15ccff", marginLeft: ".25rem"}}/>}
                            onChangeHandler={(event:any) => onChangeAdvancedFilter(event, 'title')}
                            action={clearValue}
                        />
                    </Grid>
                    <Grid item xs={2} sx={{marginLeft: "auto"}}>
                        <Button variant="light" onClickHandler={onClickFilter} style={{marginBottom: "1rem"}}>
                            <small style={{fontSize: 14}}>{t("views.documents.list.filterButtonText")}</small>
                            {Object.keys(filters).length === 0 && <FiFilter style={{marginLeft: ".3rem", marginBottom: ".1rem"}}/>}
                            {Object.keys(filters).length > 0 && <FaFilter style={{marginLeft: ".3rem", marginBottom: ".1rem"}}/>}
                        </Button>
                    </Grid>
                </Grid>
            </Row>

            {/* Table documents data */}
            <Row>
                <Col>
                    {isLoading ? <Loader absolute={true} /> :
                    <DocumentsTable
                        data={tableData}
                        batchDelete={batchDelete}
                        onClickDeleteDocument={onClickDeleteDocument}
                        onClickDownloadDocument={onClickDownloadDocument}
                        onClickEditDocument={onClickEditDocument}
                        />
                    }
                </Col>
            </Row>
            <Row style={{paddingTop: "3rem"}}>
                <Grid container>
                    <Grid item xs={2}>
                        <Button onClickHandler={onClickAddDocument} style={{borderRadius: 50, padding: "9px 12px"}}>
                            <BsFileEarmarkPlus style={{color: "white", fontSize: 18, marginBottom: ".20rem"}}/>
                        </Button>
                        <div style={{height: 45, display: "grid", placeItems: "center", marginLeft: ".5rem"}}>
                            <small style={{fontWeight: "bold"}}> {t("views.documents.list.uploadButtonText")}  </small>
                        </div>

                    </Grid>
                    <Grid item xs={2}>
                        <Button onClickHandler={() => navigate("/documents/status")} style={{borderRadius: 50, padding: "9px 12px"}}>
                            <GrStatusGood style={{color: "white", fontSize: 18, marginBottom: ".20rem"}}/>
                        </Button>
                        <div style={{height: 45, display: "grid", placeItems: "center", marginLeft: ".5rem"}}>
                            <small style={{fontWeight: "bold"}}> {t("views.documents.list.redirectButton")}  </small>
                        </div>

                    </Grid>
                </Grid>

           </Row>

           <CustomModal
                singleButton={false}
                textList={[
                    "views.documents.list.modals.delete.bulkDocumentText",
                    itemsToBeDeleted.map((item) => ` ${item.title} (in ${item.namespace_name})`).toString(),
                ]}
                showModal={batchDeleteModal}
                onConfirm={confirmedBatchDelete}
                onCloseModal={() => {
                    setBatchDeleteModal(false);
                    setItemsToBeDeleted([]);
                }}
            />

            <CustomModal
                singleButton={true}
                button1Text="OK"
                textList={[
                    "modal.documents.error",
                    errors.toString()
                ]}
                showModal={batchDeleteError}
                onConfirm={() => {
                    setBatchDeleteError(false);
                    setErrors([]);
                    window.location.reload();
                }}
            />

        </Container>

    )

}

export default TableDocumentsComponent;
