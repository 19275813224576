import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { Grid } from '@mui/material';
import EnhancedTableToolbar from '../../../../components/table/CustomTableToolbar';
import EnhancedTableHead from '../../../../components/table/CustomTableHead';
import { Button } from '../../../../core/components/layout';
import { FaRegTrashCan } from 'react-icons/fa6';
import { t } from 'i18next';
import dayjs from 'dayjs';
import { TbCrown } from 'react-icons/tb';
import { UserData, UserHeadCell } from './UserDataType';
import { LuAtom } from 'react-icons/lu';
import { Order } from '../../../../components/table/TableOrder';

const headCells: readonly UserHeadCell[] = [
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: "views.users.list.table.user",
    width: "30%",
  },
  {
    id: 'creation_date',
    numeric: false,
    disablePadding: false,
    label: "views.users.list.table.date",
    width: "30%",
  },
  {
    id: 'is_admin',
    numeric: false,
    disablePadding: false,
    label: "views.users.list.table.admin",
    width: "30%",
  },
  {
    id: '_id',
    numeric: false,
    disablePadding: false,
    label: "",
    width: "20%",
  },
];

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string | boolean},
  b: { [key in Key]: number | string | boolean},
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

type Props = {
    data: UserData[];
    onClickDetail: (e: any, row: UserData) => void;
    onClickDeleteUser: (e: any, row: UserData) => void;
    batchDelete: (data: UserData[]) => void;
};

export default function UsersTable({
    data,
    onClickDetail,
    onClickDeleteUser,
    batchDelete,
  }: Props) {
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof UserData>('creation_date');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  React.useEffect(() => {
    setOrder('desc');
    setOrderBy('creation_date');
    setSelected([]);
    setPage(0);
  }, [data]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof UserData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
    if (event.shiftKey) {
        let startIndex = visibleRows.indexOf(visibleRows.find((row) => row.id === selected[selected.length - 1])!);
        let endIndex = visibleRows.indexOf(visibleRows.find((row) => row.id === id)!);
        let range =  Array.from({length: endIndex - startIndex }, (_, i) => startIndex + 1 + i);
        range.map((index) => setSelected((current) => [...current, visibleRows[index].id]));
        } else {
          const selectedIndex = selected.indexOf(id);
          let newSelected: readonly number[] = [];

          if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, id);
          } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
          } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
          } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
              selected.slice(0, selectedIndex),
              selected.slice(selectedIndex + 1),
          );
          }
          setSelected(newSelected);
        }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(data, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, data],
  );

  return (
    <>
    <EnhancedTableToolbar
    numSelected={selected.length}
    handleBatchDelete={() => batchDelete(data.filter(item => {
      if (selected.includes(item.id)) {
        return item;
      }
    }))} />

    <Box sx={{ width: '100%', border: "none", overflow: "auto", maxHeight: "450px" }}>
      <TableContainer sx={{ overflowX: 'initial' }}>
        <Table
          stickyHeader
          sx={{ minWidth: 750, tableLayout: "fixed" }}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            headCells={headCells}
          />
          <TableBody>
            {visibleRows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover

                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      onClick={(event) => handleClick(event, row.id)}
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                      sx={{color: "lightgrey"}}
                    />
                  </TableCell>
                  <TableCell align="left" title={row.email}>
                    <Typography
                      variant="body2"
                      sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap"}}
                      >{row.email}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{dayjs(row.creation_date).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                  </TableCell>
                  <TableCell align='left'>
                    {(row.is_admin) &&
                        <TbCrown style={{fontSize: 18, color: "#0b49f9"}} />
                    }
                  </TableCell>
                  <TableCell align="right">
                    <Grid container>
                      <Grid item xs={4}>
                        <Button variant="light" onClickHandler={(e:any) => onClickDetail(e, row)}>
                          <LuAtom style={{color: "rgb(0, 204, 255)"}} />
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button variant="light" onClickHandler={(e:any) => onClickDeleteUser(e, row)}>
                          <FaRegTrashCan style={{color: "rgb(0, 204, 255)"}} />
                        </Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("views.namespace.list.table.options.pagination")}
        sx={{'& .MuiTablePagination-toolbar': {alignItems: "baseline"}}}
      />
    </Box>
    </>
  );
}
