import React from "react";
import { Row, Col } from "react-bootstrap";
import { Button, Modal } from "../../../../core/components/layout";
import { OnDeleteUsers, OnSetShowModalUsersDeletion, SelectedUsers, ShowModalUsersDeletion } from "../../types";
import { FaRegTrashCan } from "react-icons/fa6";
import { useTranslation } from "react-i18next";


interface ModalUsersDeleteProps {
    showModalUsersDeletion: ShowModalUsersDeletion,
    selectedUsers: SelectedUsers,
    onDeleteUsers: OnDeleteUsers,
    onSetShowModalUsersDeletion: OnSetShowModalUsersDeletion,
}

const ModalUsersDeleteComponents:React.FC<ModalUsersDeleteProps> = ({
        showModalUsersDeletion,   
        selectedUsers,
        onDeleteUsers,
        onSetShowModalUsersDeletion

    }) => {

    const { t } = useTranslation();    
    
    /**
     * @descr function used as callback to hide the deletion modal
     */
    const onCloseModal = () => {
        onSetShowModalUsersDeletion(false);
    }

    /**
     * @descr function used as callback to invoke the effect that delete the selected users list
     */
    const onClickDelete = () => {
        onDeleteUsers(selectedUsers);
        onSetShowModalUsersDeletion(false);
    }

    return (
        <Modal
            visible={showModalUsersDeletion}
            size="lg"  
        >
            <Row>
                <Col className="text-center">
                    <FaRegTrashCan style={{fontSize: 24, marginTop: "2rem", color: "#C2C2C2"}} />
                </Col>
            </Row>
            <Row>
                <Col className="text-center" style={{marginTop: "1rem"}}>
                    <h5>
                        {selectedUsers.length === 1 &&
                            <div>
                                <span>{t('views.users.list.modals.delete.single')}</span><br/><br/>
                                <small style={{margin: "1rem 0"}}>{selectedUsers[0].email}</small>
                            </div>
                        }
                        {selectedUsers.length !== 1 &&
                            <div>                            
                                <span>{t('views.users.list.modals.delete.bulk')}</span><br/><br/>
                                <small style={{margin: "1rem 0"}}>
                                    {selectedUsers.map((item:any, index:any) => {
                                                                if (index < selectedUsers.length-1) { 
                                                                    return (
                                                                        <div>
                                                                            <span>{item.email + ", "}</span><br/>
                                                                        </div> 
                                                                    )
                                                                };
                                                                return <span>{item.email + "."}</span> 
                                                        })
                                    }
                                </small>
                            </div>
                        }
                    </h5>
                </Col>
            </Row>
            <Row>
                <Col className="text-center" style={{margin: "2rem 0"}}>
                    <Button text={t('views.users.list.modals.delete.backButtonText')} variant="secondary" outline={true} onClickHandler={() => onCloseModal()} style={{width: "8rem", marginRight: "1rem"}} />
                    <Button text={t('views.users.list.modals.delete.deleteButtonText')} onClickHandler={() => onClickDelete()} style={{width: "8rem"}} />           
                </Col>
            </Row>
        </Modal>
    );
}

export default ModalUsersDeleteComponents;