import { useCallback } from "react";
import { t } from "i18next";
import { REST_API_URL } from "../custom.config";
import { getCookie } from "../core/services";
import { ID } from "../config";
import { Toast } from "../core/components/layout";
import { User } from "../data-structures/UserType";
import { HcError } from "./ApiErrors";

type UserApi = {
  getUserInfo: () => Promise<{email: string}>;
  getUsersData: () => Promise<User[]>;
  getUserTokenData: (data: FormData) => Promise<any | HcError>;
};

export default function useUserApi(): UserApi {
  const getUserInfo = useCallback(async () => {
    const response = await fetch(`${REST_API_URL}/api/hyperchat/handle_users/user_info`, {
      method: 'GET',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${getCookie(`token_${ID}`)}`
      }
    });
    if (response.ok) {
      return await response.json();
    } else {
      let err = new HcError({
        code: response.status,
        message: response.statusText,
        status: response.status.toString()
      });
      console.log(err);
      Toast("error", t(`errors.users.retrieve.${err.response.code}`));
    }
  }, []);

  const getUsersData = useCallback(async () => {
    const response = await fetch(`${REST_API_URL}/api/hyperchat/handle_users/users`, {
      headers: {
        "Authorization": `Bearer ${getCookie(`token_${ID}`)}`
      }
    });

    // Check response
    if (response.ok) {
        return await response.json();
    } else {
      let err = await response.json();
      console.log(err);
      Toast("error", t(`errors.users.retrieve.${err.code}`));
    }
  }, []);

  const getUserTokenData = useCallback(async (data: FormData) => {
    const response = await fetch(`https://login.microsoftonline.com/56ac8205-a37e-4856-a53f-686943febf7b/oauth2/v2.0/token`, {
      method: 'POST',
      headers: {
          "Access-Control-Allow-Origin": "*"
      },
      body: data
    });

    // Check response
    if (response.ok) {
        return await response.json();
    } else {
      let err = new HcError({
        code: response.status,
        message: response.statusText,
        status: response.status.toString()
      });
      console.log(err);
      Toast("error", t(`errors.chat.session.${err.response.code}`));
      return err;
    }
  }, []);

  return {
    getUserInfo,
    getUsersData,
    getUserTokenData,
  };
};