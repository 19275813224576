const initialState = {
    isPendingRequest: true,
    requestError: null,
    requestResponse: null,
    showUserNamespaces: false,
    showModalUsersDeletion: false,
    showModalNamespaceDeletion: false,
    showModalUserDeletion: false,
    showModalUserConflict: false,
    showFilterSidebar: false,
    showFilterUserNamespacesSidebar: false,
    showModalUnregisteredUser: false,
    userConflictedObject: null,
    activeUser: null,
    userToken: null,
    userInfo: null,
    chatsData: [],
    usersData: [],
    namespacesData: [],
    selectedUsers: [],
    selectedNamespaces: [],
    filters: {},
    namespaceFilters: {}
}

export default initialState;