import React from "react";
import { Col, Row } from "react-bootstrap";
import { Button, Modal } from "../../../../core/components/layout";
import { OnSetShowModalUserConflict, ShowModalUserConflict, UserConflictedObject } from "../../types";
import { VscWarning } from "react-icons/vsc";
import { useTranslation } from "react-i18next";


interface ModalUsersConflictProps {
    showModalUserConflict: ShowModalUserConflict,
    userConflictedObject: UserConflictedObject,
    onSetShowModalUserConflict: OnSetShowModalUserConflict
}


const ModalUsersConflictComponent:React.FC<ModalUsersConflictProps> = ({
    showModalUserConflict,
    userConflictedObject,
    onSetShowModalUserConflict,
}) => {

    const { t } = useTranslation();

    // Set the global state for showModal to false
    const onCloseModal = () => {
        onSetShowModalUserConflict(false);
    }

    return (
        <React.Fragment>
            {(userConflictedObject !== null) && 
                <Modal
                    visible={showModalUserConflict}
                    size="lg"                 
                >
                    <Row style={{marginTop: "2rem"}}>
                        <Col className="text-center">
                            <VscWarning style={{fontSize: 30, color: "#C2C2C2"}} />
                        </Col>
                    </Row>
                    <Row style={{marginBottom: "2rem"}}>
                        <Col className="text-center">
                            <small style={{color: "#C2C2C2"}}>{t('views.users.list.modals.conflict.title')}</small>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center" style={{padding: "0 5rem"}}>
                            <h5>
                                {t('views.users.list.modals.conflict.text')} <br />
                            </h5>
                            <span style={{color:"red"}}>{`${userConflictedObject.email}`}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center" style={{margin: "3rem"}}>
                            <Button variant="secondary" outline={true} onClickHandler={() => onCloseModal()} style={{width: "8rem"}} >
                                <small>{t('views.users.list.modals.conflict.backButtonText')}</small>
                            </Button> 
                        </Col>
                    </Row>
                </Modal>
            }
        </React.Fragment>
    );
}

export default ModalUsersConflictComponent;