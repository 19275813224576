import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Toast } from "../../../core/components/layout";
import {
    ModalDocumentsDelete,
    ModalDocumentsDescription,
    ModalDocumentsIngestion,
    SidebarFilter,
    TableDocumentsComponent
} from "./components";
import {
    IsPendingRequest,
    RequestError,
    RequestResponse,
    ShowModalDocumentIngestion,
    ShowModalDocumentDescription,
    ShowModalDocumentDeletion,
    ShowFilterSidebar,
    NamespacesData,
    DocumentsData,
    SelectedDocuments,
    ActiveDocument,
    Filters,
    UsersData,
    DocumentsDataConflicts,
    OnSetShowModalDocumentIngestion,
    OnSetShowModalDocumentDescription,
    OnSetShowModalDocumentDeletion,
    OnSetSelectedDocuments,
    OnSetShowFilterSidebar,
    OnSetFilters,
    OnUploadDocuments,
    OnDeleteDocuments,
    OnUpdateDocument,
    OnDownloadDocument,
    ShowModalDocumentsConflict,
    OnGetDocumentsData,
    OnSetDocumentsDataConflicts,
    UserInfo,
    OnSetShowModalUnregisteredUser,
} from "../types";
import { useTranslation } from "react-i18next";
import { store } from "../../../store/app.store";

interface DocumentsProps {
    isPendingRequest: IsPendingRequest,
    requestError: RequestError,
    requestResponse: RequestResponse,
    showModalDocumentsConflict: ShowModalDocumentsConflict,
    showModalDocumentIngestion: ShowModalDocumentIngestion,
    showModalDocumentDescription: ShowModalDocumentDescription,
    showModalDocumentDeletion: ShowModalDocumentDeletion,
    showFilterSidebar: ShowFilterSidebar,
    documentsDataConflicts: DocumentsDataConflicts,
    namespacesData: NamespacesData,
    documentsData: DocumentsData,
    usersData: UsersData,
    selectedDocuments: SelectedDocuments,
    activeDocument: ActiveDocument,
    filters: Filters,
    userInfo: UserInfo,
    requestGetDocumentsData: OnGetDocumentsData,
    requestUploadDocuments: OnUploadDocuments,
    requestSetShowModalDocumentIngestion: OnSetShowModalDocumentIngestion,
    requestSetShowModalDocumentDescription: OnSetShowModalDocumentDescription,
    requestSetShowModalDocumentDeletion: OnSetShowModalDocumentDeletion,
    requestSetShowModalUnregisteredUser: OnSetShowModalUnregisteredUser,
    requestSetSelectedDocuments: OnSetSelectedDocuments,
    requestSetShowFilterSidebar: OnSetShowFilterSidebar,
    requestDownloadDocument: OnDownloadDocument,
    requestUpdateDocument: OnUpdateDocument,
    requestDeleteDocuments: OnDeleteDocuments,
    requestSetFilters: OnSetFilters,
    requestSetDocumentsDataConflicts: OnSetDocumentsDataConflicts
}

const DocumentsContent:React.FC<DocumentsProps> = ({
        isPendingRequest,
        requestError,
        requestResponse,
        showModalDocumentIngestion,
        showModalDocumentDescription,
        showModalDocumentDeletion,
        showFilterSidebar,
        namespacesData,
        documentsData,
        documentsDataConflicts,
        selectedDocuments,
        activeDocument,
        filters,
        userInfo,
        requestUploadDocuments,
        requestSetShowModalDocumentIngestion,
        requestSetShowModalDocumentDescription,
        requestSetShowModalDocumentDeletion,
        requestSetShowModalUnregisteredUser,
        requestSetSelectedDocuments,
        requestSetShowFilterSidebar,
        requestDeleteDocuments,
        requestDownloadDocument,
        requestUpdateDocument,
        requestSetFilters,
        requestSetDocumentsDataConflicts
    }) => {
    const [orderedData, setOrderedData] = useState([]);
    const { t } = useTranslation();

    /**
     * useEffect callback used to display a Toast with success or error message
    **/
	useEffect(() => {
        let data: DocumentsData = store.getState().document.documentsData;
        setOrderedData(data.map((n: any, i: any) => ({...n, id: i})));
		if (requestError !== null) {
            const {code, type} = requestError;
            if (code === 401 && userInfo === null) { requestSetShowModalUnregisteredUser(true); }
            if (userInfo !== null) { Toast("error", t(`errors.documents.${type}.${code}`)); }
        }
        if (requestResponse !== null) {
            const {code, type} = requestResponse;
            Toast("success", t(`errors.documents.${type}.${code}`));
        }
	}, [requestError, requestResponse, documentsData, userInfo, requestSetShowModalUnregisteredUser, requestSetShowModalUnregisteredUser]);

    return (
        <Container fluid={true} className="document-content">
            <Row className="justify-content-md-center">
                <Col lg={10} md={10} style={{paddingTop: "2rem"}}>
                    <TableDocumentsComponent
                        isPendingRequest={isPendingRequest}
                        documentsData={orderedData}
                        filters={filters}
                        selectedDocuments={selectedDocuments}
                        namespacesData={namespacesData}
                        onSetFilters={requestSetFilters}
                        onSetSelectedDocuments={requestSetSelectedDocuments}
                        onSetShowModalDocumentDescription={requestSetShowModalDocumentDescription}
                        onSetShowModalDocumentIngestion={requestSetShowModalDocumentIngestion}
                        onSetShowModalDocumentDeletion={requestSetShowModalDocumentDeletion}
                        onSetShowFilterSidebar={requestSetShowFilterSidebar}
                        onDownloadDocument={requestDownloadDocument}
                    />
                </Col>
            </Row>

            {/* Advanced filters */}
            <SidebarFilter
                showFilterSidebar={showFilterSidebar}
                documentsData={documentsData}
                namespacesData={namespacesData}
                filters={filters}
                onSetFilters={requestSetFilters}
                onSetShowFilterSidebar={requestSetShowFilterSidebar}
            />

            {/* Modal add new document */}
            <ModalDocumentsIngestion
                isPendingRequest={isPendingRequest}
                showModalDocumentIngestion={showModalDocumentIngestion}
                namespacesData={namespacesData}
                documentsDataConflicts={documentsDataConflicts}
                onUploadDocuments={requestUploadDocuments}
                onSetShowModalDocumentIngestion={requestSetShowModalDocumentIngestion}
                onSetDocumentsDataConflicts={requestSetDocumentsDataConflicts}
            />

            {/* Modal document description */}
            <ModalDocumentsDescription
                isPendingRequest={isPendingRequest}
                showModalDocumentDescription={showModalDocumentDescription}
                documentsDataConflicts={documentsDataConflicts}
                activeDocument={activeDocument}
                onUpdateDocument={requestUpdateDocument}
                onSetShowModalDocumentDescription={requestSetShowModalDocumentDescription}
            />

            {/* Modal delete document */}
            <ModalDocumentsDelete
                isPendingRequest={isPendingRequest}
                showModalDocumentDeletion={showModalDocumentDeletion}
                selectedDocuments={selectedDocuments}
                onDeleteDocuments={requestDeleteDocuments}
                onSetShowModalDocumentDeletion={requestSetShowModalDocumentDeletion}
            />

        </Container>
    );

}

export default DocumentsContent;