import moment from "moment"
import momentTimezone from "moment-timezone";
import { TIMEZONE } from "../../config";

type DateOptions = {
    format?: string;
    utc?: boolean;
    type?: string;
};

/**
  * @desc this function converts a date to a timestamp.
  *       If no timezone is specified, the function converts the date to the local guessed tz.
  * @param date date value
  * @param utc (optional) response date zone
  * @param format (optional) output date format
  * @return timestamp number
*/
export const dateToTimestamp = (date:string,  options: DateOptions = {}) => {

    const {utc, format} = options;
    let parsed_utc = utc || false,
        parsed_format = format || "YYYY-MM-DD HH:mm:ss";

    if (!moment(date, parsed_format).isValid()) return null;

    if (TIMEZONE !== undefined && TIMEZONE !== "") {
        return (parsed_utc) ?
            momentTimezone.utc(date, parsed_format).tz(TIMEZONE).valueOf()
            :
            momentTimezone.tz(date, parsed_format, TIMEZONE).valueOf()
    }

    if (TIMEZONE === undefined || TIMEZONE === "") {
        let guessed_timezone = momentTimezone.tz.guess(true);
        return momentTimezone.tz(date, parsed_format,  guessed_timezone).valueOf();
    }
}

/**
  * @desc this function converts a timestamp to a date with a specific format passed as input.
  *       If no timezone is specified, the function converts the timestamp to the local guessed tz.
  * @param timestamp, timestamp date
  * @param utc (optional) response date zone
  * @param format (optional) output date format
  * @return converted date
*/
export const timestampToDate = (timestamp:number|Date, options: DateOptions = {}) => {

    if (typeof(timestamp) !== "number" || timestamp.toString().length < 10) return null;

    const {utc, format} = options;
    let parsed_utc = utc || false,
        parsed_format = format || "YYYY-MM-DD HH:mm:ss";

    let parsed_timestamp = parseTimestamp(timestamp),
        // microsecond_date = getDifferenceBetweenString(parsed_timestamp as any as string, timestamp as any as string),
        parsed_date = null;

    if (TIMEZONE !== undefined && TIMEZONE !== "") {
        parsed_date = (parsed_utc) ? moment(parsed_timestamp).utc().format(parsed_format) : momentTimezone(parsed_timestamp).tz(TIMEZONE).format(parsed_format);
    }

    if (TIMEZONE === undefined || TIMEZONE === "") {
        let guessed_timezone = momentTimezone.tz.guess(true);
        parsed_date = momentTimezone(parsed_timestamp).tz(guessed_timezone).format(parsed_format);
    }

    if (parsed_format.includes("SSS") && timestamp.toString().length > 10) return parsed_date;

    return parsed_date;

  }
/**
  * @desc this function takes a timestamp as input and returns it in a millisecond format
  * @param timestamp, timestamp date
  * @return timestamp in millisecond format
*/
export const parseTimestamp = (timestamp:number) => {

    if (typeof(timestamp) !== "number") return null;

    //Millisecond date as 0
    if(timestamp.toString().length === 10) return timestamp*1000;
    //Millisecond date
    if(timestamp.toString().length === 13) return timestamp;
    //Microsecond date
    if(timestamp.toString().length > 10 && timestamp.toString().length !== 13) {
        let date_as_string = timestamp.toString();
        return parseInt(date_as_string.substring(0, 13));
    }

}