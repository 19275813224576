import { ID } from "../../../../config";
import { getCookie } from "../../../../core/services";
import { REACT_APP_REST_API_URL } from "../../../../custom.config";

const REST_API_URL:string = REACT_APP_REST_API_URL ? REACT_APP_REST_API_URL : `${window.location.origin}`;
const ERROR_STATUS:any = [401, 402, 403, 404, 500];

const handleError = (error_data:any, type:string) => {
    let error:any = new Error();
    error.name = "HyperChat Error";
    error.response = { 
        ...error_data,
        type: type
    };

    return error;
}

/**
  * @desc this function performs login user action retrieving session tokens
  * @return array of objects
*/
export async function getUserTokenData(data:any) {

    try {
        const response = await fetch(`https://login.microsoftonline.com/56ac8205-a37e-4856-a53f-686943febf7b/oauth2/v2.0/token`, {
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*"
            },
            body: data
        });
        
        // Check response
        if (response.ok) {
            return await response.json();
        } else {
            let res_err:any = await response.json();
            throw handleError(res_err, "login");
        }

    } catch (error:any) {
        return { success: false, message: error.message};
    }

}



export async function getUserInfo() {

    try {
        const response = await fetch(`${REACT_APP_REST_API_URL}/api/hyperchat/handle_users/user_info`, {
            headers: {
                "Authorization": `Bearer ${getCookie(`token_${ID}`)}`
            }
        });
       
        // Check response
        if (response.ok) {
            return await response.json();
        } else {
            if(ERROR_STATUS.includes(response.status)) {
                let res_err:any = {
                    code: response.status,
                    status: `${response.status} error`
                }
                throw handleError(res_err, 'retrieve');
            } else {
                let res_err:any = await response.json();
                throw handleError(res_err, 'retrieve');
            }
        }

    } catch (error:any) {
        return error.response;
    }
}