import { Col, Row } from "react-bootstrap";
import { Button, Modal } from "../core/components/layout"
import { VscWarning } from "react-icons/vsc";
import styles from "../core/style/global/global.module.scss";
import { t } from "i18next";

type Props = {
    showModal: boolean,
    onConfirm?: () => void,
    onCloseModal?: () => void,
    title?: string,
    textList?: string[],
    singleButton?: boolean,
    button1Text?: string,
    button2Text?: string,
};

const CustomModal = ({
    singleButton=true,
    button2Text='views.namespace.list.modals.delete.backButtonText',
    button1Text='views.namespace.list.modals.delete.deleteButtonText',
    showModal,
    title='',
    textList=[''],
    onConfirm,
    onCloseModal}: Props) => {
    return (
        <Modal
            visible={showModal}
            size="lg"
        >
            <Row style={{marginTop: "2rem"}}>
                <Col className="text-center">
                    <VscWarning style={{fontSize: 30, color: styles.light}} />
                </Col>
            </Row>
            <Row style={{marginBottom: "2rem"}}>
                <Col className="text-center">
                    <small style={{color: styles.light}}>{t(title)}</small>
                </Col>
            </Row>
            <Row>
                <Col className="text-center" style={{padding: "0 5rem"}}>
                    {textList.map((text, i) => (
                        <h6 key={i}>{t(text)}</h6>
                    ))}
                </Col>
            </Row>
            <Row style={{margin: "2rem 0"}}>
                <Col className="text-center" style={{margin: "0 0 2rem 0"}}>
                    {!singleButton && (
                        <Button
                        text={t(button2Text)}
                        variant="primary" outline={true}
                        onClickHandler={onCloseModal}
                        style={{width: "8rem", marginRight: "1rem"}} />
                    )}


                    <Button
                    text={t(button1Text)}
                    onClickHandler={onConfirm}
                    style={{width: "8rem"}} />

                </Col>
            </Row>
        </Modal>
    );
}

export default CustomModal;