import React from "react";
import { Button as ButtonComponent } from "react-bootstrap";
import type { Text, Type, Name, Size, Disabled, Outline, Weight, Block, Style, Children, ClickHandler, Variant} from "./types";
// import "./button.scss";

interface ButtonProps {
    className?: any,
    text?: Text,
    type?: Type,
    name?: Name,
    size?: Size,
    variant?: Variant,
    disabled?: Disabled,
    outline?: Outline,
    weight?: Weight,
    block?: Block,
    style?: Style,
    children?: Children,
    onClickHandler?: ClickHandler
}

/*
 * Button component
*/
const Button: React.FC<ButtonProps> = ({
        // default props
        text = "",
        type = "button",
        name = "",
        className = "",
        size = "md",
        weight = "500",
        variant = "primary",
        disabled = false,
        outline = false,
        block = false,
        style = {},
        onClickHandler = event => console.log("onClick event fired"),
        children

    }) => {

    // Action types
    const button_types = ["submit", "button", "reset"];
    const button_type = (button_types.includes(type) ? type : "button");
    //Variant type
    const variant_types = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"];
    const variant_type = (variant_types.includes(variant) ? variant : "primary");
    //Size types
    const size_types = ["sm", "md",  "lg"];
    const size_type = (size_types.includes(size) ? size : "md");
    //Weight types
    const weight_types = ["200", "500",  "700"];
    const weight_type = (weight_types.includes(weight) ? weight : "500");

    return (
        <React.Fragment>
            {block &&
                <div className="d-grid gap-2">
                    <ButtonComponent variant={outline ? `outline-${variant_type}-${weight_type}` : `${variant_type}-${weight}`} onClick={ onClickHandler } disabled={disabled} name={name} type={button_type} size={size} style={style}>
                        {text}
                        {children}
                    </ButtonComponent>
                </div>
            }
            {!block &&
                <ButtonComponent variant={outline ? `outline-${variant_type}-${weight_type}` : `${variant_type}-${weight}`} onClick={ onClickHandler } disabled={disabled} name={name} type={button_type} size={size} style={style}>
                    {text}
                    {children}
                </ButtonComponent>
            }
        </React.Fragment>  
    );
}

export default Button;
