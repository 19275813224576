import { createSlice } from "@reduxjs/toolkit";
import initialState from "./login.default-state";

// LOGIN STORE_SLICE
const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        setIsPendingRequest: (state, action) => {
            return {
				...state,
                isPendingRequest: action.payload,
                requestError: null,
                requestResponse: null
			}
        },
        setRequestError: (state, action) => {
            return {
                ...state,
                requestError: action.payload,
                isPendingRequest: false
            }
        },
        setRequestResponse: (state, action) => {
            return {
				...state,
                requestResponse: action.payload,
                requestError: null,
                isPendingRequest: false
			}
        }
    }
});

// Exports actions
export const {
    setIsPendingRequest,
    setRequestError,
    setRequestResponse
} = loginSlice.actions;

export default loginSlice.reducer;