import React, { useState } from "react";
import { Header, SubHeader, Body, Footer } from "./types";
import { Sidebar as SidebarComponent } from "react-pro-sidebar";
import { Button } from "../../../core/components/layout";
import "./Sidebar.scss";

interface SidebarProps {
    header?: Header,
    subHeader?: SubHeader,
    body?: Body,
    footer?: Footer
}



const Sidebar:React.FC<SidebarProps> = ({
        header = undefined,
        subHeader = undefined,
        body = undefined,
        footer = undefined
    }) => {

    const [isCollapsed, setIsCollapsed] = useState<any>(true);
    
    return (
        <React.Fragment>
            <SidebarComponent
                className="sidebar_container" 
                collapsed={isCollapsed}
                width="290px"
                collapsedWidth="3rem"
            >
                {/** HEADER **/}
                <div style={{height: isCollapsed ? "9vh" : "12vh"}} > 
                    {(header === undefined && !isCollapsed) &&
                        <span style={{color: "#FFF"}}>Sidebar Header</span>
                    }
                    {(header !== undefined && !isCollapsed) &&
                        header
                    }
                </div>
                {/** SUB HEADER **/}
                {isCollapsed &&
                    <div style={{height: "3vh"}} > 
                        {(subHeader !== undefined && isCollapsed) &&
                            subHeader
                        }
                    </div>
                } 
                {/** BODY */}
                <div style={{height: "67h"}} > 
                    {(body === undefined && !isCollapsed) &&
                        <span style={{color: "#FFF"}}>Sidebar Body</span>
                    }
                    {(body !== undefined && !isCollapsed) &&
                        body
                    }
                </div>
                {/** FOOTER */}
                <div style={{height: "21vh"}} > 
                    {(footer === undefined && !isCollapsed) &&
                        <span style={{color: "#FFF"}}>Sidebar Footer</span>
                    }
                    {(footer !== undefined && !isCollapsed) &&
                        footer
                    }
                </div>  
			</SidebarComponent>
            <div className="sidebar_button_container">
                <Button onClickHandler={() => setIsCollapsed(!isCollapsed)} variant="light" style={{border: "none"}}>
                    <div className="bracket_container">
                        <div className="bracket_top" style={isCollapsed ? {transform: 'skewX(0deg)'} : {}} />
                        <div className="bracket_bot" style={isCollapsed ? {transform: 'skewX(0deg)'} : {}} />
                    </div>
                </Button>
            </div>     
        </React.Fragment>
    );
}

export default Sidebar;

