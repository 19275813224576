import React from "react";
import { Navigate  } from "react-router-dom";
//Import private components types
import { RedirectionUrl, OnRedirection, Children } from "./types";

interface PrivateRouteProps {
    redirectionUrl: RedirectionUrl,
    onRedirection: OnRedirection,
    children: Children
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
        //default props
        redirectionUrl,
        onRedirection,
        children
    }) => {

    if (onRedirection() === null) {
        return <Navigate to={redirectionUrl} replace />;
    }
        
    return children;
}

export default PrivateRoute;
