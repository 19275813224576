import React from "react";
import { Image, Nav } from "react-bootstrap";
import logo from "../core/images/HYPERCHAT_LOGO_SCRITTA_BIANCO.svg";

const SidebarHeader:React.FC = () => {

    return (
        <div style={{paddingTop: 10}}>
            <Nav.Link href="/chat">
                <Image src={logo} alt="HyperChat" style={{transform: "scale(0.6)", cursor: "pointer"}}  />
            </Nav.Link>
        </div>

    );
}

export default SidebarHeader;