import { Col, Row } from "react-bootstrap";
import { Button, Modal } from "../core/components/layout"
import { VscWarning } from "react-icons/vsc";
import { useTranslation } from "react-i18next";
import { ShowModalExpiredSession, OnDestroySession } from "./types";
import styles from "../core/style/global/global.module.scss";

interface UnregisteredUSerModalProps {
    showModalExpiredSession: ShowModalExpiredSession,
    onDestroySession: OnDestroySession,
}

const UnregistedUserModalComponent:React.FC<UnregisteredUSerModalProps> = ({
        showModalExpiredSession,
        onDestroySession
    }) => {

    const { t } = useTranslation();

    return (
        <Modal
            visible={showModalExpiredSession}
            size="lg"
        >
            <Row style={{marginTop: "2rem"}}>
                <Col className="text-center">
                    <VscWarning style={{fontSize: 30, color: styles.light}} />
                </Col>
            </Row>
            <Row style={{marginBottom: "2rem"}}>
                <Col className="text-center">
                    <small style={{color: styles.light}}>{t("unregistered.title")}</small>
                </Col>
            </Row>
            <Row>
                <Col className="text-center" style={{padding: "0 5rem"}}>
                    <h6>
                        {t("unregistered.text1")}
                    </h6>
                    <h6>
                        {t("unregistered.text2")}
                    </h6>
                </Col>
            </Row>
            <Row style={{margin: "2rem 0"}}>
                <Col className="text-center">
                    <Button variant="secondary" outline={true} style={{width: "8rem", marginRight: "1rem"}} onClickHandler={onDestroySession}>
                        {t("unregistered.destroySessionButtonText")}
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
}

export default UnregistedUserModalComponent;