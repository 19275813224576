import React from "react";
import { Header, SubHeader, Body, Footer, Children} from "./types";
import { Sidebar } from "../components";

interface SidebarLayoutProps {
    header?: Header,
    subHeader?: SubHeader,
    body?: Body,
    footer?: Footer,
    children?: Children
}

const SidebarLayout:React.FC<SidebarLayoutProps> = ({
        header = undefined,
        body = undefined,
        subHeader = undefined,
        footer = undefined,
        children
    }) => {

    return (
        <div className="app" style={{flexGrow: 1}}>
            <Sidebar
                header={header}
                subHeader={subHeader}
                body={body}
                footer={footer}
            />
            {children}
        </div>
    );
}

export default SidebarLayout;

