import React from "react";
import Dropzone from "react-dropzone";
import type { Label, Name, Disabled, Size, Multiple, Accept, DropHandler, Style } from "./types";

interface InputDrangAndDropProps {
  label?: Label,
  icon?: any,
  name?: Name,
  size?: Size,
  multiple?: Multiple,
  disabled?: Disabled,
  accept?: Accept,
  style?: Style
  onDropHandler?: DropHandler
}


const InputDragAndDrop: React.FC<InputDrangAndDropProps> = ({
    label = "Drag and drop some files here",
    icon = null,
    name = "",
    multiple = false,
    disabled = false,
    accept =  {
      'text/plain': ['.txt'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'], 
    },
    onDropHandler = (acceptedFiles:any) => console.log("Drop event fired"),
    style = {},
    size = 2097152 
  }) => {

  return (
    <Dropzone onDrop={onDropHandler} maxSize={size} disabled={disabled} accept={accept} multiple={multiple}>
        {({getRootProps, getInputProps, acceptedFiles}) => (
            <section style={style}>
                <div {...getRootProps()}>
                    <input {...getInputProps()} name={name} />
                    <p>{label}</p>
                    {icon !== null && icon}
                </div>
            </section>
        )}
    </Dropzone>
  );

}



export default InputDragAndDrop;
