import React, { forwardRef } from "react";
import { Form } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

//Definire types e importarle
interface InputSwitchProps {
    index?: any
    disabled?: any,
    label?: any,
    title?: any,
    inline?: boolean,
    name?: string,
    checked?: boolean,
    style?: any,
    ref?: any,
    onChangeHandler?: (e:any) => void
}

const InputSwitch:React.FC<InputSwitchProps> = forwardRef<HTMLInputElement, InputSwitchProps>(({
        disabled = false,
        title = "",
        label = "Default switch label",
        inline = false,
        name = "",
        checked = false,
        style = {},
        onChangeHandler = (e:any) => console.log("onChange event fired")
    }, ref) => {

    return (
        <Form.Check
            ref={ref}
            type="switch"
            title={title}
            disabled={disabled}  
            inline={inline}
            label={label}
            name={name}
            defaultChecked={checked}
            id={`custom-switch-${uuidv4()}`}
            style={style}
            onChange={onChangeHandler}
            
      />
    );
})

export default InputSwitch;

