import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SidebarLayout } from "../../layout";
import { SidebarHeader, SidebarFooter, SidebarBody, SidebarSubHeader, UnregistedUserModal } from "../../commons";
import { getFromStorage, createCookie, saveToStorage, deleteFromStorage, deleteCookie } from "../../core/services";
import { ID } from "../../config";
import { REACT_APP_CLIENT_ID, REACT_APP_CODE_VERIFIER, REST_API_URL } from "../../custom.config";
import withDocument from "../documents/sources/connectors/documents.connector";
import DocumentsStatusPage from "./DocumentsStatusPage";
import useChatApi from "../../api/ChatApi";
import { ChatList } from "../../data-structures/ChatType";
import useDocumentsApi from "../../api/DocumentsApi";
import { DEFAULT_FILTER, DocumentFilter, DocumentWithStatusList } from "../../data-structures/DocumentType";
import { Namespace } from "../../data-structures/NamespaceType";
import useNamespaceApi from "../../api/NamespaceApi";
import { HcError } from "../../api/ApiErrors";

const SESSION_CHECK_TIME:number = 300000;

const DocumentsStatusContainer:React.FC = (props:any) => {
  const { getChats } = useChatApi();
  const { getDocumentsStatus } = useDocumentsApi();
  const navigate = useNavigate();
  const [chatList, setChatList] = useState<ChatList>({chats: []});
  const [documentList, setDocumentList] = useState<DocumentWithStatusList>({documents: []});
  const [namespaces, setNamespaces] = useState<Namespace[]>([]);
  const { getNamespaceList, getNamespaces } = useNamespaceApi();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /**
   * @descr function used to empty the storage from the current user session and return to the login page
  */
  const onDestroyUserSession = () => {
    let storage:any = deleteFromStorage(`user_${ID}`);
    let cookie:any = deleteCookie(`token_${ID}`);

    if (storage && cookie) window.location.reload();
  }

  /**
   * @descr function used as callback to retrieve the user chats data
   * @param {Object} event click event trigger
   */
  const onClickAddNewChat = (event:any) => {
    event.preventDefault();
    navigate(`/chat`);
  }

    /**
   * OnClick add new chat button callback used to delete all user chat
   * @param {object} event, click event data
   * @param {Obejct} user_data user email data
   */
    const onClickDeleteAllChat = (event:any, user_data:any) => {
    event?.preventDefault();
    props.requestDeleteAllChat(user_data);
  }

  /**
   * @desc function used to refresh the user session sending refresh_token
  **/
  const onSetNewUserSession = () => {
    let user_tokens = getFromStorage(`user_${ID}`);
    if(user_tokens) {
      const {refresh_token} = user_tokens;
      let user_form_data = new FormData();
      user_form_data.append("client_id", REACT_APP_CLIENT_ID);
      user_form_data.append("grant_type", "refresh_token");
      user_form_data.append("refresh_token", refresh_token);
      user_form_data.append("redirect_uri", `${REST_API_URL}/login`);
      user_form_data.append("code_verifier", REACT_APP_CODE_VERIFIER);
      //Refresh token effect
      props.requestSetUserSession(user_form_data);
    }
  }

  /**
   * @desc function used to check the user session status
  **/
  const checkUserSession = () => {
    console.log("Checking user session...");
    let session_data:any = getFromStorage(`user_${ID}`);
    let check_timestamp = new Date().getTime();
    const {expire_date} = session_data;
    //Current time is greater than the cookie expiry time or less than 5 minutes
    if(check_timestamp >= expire_date || (expire_date-check_timestamp < SESSION_CHECK_TIME)) {
      onSetNewUserSession();
    }
  }

  const handleRefresh = useCallback(async (filters: DocumentFilter) => {
    setDocumentList(await getDocumentsStatus(filters));
  }, []);

  /**
   * useEffect callback used to get documents data
  **/
  useEffect (() => {
    setIsLoading(true);
    (async () => {
      setChatList(await getChats());
      setDocumentList(await getDocumentsStatus(DEFAULT_FILTER));
      getNamespaceList().then(async (data) => {
        if (!(data instanceof HcError)) setNamespaces(await getNamespaces(data));
      });
      setIsLoading(false);
    })();

    checkUserSession();
    //Check user session every 5 minutes
    const interval = setInterval(() => {
    checkUserSession();
  }, SESSION_CHECK_TIME);

  return () => clearInterval(interval);
  }, []);

  /**
   * useEffect callback on userToken used to refresh user session
  **/
  useEffect (() => {
    if(props.userToken !== null) {

      let old_session_data:any = getFromStorage(`user_${ID}`);
      const {access_token, id_token, refresh_token, expires_in } = props.userToken;

      let new_session_data:any = {
        ...old_session_data,
        access_token: access_token,
        refresh_token: refresh_token,
        expire_date: new Date().getTime() + ((expires_in-600)*1000),
      };
      createCookie(`token_${ID}`, id_token, expires_in*1000);
      saveToStorage(`user_${ID}`, new_session_data);
    }
  }, [props.userToken]);

  /**
   * @descr After the login set on the local storage the user role and email data
   */
  useEffect (() => {
    if (props.userInfo !== null) {

      let session_data:any = getFromStorage(`user_${ID}`);
      //Updating session user data
      let new_session_data:any = {
        ...session_data,
        role: props.userInfo.role,
        email: props.userInfo.email,
      };

      saveToStorage(`user_${ID}`, new_session_data);
    }
  }, [props.userInfo]);

  const applyFilters = async (advancedFilters: DocumentFilter) => {
    setIsLoading(true);
    setDocumentList(await getDocumentsStatus(advancedFilters));
    setIsLoading(false);
  };

  return (
    <SidebarLayout
      header={
        <SidebarHeader />
      }
      subHeader={
          <SidebarSubHeader
            onClickAddNewChatHandler={onClickAddNewChat}
          />
      }
      body={
          <SidebarBody
            chats={chatList.chats}
            onClickAddNewChatHandler={onClickAddNewChat}
            onClickDeleteAllChat={onClickDeleteAllChat}
          />
      }
      footer={
          <SidebarFooter />
      }
    >
      <DocumentsStatusPage
        documentsData={documentList}
        namespacesData={namespaces}
        refreshTable={handleRefresh}
        applyFilters={applyFilters}
        isLoading={isLoading}
      />
      <UnregistedUserModal
        showModalExpiredSession={props.showModalUnregisteredUser}
        onDestroySession={onDestroyUserSession}
      />
    </SidebarLayout>
  );
}

export default withDocument(DocumentsStatusContainer);