import { useEffect, useState } from "react";
import { Container, Grid, Stack, Typography } from "@mui/material";
import { FaFilter, FaListUl } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Button, Loader } from "../../core/components/layout";
import { InputText } from "../../core/components/form";
import { FiFilter } from "react-icons/fi";
import { IoReload, IoSearch } from "react-icons/io5";
import StatusTable from "./StatusTable";
import { Namespace } from "../../data-structures/NamespaceType";
import DocumentsFilters from "./DocumentsFilters";
import { DEFAULT_FILTER, DocumentFilter, DocumentWithStatus, DocumentWithStatusList } from "../../data-structures/DocumentType";

type Props = {
  documentsData: DocumentWithStatusList;
  namespacesData: Namespace[];
  isLoading: boolean;
  refreshTable: (filters: DocumentFilter) => void;
  applyFilters: (filters: DocumentFilter) => void;
};

export default function DocumentsStatusPage({documentsData, namespacesData, isLoading, refreshTable, applyFilters}: Props) {
  // const [isLoading, setIsLoading] = useState(false);
  const [showFilterSidebar, setShowFilterSidebar] = useState(false);
  const [filters, setFilters] = useState<DocumentFilter>(DEFAULT_FILTER);
  const [documents, setDocuments] = useState<DocumentWithStatus[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (documentsData.documents.length > 0 && namespacesData.length > 0) {
      let init_table_data =  documentsData.documents.map((item: DocumentWithStatus, index: number) => {
        return {
            ...item,
            id: index,
            namespace_name: namespacesData.filter((ns:any) => ns._id === item._id_namespace)[0].name,
        }
      });
      setDocuments(init_table_data);
    } else {
      setDocuments([]);
    };
  }, [documentsData, namespacesData]);

  const onFiltersSet = (advancedFilters: DocumentFilter) => {
    setFilters(advancedFilters);
    applyFilters(advancedFilters);
  };

  const onSearchTitle = (event:any, field:string) => {
    let titleFilter = {...filters, [field]: event.target.value};
    setFilters(titleFilter);
    applyFilters(titleFilter);
  }

  const clearValue = () => {
    setFilters(DEFAULT_FILTER);
    applyFilters(DEFAULT_FILTER);
  };

  const onClickRefresh = () => {
    refreshTable(filters);
  };

  return (
    <Container maxWidth={false} sx={{marginRight: "60px"}}>
      <Stack p={3} spacing={5} sx={{height: "100%"}}>
        <Typography variant="h3" sx={{fontSize: "1.3rem"}}>
            <FaListUl style={{marginRight: ".5rem", marginBottom: ".2rem", fontSize: 18}} />
            {t("views.documents.status.title")}
        </Typography>
        <Grid container>
            <Grid item xs={6}>
              <InputText
                  label=""
                  placeholder={t("views.documents.list.inputPlaceholder")}
                  value=""
                  headComponent={<IoSearch style={{color: "#15ccff", marginLeft: ".25rem"}}/>}
                  onChangeHandler={(event:any) => onSearchTitle(event, "title")}
                  action={clearValue}
              />
            </Grid>
            <Grid item xs={2} sx={{marginLeft: "auto"}}>
              <Button variant="light" onClickHandler={() => setShowFilterSidebar(true)} style={{marginBottom: "1rem"}}>
                  <Typography variant="body1">{t("views.documents.list.filterButtonText")}</Typography>
                  {Object.keys(filters).length === 0 && <FiFilter style={{marginLeft: ".3rem", marginBottom: ".1rem"}}/>}
                  {Object.keys(filters).length > 0 && <FaFilter style={{marginLeft: ".3rem", marginBottom: ".1rem"}}/>}
              </Button>
            </Grid>
        </Grid>

        {isLoading ? <Loader absolute={true} /> :
          <StatusTable
            data={documents}
          />
        }
      <Grid container sx={{marginTop: "auto!important"}}>
        <Grid item xs={6}>
          <Button text={t("views.documents.status.refresh")} style={{padding: "9px 45px"}} onClickHandler={onClickRefresh}>
            <IoReload style={{fontSize: 14, marginLeft: ".3rem", marginBottom: ".1rem", color: "white"}} />
          </Button>
        </Grid>
      </Grid>
      </Stack>
      <DocumentsFilters
          showFilterSidebar={showFilterSidebar}
          documentsData={documents}
          namespacesData={namespacesData}
          filters={filters}
          onSetFilters={onFiltersSet}
          onCloseFilters={() => setShowFilterSidebar(false)}
          resetFilters={clearValue}
      />
    </Container>
  );
}