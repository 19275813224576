import { configureStore } from "@reduxjs/toolkit";

//App Reducers
import loginReducer from "../views/login/sources/login.reducer";
import documentReducer from "../views/documents/sources/documents.reducer";
import namespaceReducer from "../views/namespace/sources/namespace.reducer";
import usersReducer from "../views/users/sources/users.reducer";

export const store = configureStore ({
    reducer: {
        login: loginReducer,
        document: documentReducer,
        namespace: namespaceReducer,
        users: usersReducer,
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;