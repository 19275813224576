import {
    setIsPendingRequest,
    setRequestError,
    setRequestResponse,
    setShowModalDocumentIngestion,
    setShowModalDocumentDescription,
    setShowModalDocumentDeletion,
    setShowFilterSidebar,
    setNamespacesData,
    setDocumentsData,
    setSelectedDocuments,
    setFilters,
    setActiveDocument,
    setDocumentsDataConflicts,
    setShowModalDocumentsConflict,
    setUserToken,
    setChatsData,
    setUserInfo,
    setShowModalUnregisteredUser
} from "./documents.reducer";
import { checkDocumentTitle, deleteDocuments, getDocumentsData, updateDocument, uploadDocuments, getUserTokenData, getChats, getUserGrants, getNamespacesMetadata, getNamespacesData, deleteAllChat, getUserInfo } from "./services";


/**
 * @descr function used to retreive the user chats and documents data stored on the service and set the global documents view state.
 * @param {Array<Object>} data documents data
 */
export function requestGetDocumentsData (modal?:boolean) {
    return async (dispatch:any) =>  {
        dispatch (setIsPendingRequest(true));

        let user_data:any = null;
        let retrieve_user_email_response:any = await getUserInfo();
        if (retrieve_user_email_response.status !== undefined) {
            return dispatch (setRequestError(retrieve_user_email_response));
        }

        let admin_namespaces_data_response:any = await getNamespacesData({role: "admin"});
        if (admin_namespaces_data_response.status !== undefined) {
            return dispatch ((setRequestError(admin_namespaces_data_response)));
        }

        if (admin_namespaces_data_response.namespaces.length > 0) {
            user_data = {role: "admin", email: retrieve_user_email_response.email};
            dispatch (setUserInfo(user_data));
        }

        if (admin_namespaces_data_response.namespaces.length === 0) {
            let supervisor_namespaces_data_response = await getNamespacesData({role: "supervisor"});
            if (supervisor_namespaces_data_response.status !== undefined) {
                return dispatch ((setRequestError(supervisor_namespaces_data_response)));
            }
            if (supervisor_namespaces_data_response.namespaces.length > 0) {
                user_data = {role: "supervisor", email: retrieve_user_email_response.email};
                dispatch (setUserInfo(user_data));

            } else {
                let user_namespaces_data_response = await getNamespacesData({role: "user"});
                if (user_namespaces_data_response.status !== undefined) {
                    return dispatch ((setRequestError(user_namespaces_data_response)));
                }

                user_data = {role: "user", email: retrieve_user_email_response.email};
                dispatch (setUserInfo(user_data));
            }
        }

        let chats_data_response = await getChats();
        if (chats_data_response !== undefined && chats_data_response.status !== undefined) {
            return dispatch ((setRequestError(chats_data_response)));
        }

        dispatch (setChatsData(chats_data_response.chats));

        let documents_data_response:any = await getDocumentsData();
        if (documents_data_response !== undefined && documents_data_response.status !== undefined) {
            return dispatch (setRequestError(documents_data_response));
        }

        let get_namespaces_data_response:any = await getNamespacesData({role: 'user'});
        if (get_namespaces_data_response !== undefined && get_namespaces_data_response.status !== undefined) {
            return dispatch (setRequestError(get_namespaces_data_response));
        }

        if (get_namespaces_data_response !== null || get_namespaces_data_response !== undefined) {
            let get_namespaces_metadata_response:any = await getNamespacesMetadata(get_namespaces_data_response);
            if (get_namespaces_metadata_response !== undefined && get_namespaces_metadata_response.status !== undefined) {
                return dispatch (setRequestError(get_namespaces_metadata_response));
            }

            dispatch (setNamespacesData(get_namespaces_metadata_response));
        }

        dispatch (setDocumentsData(documents_data_response.documents));
        if (modal === true) dispatch (setShowModalDocumentIngestion(modal));
        dispatch (setIsPendingRequest(false));
    }
}

/**
 * @descr Effect used to refresh the current user session
 * @param {Object} session_data user session data
 */
export function requestSetUserSession (session_data:any) {
    return async (dispatch:any) => {
        dispatch (setIsPendingRequest(true));

        let refresh_user_session_response = await getUserTokenData(session_data);
        if (refresh_user_session_response.error !== undefined) {
            return dispatch(setRequestError(refresh_user_session_response.error_description));
        }

        dispatch (setUserToken(refresh_user_session_response));
        dispatch (setRequestResponse({code: 200, type: "session"}));
    }
}

/**
 * @descr Effect used to handle the document upload modal
 * @param {boolean} modal TT show the modal, hide it otherwise
 */
export function requestSetShowModalDocumentIngestion (modal:boolean) {
    return (dispatch:any) =>  {
        dispatch (setShowModalDocumentIngestion(modal));
    }
}

/**
 * @descr Effect used to handle the document title conflict modal
 * @param {boolean} modal TT show the modal, hide it otherwise
 */
export function requestSetShowModalDocumentConflict (modal:boolean) {
    return (dispatch:any) =>  {
        dispatch (setShowModalDocumentsConflict(modal));
    }
}

/**
 * @descr Effect used to handle the document metadata upadate modal
 * @param {boolean} modal TT show the modal, hide it otherwise
 * @param {Object || null} document_data if specified, sets the global state with data of the selected document to show its metadata
 */
export function requestSetShowModalDocumentDescription (modal:boolean, document_data?:any) {
    return (dispatch:any) =>  {
        if(document_data && modal) dispatch(setActiveDocument(document_data));
        if(!modal) dispatch(setActiveDocument(null));
        dispatch (setShowModalDocumentDescription(modal));
    }
}

/**
 * @descr Effect used to handle the delete documents modal
 * @param {boolean} modal TT show the modal, hide it otherwise
 * @param {Array<any> || null} documents_data if specified, sets the global state with the documents to delete
 */
export function requestSetShowModalDocumentDeletion (modal:boolean, documents_data?:any) {
    return (dispatch:any) =>  {
        if(documents_data && modal) dispatch (setSelectedDocuments(documents_data));
        if(!modal) dispatch(setSelectedDocuments([]));
        dispatch (setShowModalDocumentDeletion(modal));
    }
}

/**
 * @descr Effect used to handle the advance filter sidebar modal
 * @param {boolean} sidebar TT show the modal, hide it otherwise
 */
export function requestSetShowFilterSidebar (sidebar:boolean) {
    return (dispatch:any) =>  {
        dispatch (setShowFilterSidebar(sidebar));
    }
}

/**
 * @descr Effect used to set the selected documents list on the global state
 * @param {Array<any>} documents selected documents data
 */
export function requestSetSelectedDocuments(documents:any) {
    return (dispatch:any) => {
        dispatch (setSelectedDocuments(documents));
    }
}

/**
 * @descr
 * @param {} data
 */
export function requestDownloadDocument(document_data:any) {
    return async (dispatch:any) => {
        dispatch (setIsPendingRequest(true));
        console.log("Download document at URL ", document_data.original_link);
        dispatch (setIsPendingRequest(false));
    }
}

/**
 * @descr Effect used to upload a list of documents to the selected namespaces on the service.
 *        A title check is carried out for each document.
 *        If at least one title generates a conflict, the user is notified of an error and no documents are uploaded to the service.
 *        However, if the overwriting parameter is active, the documents are loaded without carrying out any checks overwriting the existing documents with that names.
 * @param documents_data documents data to upload
 * @param namespaces_data namespace data
 * @param force_overwrite enable/disable the title document check
 */
export function requestUploadDocuments(documents_data:any, namespaces_data:string, force_overwrite:boolean) {
    return async (dispatch:any) => {

        dispatch (setIsPendingRequest(true));
        let upload_documents:any = true,
            check_document_error:any = null;

        // Check documents
        if (force_overwrite === false) {
            for (let document of documents_data) {
                let check_document_title_response:any = await checkDocumentTitle(document.title, namespaces_data);
                if (check_document_title_response.status !== undefined && check_document_title_response.code !== 409) {
                    return dispatch (setRequestError(check_document_title_response));
                }
                if (check_document_title_response.code !== undefined && check_document_title_response.code === 409) {
                    let document_data_conficts:any = {
                        ...check_document_title_response,
                        document: document.title

                    }
                    dispatch (setDocumentsDataConflicts(document_data_conficts));
                    upload_documents=false;
                    if(check_document_error === null) check_document_error=check_document_title_response;
                }
            }
        }
        if (!upload_documents) dispatch (setRequestError(check_document_error));

        if (upload_documents) {
            for (let document of documents_data) {
                const document_form_data = new FormData();
                document_form_data.append("_id_namespaces", namespaces_data);
                document_form_data.append("title", document.title);
                document_form_data.append("document", document.document);
                document_form_data.append("force_overwrite", force_overwrite.toString());
                // Upload document service
                let upload_document_response:any = await uploadDocuments(document_form_data);
                if (upload_document_response.status !== undefined) {
                    return dispatch (setRequestError(upload_document_response));
                }
            }

            let documents_data_response:any = await getDocumentsData();
            if (documents_data_response.status !== undefined) {
                return dispatch (setRequestError(documents_data_response.message));
            }

            dispatch(setShowModalDocumentIngestion(false));
            dispatch(setRequestResponse({code: 200, type: "upload"}));
        }
    }
}


/**
 * @descr Effect used to update the document's metadata.
 *        Before updating, a check is performed on the title of the document, blocking the action and notifying the user in case of conflicts.
 * @param {Object} document_data document data to update
 */
export function requestUpdateDocument(document_data:any) {
    return async (dispatch:any) => {
        let base_request_body = {
            title: document_data.title,
            _id_namespace: document_data._id_namespace
        }
        dispatch (setIsPendingRequest(true));
        let update_document:any = true;

        //Check document
        if (document_data.new_title !== undefined && document_data.new_title !== document_data.title) {
            let check_document_title_response:any = await checkDocumentTitle(document_data.new_title, document_data._id_namespace);
            if (check_document_title_response.status !== undefined && check_document_title_response.code !== 409) {
                return dispatch (setRequestError(check_document_title_response));
            }
            if (check_document_title_response.status !== undefined && check_document_title_response.code === 409) {
                let document_data_conficts:any = {
                    ...check_document_title_response,
                    document_title: document_data.title,
                    document_new_title: document_data.new_title
                }
                dispatch (setDocumentsDataConflicts(document_data_conficts));
                update_document=false;
            }
            if (!update_document) dispatch (setRequestError(check_document_title_response));
        }


        if (update_document) {
            let body = {}
            body = {...base_request_body};

            if (document_data.new_link !== undefined && document_data.new_link !== document_data.original_link) {
                let new_request_body = {
                    ...body,
                    ["new_link"]: document_data.new_link
                };
                body = {...new_request_body};
            };

            if (document_data.new_description !== undefined && document_data.new_description !== document_data.description) {
                let new_request_body = {
                    ...body,
                    ["new_description"]: document_data.new_description
                };
                body = {...new_request_body};
            };

            if (document_data.new_title !== undefined && document_data.new_title !== document_data.title) {
                let new_request_body = {
                    ...body,
                    ["new_title"]: document_data.new_title
                };
                body = {...new_request_body};
            };

            let update_document_response:any = await updateDocument(body);
            if (update_document_response.status !== undefined && update_document_response.code !== 409) {
                return dispatch (setRequestError(update_document_response));
            }
            if (update_document_response.status !== undefined && update_document_response.code === 409) {
                let document_data_conficts:any = {
                    ...update_document_response,
                    document: document_data.title
                }
                dispatch (setDocumentsDataConflicts(document_data_conficts));
            }

            let documents_data_response:any = await getDocumentsData();
            if (documents_data_response.status !== undefined) return dispatch (setRequestError(documents_data_response.message));
            dispatch(setShowModalDocumentDescription(false));
            dispatch (setDocumentsData(documents_data_response.documents));
            dispatch(setRequestResponse({code: 200, type: "edit"}));
        }
    }
}

/**
 * @descr Effect used to archive the selected documents
 * @param {Array<Object>} documents_data selected documents data
 */
export function requestDeleteDocuments(documents_data:any) {
    return async (dispatch:any) => {
        dispatch (setIsPendingRequest(true));

        for (const document of documents_data) {
            let archive_document_response = await deleteDocuments({title: document.title, _id_namespace: document._id_namespace});
            if (archive_document_response.status !== undefined) {
                return dispatch (setRequestError(archive_document_response));
            }
        }

        let documents_data_response:any = await getDocumentsData();
        if (documents_data_response.status !== undefined) return dispatch (setRequestError(documents_data_response.message));
        dispatch (setShowModalDocumentDeletion(false));
        dispatch (setDocumentsData(documents_data_response.documents));
        dispatch (setSelectedDocuments([]));
        dispatch (setRequestResponse({code: 200, type: "delete"}));

    }
}


/**
 * @descr Effect used to set the advanced filter on the global state
 * @param {Object} filter_data advanced filter data
 */
export function requestSetFilters(filter_data:any) {
    return (dispatch:any) => {
        dispatch (setFilters(filter_data));
    }
}


export function requestDeleteAllChat (user_data:any) {
    return async (dispatch:any) => {
        dispatch (setIsPendingRequest(true));

        let delete_all_user_chat_response:any = await deleteAllChat({owner_email: user_data});
        if (delete_all_user_chat_response.status !== undefined) {
            return dispatch (setRequestError(delete_all_user_chat_response));
        }

        let chats_data_response = await getChats();
        if (chats_data_response.status !== undefined) {
            return dispatch ((setRequestError(chats_data_response)))
        }

        dispatch (setChatsData(chats_data_response.chats));
        dispatch (setRequestResponse({code: 200, type: "delete"}));
    }
}


/**
 * @descr Effect used to set the documents data with conflicted title
 * @param {Object} documents_data document data
 */
export function requestSetDocumentsDataConflicts(documents_data:any) {
    return (dispatch:any) => {
        dispatch (setDocumentsDataConflicts(documents_data));
    }
}

/**
 * @descr Effect used to show an unregistered user modal hanlder
 * @param {boolean} modal TT show | FF hide
 */
export function requestSetShowModalUnregisteredUser (modal:any) {
    return async (dispatch:any) => {
        dispatch (setShowModalUnregisteredUser(modal));
    }
}
