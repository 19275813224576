import { connect } from "react-redux";
import { RootState } from "../../../../store/app.store";
import {
    requestLoginUser
} from "../login.effects";

const withLogin = connect(
    (state:RootState) => state.login,
    {
        requestLoginUser
    }
);

export default withLogin;