import { createSlice } from "@reduxjs/toolkit";
import initialState from "./users.default-state";

const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setIsPendingRequest: (state, action) => {
            return {
				...state,
                isPendingRequest: action.payload,
                requestError: null,
                requestResponse: null
			}
        },
        setRequestError: (state, action) => {
            return {
                ...state,
                requestError: action.payload,
                isPendingRequest: false
            }
        },
        setRequestResponse: (state, action) => {
            return {
				...state,
                requestResponse: action.payload,
                requestError: null,
                isPendingRequest: false
			}
        },
        setUserToken: (state, action) => {
            return {
                ...state,
                userToken: action.payload
            }
        },
        setUserInfo: (state, action) => {
            return {
                ...state,
                userInfo: action.payload
            }
        },
        setChatsData: (state, action) => {
            return {
                ...state,
                chatsData: action.payload
            }
        },
        setUsersData: (state, action) => {
            return {
                ...state,
                usersData: action.payload
            }
        },
        setActiveUser: (state, action) => {
            return {
                ...state,
                activeUser: action.payload
            }
        },
        setNamespacesData: (state, action) => {
            return {
                ...state,
                namespacesData: action.payload
            }
        },
        setUserConflictedObject: (state, action) => {
            return {
                ...state,
                userConflictedObject: action.payload
            }
        },
        setSelectedUsers: (state, action) => {
            return {
                ...state,
                selectedUsers: action.payload
            }
        },
        setSelectedNamespaces: (state, action) => {
            return {
                ...state,
                selectedNamespaces: action.payload
            }
        },
        setShowModalUsersDeletion: (state, action) => {
            return {
                ...state,
                showModalUsersDeletion: action.payload
            }
        },
        setShowModalNamespaceDeletion: (state, action) => {
            return {
                ...state,
                showModalNamespaceDeletion: action.payload
            }
        },
        setShowModalUserConflict: (state, action) => {
            return {
                ...state,
                showModalUserConflict: action.payload
            }
        },
        setShowModalUnregisteredUser: (state, action) => {
            return {
                ...state,
                showModalUnregisteredUser: action.payload
            }
        },
        setShowFilterSidebar: (state, action) => {
            return {
                ...state,
                showFilterSidebar: action.payload
            }
        },
        setShowFilterUserNamespacesSidebar: (state, action) => {
            return {
                ...state,
                showFilterUserNamespacesSidebar: action.payload 
            }
        },
        setFilters: (state, action) => {
            return {
				...state,
                filters: action.payload
            }
        },
        setNamespaceFilters: (state, action) => {
            return {
				...state,
                namespaceFilters: action.payload
            }
        }
    }
});

// Exports actions
export const {
    setIsPendingRequest,
    setRequestError,
    setRequestResponse,
    setUsersData,
    setActiveUser,
    setChatsData,
    setNamespacesData,
    setSelectedUsers,
    setUserConflictedObject,
    setSelectedNamespaces,
    setShowFilterSidebar,
    setShowFilterUserNamespacesSidebar,
    setShowModalUsersDeletion,
    setShowModalNamespaceDeletion,
    setShowModalUnregisteredUser,
    setShowModalUserConflict,
    setFilters,
    setNamespaceFilters,
    setUserToken,
    setUserInfo,

} = usersSlice.actions;

export default usersSlice.reducer;