import { useEffect } from "react";
import { Box, Container, Grid, Stack } from "@mui/material";
import { PiAtomThin } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import { Namespace } from "../../data-structures/NamespaceType";

type Props = {
  namespaces: Namespace[];
  handleClickNamespace: (namespace: Namespace) => void;
  handleResetHistory: () => void;
};

export default function SelectNamespace({ namespaces, handleClickNamespace, handleResetHistory }: Props) {
  const { t } = useTranslation();

  useEffect(() => {
    handleResetHistory();
  }, [handleResetHistory]);

  return (
    <Container maxWidth={false} className="chat-content">
      <Stack p={3} spacing={3} sx={{alignItems: "center", marginTop: "5rem", paddingRight: "150px"}}>
        <Stack sx={{alignItems: "center"}}>
          <PiAtomThin size={"3rem"}/> <br/>
          <p style={{margin: ".5rem 0", fontSize: "1.2rem"}}> {t('views.chat.namespace.title')} </p>
        </Stack>

        <Box sx={{
          width: "70%",
          height: "calc(-450px + 100vh)",
          overflow: "auto",
          '&::-webkit-scrollbar': {
            width: '6px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            borderRadius: '6px',
          }
        }}>
          <Grid container sx={{paddingRight: "10px"}}>
           {namespaces.map((namespace, index) => {
            return (
              <Grid key={index} item xs={Math.max(4, 12/namespaces.length)}>
                <Stack sx={{alignItems: "center"}}>
                  <div
                    className="namespace-item text-center"
                    style={{
                        height: "4.5rem",
                        width: "4.5rem",
                        margin: "4rem auto .5rem auto",
                        backgroundColor: namespace.icon,
                        borderRadius: 50,

                    }}
                    onClick={() => handleClickNamespace(namespace)}
                  />
                  <p style={{fontSize: "1rem", textAlign: "center"}}>
                      {namespace.name}
                  </p>
                </Stack>
              </Grid>
            );
           })}
          </Grid>
        </Box>
      </Stack>
    </Container>
  );
};