import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Offcanvas  } from "react-bootstrap";
import InputSearch from "../../../../core/components/form/InputSearch/InputSearch";
import { Button } from "../../../../core/components/layout";
import { Scrollbar } from "react-scrollbars-custom";
import Calendar from "react-calendar";
import {
    Filters,
    ShowFilterSidebar,
    OnSetFilters,
    OnSetShowFilterSidebar,
} from "../../types";
import { BsCalendar4 } from "react-icons/bs";
import { CgCloseO } from "react-icons/cg";
import { BiWorld } from "react-icons/bi";

// services
import { timestampToDate } from "../../../../core/services/date.services";
// files
import languages from "../../../../assets/languages/languages.json";


// const DEFAULT_FILTER = {
//     date_start: null,
//     date_end: null,
//     language: ''
// }

const DEFAULT_FILTER = {};

interface SidebarFiltersProps {
    filters: Filters,
    showFilterSidebar: ShowFilterSidebar,
    onSetFilters: OnSetFilters,
    onSetShowFilterSidebar: OnSetShowFilterSidebar
}


const SidebarFiltersComponent:React.FC<SidebarFiltersProps> = ({
    filters,
    showFilterSidebar,
    onSetFilters,
    onSetShowFilterSidebar,

}) => {

    const { t } = useTranslation();
    const [advancedFilter, setAdvancedFilter] = useState<any>(DEFAULT_FILTER);
    const [languageOpt, setLanguageOpt] = useState<any>([]);
    // const LANGUAGES_OPT = Object.keys(languages);

     /**
     * @descr Omit a specific field from an object passed as an argument
     * @param key field to omit
     * @param obj target object
     * @returns object without the field identify
    */
     function omit(key:any, obj:any) {
        const { [key]: omitted, ...rest } = obj;
        return rest;
    }

    /**
     * @descr function used to hide the advance filter sidebar
     * @param {Object} event click event trigger
     */
    const onClickCloseFilter = (event:any) => {
        onSetShowFilterSidebar(false);
    }

    /**
     * @desc function used to update the filters using search inputs
     * @param {object} data, filter data value
     * @param {string} field, filter item string value
    **/
    const onChangeAdvancedFilter = (field:any, data:any) => {
        if (data.length === 0) {
            let advanced_fitlers:any = omit(field, advancedFilter);
            setAdvancedFilter(advanced_fitlers);
        }
        if (data.length > 0) {
            let advanced_fitlers:any = {...advancedFilter, [field]: data}
            setAdvancedFilter(advanced_fitlers);
        }
    }

    /**
     * @desc function used to update the filters using calendar component
     * @param {object} data, filter data value
     * @param {string} field, filter item string value
    **/
    const onChangeAdvancedDateFilter = (field:any, data:any) => {
        let date_timestamp = data.getTime();
        let advanced_fitlers = {...advancedFilter, [field]: date_timestamp}
        setAdvancedFilter(advanced_fitlers);
    }

    /**
     * @desc function used to save filter object
    **/
    const onClickConfirm = () => {
        onSetFilters(advancedFilter);
        onSetShowFilterSidebar(false);
    }

    /**
     * @desc function used to reset filter object
    **/
    const onClickReset = () => {
        if (filters.name !== undefined) onSetFilters({name: filters.name});
        if (filters.name === undefined) onSetFilters({});
        setAdvancedFilter(DEFAULT_FILTER);
        onSetShowFilterSidebar(false);
    }

    useEffect (() => {
        if (Object.keys(filters).length > 0) setAdvancedFilter(filters);
        if (Object.keys(filters).length === 0) setAdvancedFilter(DEFAULT_FILTER);
    }, [filters]);

    // useEffect (() => {
    //     let languages_opt_translated = LANGUAGES_OPT.map((language:any) => t(`views.namespace.list.sidebar.languageSelection.${language}`));
    //     setLanguageOpt(languages_opt_translated);
    // }, []);

    // Set the language options
    useEffect (() => {
        let languages_opt_translated = Object.values(languages).map((language:any) => language.extendedCode);
        setLanguageOpt(languages_opt_translated);
    }, []);

    return (
        <Offcanvas placement="end" show={showFilterSidebar} style={{zIndex: 99999}} onHide={onClickCloseFilter}>
            <Row style={{margin: "0 0.15rem", padding: "1rem 0 0.5rem 0", borderBottom: "1px solid #CCC"}}>
                <Col>
                    <h6><b>{t('views.namespace.list.sidebar.title')}</b></h6>
                </Col>
                <Col style={{textAlign: "right"}}>
                    <Button onClickHandler={onClickCloseFilter} variant="light">
                        <CgCloseO style={{fontSize: 20}}/>
                    </Button>
                </Col>
            </Row>

            {/* Filters inputs */}
            <Scrollbar style={{height: "calc(100vh - 115px)"}}>
                <Row style={{margin: "15px 0 0 15px"}}>
                    <Col>
                        <small>
                            <BiWorld style={{marginRight: ".3rem", fontSize: 16}}/>
                            <b>{t('views.namespace.list.sidebar.inputLabel')}</b>
                        </small>
                    </Col>
                </Row>
                <Row style={{margin: "10px 0 0 15px"}}>
                    <Col>
                        <InputSearch
                            label=''
                            placeholder={t('views.namespace.list.sidebar.inputPlaceholder')}
                            options={languageOpt}
                            selected={advancedFilter.language}
                            style={{fontSize: 14}}
                            onChangeHandler={(item:any) => { onChangeAdvancedFilter('language', item)}}
                        />
                    </Col>
                </Row>
                <Row style={{margin: "0 0 0 15px"}}>
                    <Col>
                        <small style={{fontSize: 14}}>
                            <BsCalendar4 style={{marginRight: ".3rem", fontSize: 14}}/>
                            <b>{t('views.namespace.list.sidebar.dateFrom')} </b>
                            {timestampToDate(advancedFilter.date_start, {format: "YYYY-MM-DD"}) !== null ?
                                `${timestampToDate(advancedFilter.date_start, {format: "YYYY-MM-DD"})}` :
                                ""
                            }
                        </small>
                    </Col>
                </Row>
                <Row style={{margin: "0 0 15px 0"}}>
                    <Col>
                        <div style={{transform: "scale(0.8)"}}>
                            <Calendar
                                value={timestampToDate(advancedFilter.date_start)}
                                onChange={(e:any) => onChangeAdvancedDateFilter('date_start', e)}
                            />
                        </div>
                    </Col>
                </Row>
                <Row style={{margin: "0 15px"}}>
                    <Col >
                        <small style={{fontSize: 14}}>
                            <BsCalendar4 style={{marginRight: ".3rem", fontSize: 14}}/>
                            <b>{t('views.namespace.list.sidebar.dateTo')} </b>
                            {timestampToDate(advancedFilter.date_end, {format: "YYYY-MM-DD"}) !== null ?
                                `${timestampToDate(advancedFilter.date_end, {format: "YYYY-MM-DD"})}` :
                                ""
                            }
                        </small>
                    </Col>
                </Row>
                <Row style={{margin: "0 0 15px 0"}}>
                    <Col>
                        <div style={{transform: "scale(0.8)"}}>
                            <Calendar
                                value={timestampToDate(advancedFilter.date_end)}
                                onChange={(e:any) => onChangeAdvancedDateFilter('date_end', e)}
                            />
                        </div>
                    </Col>
                </Row>
            </Scrollbar>

            {/* Footer buttons */}
            <Row style={{marginTop: "15px"}}>
                <Col>
                    <Button text={t('views.namespace.list.sidebar.backButtonText')} variant="secondary" style={{color: "#000", width: "45%", marginLeft: "3%", marginRight: "2%"}} onClickHandler={() => onClickReset()} />
                    <Button text={t('views.namespace.list.sidebar.confirmButtonText')} variant="primary" style={{width: "45%", marginLeft: "2%"}} onClickHandler={() => onClickConfirm()} />
                </Col>
            </Row>
        </Offcanvas>
    );
}

export default SidebarFiltersComponent;