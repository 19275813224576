/**
  * @desc this function groups an array of objects by key value
  * @param {object} json, object data
  * @param {string} key, key value
  * @return grouped object
*/
export const groupJsonByKey = (json:any, key:string) => {

    if(json === undefined || !Array.isArray(json) || key === undefined || typeof(key) !== "string" ) return null;

    if(json.length === 0) return {};

    let keys = getJsonUniqueKeys(json);
  
    if(keys === null || (!keys.includes(key) || key === "")) return {};
  
    return json.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        return result;
    }, {});

}

/**
  * @desc this function sorts an array of object by key value
  * @param {object} json, object data
  * @param {string} key, key value 
  * @param {string} type, sort type: DESC -> descending, ASC -> ascending
  * @return object
*/
export const sortJsonByKey = (json:any, key:string, order:string = "DESC") => {

  if(json === undefined || !Array.isArray(json) || key === undefined || typeof(key) !== "string" || order === undefined || typeof(order) !== "string" || (order !== "DESC" && order !== "ASC")) return null;
  
  if(order === "DESC") return json.sort((a, b) =>  ((b[key] < a[key]) ? -1 : ((b[key] > a[key]) ? 1 : 0)));
  if(order === "ASC") return json.sort((a, b) =>  ((a[key] < b[key]) ? -1 : ((a[key] > b[key]) ? 1 : 0)));

}

/**
  * @desc this function returns an array of unique keys of an array of object
  * @param {object} json, object
  * @return array of unique keys
*/
export const getJsonUniqueKeys = (json:any) => {

    if(json === undefined || !Array.isArray(json)) return null;
     
    return Object.keys(json.reduce(function(result:any, obj:any) {
        return Object.assign(result, obj);
    }, {})) 
  
  }

/**
  * @desc this function split json data into chunks with a defined size
  * @param {object} json, json data
  * @param {number} size, size of chunk
  * @return chuncked json data
*/
export const chunkJson = (json:any, size:number) => {
  return Array.from({ length: Math.ceil(json.length / size) }, (v:any, i:number) =>
    json.slice(i * size, i * size + size)
  );
}