import React from "react";
import { Button } from "../core/components/layout";
import { FaRegPenToSquare } from "react-icons/fa6";
import { OnClickAddNewChatHandler } from "./types";

interface SidebarSubHeaderProps {
    onClickAddNewChatHandler: OnClickAddNewChatHandler
}

const SidebarSubHeader:React.FC<SidebarSubHeaderProps> = ({
        onClickAddNewChatHandler
    }) => {

    return (
        <Button
            variant="light"
            style={{color: "#FFF", margin: "0 auto", border: "none"}}
            block={true}
            onClickHandler={() => window.location.href = "/chat"}
        >
            <FaRegPenToSquare/>
        </Button>
    );
}

export default SidebarSubHeader;