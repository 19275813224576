
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button, Modal, Loader, Alert } from "../../../../core/components/layout";
import { InputSwitch, InputText, InputDragAndDrop, InputCheckbox } from "../../../../core/components/form";
import Scrollbars from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { IoAddCircleOutline } from "react-icons/io5";
import { NamespacesData, ShowModalDocumentIngestion, OnSetShowModalDocumentIngestion, OnUploadDocuments, DocumentsDataConflicts, IsPendingRequest, OnSetDocumentsDataConflicts } from "../../types";
import { trimStr } from "../../../../core/services";
import styles from "../../../../core/style/global/global.module.scss";
import { CircularProgress, Grid, Stack, Typography } from "@mui/material";
import CustomModal from "../../../../commons/CustomModal";
import { VscWarning } from "react-icons/vsc";

interface ModalDocumentsProps {
    isPendingRequest: IsPendingRequest,
    showModalDocumentIngestion: ShowModalDocumentIngestion,
    namespacesData: NamespacesData,
    documentsDataConflicts: DocumentsDataConflicts,
    onUploadDocuments: OnUploadDocuments,
    onSetShowModalDocumentIngestion: OnSetShowModalDocumentIngestion,
    onSetDocumentsDataConflicts: OnSetDocumentsDataConflicts
}

const ModalDocumentsIngestionComponent:React.FC<ModalDocumentsProps> = ({
        isPendingRequest,
        showModalDocumentIngestion,
        namespacesData,
        documentsDataConflicts,
        onUploadDocuments,
        onSetShowModalDocumentIngestion,
        onSetDocumentsDataConflicts,
    }) => {

    const switch_ref:any = useRef([]);
    const switch_all_ref:any = useRef(null);
    const [switches, setSwitches] = useState<any>([]);
    const [filesToBeUploaded, setFilesToBeUploaded] = useState<any>([]);
    const [forceOverwrite, setForceOverwrite] = useState<boolean>(false);
    const [documentsNumberExceeded, setDocumentsNumberExceeded] = useState<boolean>(false);
    const history = useNavigate();
    const { t } = useTranslation();

    /**
     * @desc function used to update the title of a document that exist in a namespace
     * @param {object} event, input change event value
     * @param {object} file, file object data
    **/
    const onChangeFilename = (event:any, file:any, i:number) => {

        let {target:{value}} = event;
       // c è un problema nel momento in cui 2 file hanno lo stesso nome utilizzando item.title === file.title
        let files_data:any = filesToBeUploaded
                                        .map((item:any, index:any) => {
                                            if(item.title === file.title && index === i) {
                                                return {
                                                    ...item, title: trimStr(value)
                                                }
                                            }

                                            return item;
                                        });

        setFilesToBeUploaded(files_data);
    }

    /**
     * @desc function used to update all switch inputs
     * @param {object} event, input change event value
    **/
    const onChangeSwitchAll = (event:any) => {

        let {target: {checked}} = event;
        let switches_data:any = switches
                                .map((item:any) => {
                                    return {
                                        ...item,
                                        checked: checked
                                    }
                                });
        //Update switch references
        for (let i = 0; i < switch_ref.current.length; i++) {
            switch_ref.current[i].checked = checked;
        }
        setSwitches(switches_data);
    }

    /**
     * @desc function used to update a switch input
     * @param {object} event, input change event value
    **/
    const onChangeSwitch = (event:any) => {

        let {target: {name, checked}} = event;
        let switches_data:any = switches
                                    .map((item:any) =>
                                        item.name === name ? {...item, checked: checked} : item
                                    );
        setSwitches(switches_data);

    }

    /**
     * @desc function used to force the overwriting of document names
     * @param {object} event, input change event value
    **/
    const onChangeCheckbox = (event:any) => {
        let {target: {checked}} = event;
        setForceOverwrite(checked)
    }

    /**
     * @desc function used as callback of dropped files
     * @param {object} acceptedFiles, uploaded files data
    **/
    const onDropFile = (acceptedFiles:any) => {
        if (acceptedFiles.length > 10) {
            setDocumentsNumberExceeded(true);
            return;
        };

        setFilesToBeUploaded([]);

        acceptedFiles.forEach((file:any) => {
            //Set file reader interface
            const reader = new FileReader();
            reader.onabort = () => { console.log( `File reading was aborted`); }
            reader.onerror = () => { console.log(`File reading was failed`); }
            reader.onload = (e:any) => {
                if(file.name !== "") {
                    let file_data:any = {title: trimStr(file.name), document: file};
                    setFilesToBeUploaded((files:any) =>
                        files.concat(file_data)
                    );
                }
            }
            //Set file reader method
            reader.readAsArrayBuffer(file);
        });


    }

    /**
     * @desc function used to start the document upload pipeline
     * @param {object} event, click event value
    **/
    const onClickConfirm = (event:any) => {
        console.log("onClickConfirm", filesToBeUploaded);
        event.preventDefault();
        if (switches.every((item:any) => item.checked === false)) {
            return;
        }

        let namespaces_selected = switches
                                    .map((ns:any) => {
                                            if (ns.checked) return ns._id; }
                                    )
                                    .filter((item:any) => item !== undefined)
                                    .join(',');

        onSetDocumentsDataConflicts([]);
        onUploadDocuments(filesToBeUploaded, namespaces_selected, forceOverwrite);

        if(switch_all_ref.current !== null) switch_all_ref.current = null;
        if(switch_ref.current !== null) switch_ref.current = [];
        onSetDocumentsDataConflicts([]);
        setForceOverwrite(false);
    }

    /**
     * @desc function used as callback of close modal event, useful to reset the component state
    **/
    const onCloseModal = () => {
        onSetShowModalDocumentIngestion(!showModalDocumentIngestion);
        if(switch_all_ref.current !== null) switch_all_ref.current = null;
        if(switch_ref.current !== null) switch_ref.current = [];
        onSetDocumentsDataConflicts([]);
        setFilesToBeUploaded([]);
        setSwitches([]);
        setForceOverwrite(false);
        history('/documents');
    }

    /**
     * useEffect callback on namespacesData variable, used to reset the 'checked' status of the switch inputs
    **/
    useEffect (() => {
        setFilesToBeUploaded([]);
        if (namespacesData.length > 0) setSwitches(namespacesData.map((item:any) => { return {...item, checked:false}}));
    }, [namespacesData, showModalDocumentIngestion]);

    /**
     * useEffect callback on switch variable, used to reset the 'checked' status of the switch inputs
    **/
    useEffect (() => {
        if (switches.length > 0 && switch_all_ref.current !== null && switches.some((item:any) => item.checked === false)) switch_all_ref.current.checked = false;
        if (switches.length > 0 && switch_all_ref.current !== null && switches.every((item:any) => item.checked === true)) switch_all_ref.current.checked = true;
    }, [switches]);

    return (
        <Modal
            visible={showModalDocumentIngestion}
            size="lg"
            title={isPendingRequest ? undefined : <h3 style={{fontSize: "1.3rem"}}>{t("views.documents.upload.title")}</h3>}
            onHideHandler={onCloseModal}
            isLoading={isPendingRequest}
        >
            {isPendingRequest ?
                <Stack spacing={5} sx={{minHeight: 400, alignItems: "center", justifyContent: "center"}}>
                    <VscWarning style={{fontSize: 30, color: "#C2C2C2"}} />
                    <Typography sx={{ fontWeight: 'bold' }}>
                        {t("views.documents.upload.page")}
                    </Typography>
                    <Stack spacing={5} direction={"row"} sx={{alignItems: "center"}}>
                        <Typography sx={{ fontWeight: 'bold' }}>{t("views.documents.upload.upload")} {filesToBeUploaded.length} {t("views.documents.upload.ongoing")} </Typography>
                        <CircularProgress />
                    </Stack>
                </Stack>
            :
            <>
                <Row style={{marginTop: "2rem"}}>
                    <Col xs={3} md={3} lg={2}>
                        <InputDragAndDrop
                            label=""
                            icon={
                                <div style={{cursor: "pointer", width: 60, height: 60, backgroundColor: "transparent", display: "grid", placeItems: "center", borderRadius: 90, borderWidth: 1, borderStyle: "solid", borderColor: "#c7c7c7"}}>
                                    <IoAddCircleOutline style={{fontSize: 22, color: "#c7c7c7"}}/>
                                </div>
                            }
                            size={Infinity}
                            multiple={true}
                            style={{marginLeft: "1.5rem"}}
                            onDropHandler={onDropFile}
                        />
                    </Col>
                    <Col xs={9} md={9} lg={10}>
                        {filesToBeUploaded.length > 0 &&
                            <React.Fragment>
                                {forceOverwrite &&
                                    <Alert variant="warning" icon={true}>
                                        {t("views.documents.upload.overwriteAlertText")}
                                    </Alert>
                                }
                                <InputCheckbox
                                    label=""
                                    checkLabel={t("views.documents.upload.overwriteCheckboxInputLabel")}
                                    onChangeHandler={onChangeCheckbox}
                                />
                                <Scrollbars style={{height: filesToBeUploaded.length > 5 ? "30vh" : `${filesToBeUploaded.length*7}vh`}}>
                                    {filesToBeUploaded.map((file:any, index:any) => {
                                        let file_with_conflict = documentsDataConflicts.filter((item:any) => item.document === file.title);
                                        let namespaces_name = [];
                                        if (file_with_conflict.length > 0) {
                                            let namespaces_id:any = file_with_conflict[0].errors.namespaces_exist.map((ns:any) => ns);
                                            namespaces_name = namespacesData.map((item:any) => {
                                                                                        if (namespaces_id.includes(item._id)) {
                                                                                            return item.name
                                                                                        }
                                                                                    })
                                                                                    .filter((item:any) => item !== undefined);
                                        }

                                        return (
                                            <React.Fragment key={index}>
                                                {namespaces_name.length > 0 &&
                                                    <small style={{fontSize: "0.75rem", margin: "5px 0"}}>{namespaces_name.map((ns:any) => ns).join(', ')}</small>
                                                }
                                                <InputText
                                                    hideAction={true}
                                                    value={file.title}
                                                    name={file.title}
                                                    label=""
                                                    placeholder=""
                                                    action={() => setFilesToBeUploaded([])}
                                                    onChangeHandler={(e:any) => onChangeFilename(e, file, index)}
                                                    style={{color: file_with_conflict.length > 0 ? styles.danger : styles.dark, width: "80%", fontSize: ".75rem"}}
                                                />
                                            </React.Fragment>
                                        )
                                    })}
                                </Scrollbars>
                            </React.Fragment>
                        }
                    </Col>
                </Row>
                {/* Namespace grid form */}
                <Grid container sx={{paddingTop: 10, gap: "10px"}}>
                    <Grid item xs={2}>
                        <Stack sx={{alignItems: "center"}}>
                        <div style={{width: 60, height: 60, borderRadius: 90, backgroundColor: `#c2c2c2`, justifySelf: "center", marginBottom: ".2rem"}} />
                        <Typography sx={{wordBreak: "break-all", textAlign: "center", marginBottom: ".3rem", fontSize: ".75rem"}}>{t("views.documents.upload.switchLabel")}</Typography>
                        <InputSwitch
                            style={{justifySelf: "center", marginBottom: "2rem", marginLeft: ".5rem"}}
                            label={""}
                            ref={switch_all_ref}
                            name={t("views.documents.upload.switchLabel")}
                            checked={switch_all_ref.current?.checked}
                            disabled={filesToBeUploaded.length > 0 ? false : true}
                            onChangeHandler={onChangeSwitchAll}
                        />
                        </Stack>
                    </Grid>
                    {switches.map((item:any, index:number) => {
                        return (
                            <Grid item xs={2} key={index}>
                                <Stack sx={{alignItems: "center"}}>
                                <div style={{width: 60, height: 60, borderRadius: 90, backgroundColor: item.icon, justifySelf: "center", marginBottom: ".2rem"}} />
                                <Typography sx={{wordBreak: "break-all", textAlign: "center", marginBottom: ".3rem", fontSize: ".75rem"}}>{`${item.name}`}</Typography>
                                <InputSwitch
                                    index={index}
                                    style={{justifySelf: "center", marginBottom: "2rem", marginLeft: ".5rem"}}
                                    label={""}
                                    ref={(element:any) => { switch_ref.current[index] = element }}
                                    checked={item.checked}
                                    disabled={filesToBeUploaded.length > 0 ? false : true}
                                    name={`${item.name}`}
                                    onChangeHandler={onChangeSwitch}
                                />

                                </Stack>

                            </Grid>
                        )
                    })}
                </Grid>

                {/* Footer buttons */}
                <Row >
                    <Col className="text-end">
                        <Button text={t("views.documents.upload.uploadButtonText")} variant={filesToBeUploaded.length === 0 || switches.every((item:any) => item.checked === false) ? "secondary" : "primary" } type={"button"} onClickHandler={onClickConfirm} disabled={filesToBeUploaded.length === 0 || switches.every((item:any) => item.checked === false)  ? true : false} style={{width: "8rem"}} />
                    </Col>
                </Row>
            </>}
            <CustomModal
            singleButton={true}
            button1Text="Ok"
            showModal={documentsNumberExceeded}
            textList={[t("views.documents.upload.uploadLimit")]}
            onConfirm={() => setDocumentsNumberExceeded(false)}
            />
        </Modal>
    );
}

export default ModalDocumentsIngestionComponent;