import { connect } from "react-redux";
import { RootState } from "../../../../store/app.store";
import { 
    requestGetDocumentsData,
    requestDownloadDocument,
    requestUploadDocuments,
    requestSetShowModalDocumentConflict,
    requestSetShowModalDocumentIngestion,
    requestSetShowModalDocumentDescription,
    requestSetShowModalDocumentDeletion,
    requestSetShowModalUnregisteredUser,
    requestSetShowFilterSidebar,
    requestSetSelectedDocuments,
    requestUpdateDocument,
    requestDeleteDocuments,
    requestSetFilters,
    requestSetUserSession,
    requestDeleteAllChat,
    requestSetDocumentsDataConflicts
} from "../documents.effects";

const withDocument = connect(
    (state:RootState) => state.document, 
    {
        requestGetDocumentsData,
        requestDownloadDocument,
        requestUploadDocuments,
        requestSetShowModalDocumentConflict,
        requestSetShowModalDocumentIngestion,
        requestSetShowModalDocumentDescription,
        requestSetShowModalDocumentDeletion,
        requestSetShowModalUnregisteredUser,
        requestSetShowFilterSidebar,
        requestSetSelectedDocuments,
        requestUpdateDocument,
        requestDeleteDocuments,
        requestSetFilters,
        requestSetUserSession,
        requestDeleteAllChat,
        requestSetDocumentsDataConflicts
    }
);

export default withDocument;