/**
  * @desc this function creates a new cookie with a name, a value and a duration
  *       all passed as input parameters
  * 
  * @param name, name of cookie
  * @param value, value of cookie
  * @param duration, value in millisecond of cookie duration
  * 
  * @return true
*/
export const createCookie = (name:string, value:string, duration:number = 3600000) => {

  if(typeof(name) !== "string" || typeof(value) !== "string") return null;

  let date = new Date(),
      cookie_name = (name === undefined) ? "token" : name;

  date.setTime(date.getTime() + duration);
  document.cookie = `${cookie_name}=${value};path=/;expires=${date.toUTCString()};SameSite=Strict;`;

  return true;

}
  
/**
  * @desc this function retrieves a cookie value by name
  * 
  * @param name, name of cookie
  * 
  * @return
  * null is cookie is not defined
  * the cookie value if is defined
*/
export const getCookie = (name:string) => {

  if(typeof(name) !== "string") return null;

  let cookie_name = (name === undefined) ? "token" : name,
      value = document.cookie.match(`(^|;) ?${cookie_name}=([^;]*)(;|$)`);

  return value ? value[2] : null;

}
  
/**
  * @desc this function deletes a cookie by name setting the expiration date in the past
  * 
  * @param name, name of cookie
  * 
  * @return true
*/
export const  deleteCookie = (name:string) => {

  if(typeof(name) !== "string") return null;

  let date = new Date(),
      cookie_name = (name === undefined) ? "token" : name;

  date.setTime(date.getTime() - 60*60*1000*1);
  document.cookie = `${cookie_name}=;path=/;expires=${date.toUTCString()}`;
  
  return true;

}
  