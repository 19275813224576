import React from "react";
import { Button, Modal } from "../../../../core/components/layout";
import { NamespaceConflictObject, OnSetShowModalNamespaceConflict, OnSetShowModalNamespaceCreation, OnSetShowModalNamespaceMetadata, ShowModalNamespaceConflict } from "../../types";
import { Col, Row } from "react-bootstrap";
import { VscWarning } from "react-icons/vsc";
import styles from "../../../../core/style/global/global.module.scss";
import { useTranslation } from "react-i18next";


interface ModalNamespaceConflictProps {
    showModalNamespaceConflict: ShowModalNamespaceConflict,
    namespaceConflictObject: NamespaceConflictObject,
    onSetShowModalNamespaceConflict: OnSetShowModalNamespaceConflict,
    onSetShowModalNamespaceCreation: OnSetShowModalNamespaceCreation,
    onSetShowModalNamespaceMetadata: OnSetShowModalNamespaceMetadata,

}

const ModalNamespaceConflictComponent:React.FC<ModalNamespaceConflictProps> = ({
    showModalNamespaceConflict,
    namespaceConflictObject,
    onSetShowModalNamespaceConflict,
    onSetShowModalNamespaceCreation,
    onSetShowModalNamespaceMetadata,

}) => {

    const { t } = useTranslation();

    /**
    * @descr function used to close the conflict modal and show the previews modal based on the performed operation, such as creation or ns metadata update.   
    */
    const onCloseModal = () => {
        onSetShowModalNamespaceConflict(!showModalNamespaceConflict);
        if (namespaceConflictObject.id === null || namespaceConflictObject.id === undefined) onSetShowModalNamespaceCreation(true);
        if (namespaceConflictObject.id !== null && namespaceConflictObject.id !== undefined) onSetShowModalNamespaceMetadata(true);
    }

    return (
        <React.Fragment>
            {(namespaceConflictObject !== null) &&
                <Modal
                    visible={showModalNamespaceConflict}
                    size="lg"
                >
                    <Row style={{marginTop: "2rem"}}>
                        <Col className="text-center">
                            <VscWarning style={{fontSize: 30, color: "#C2C2C2"}} />
                        </Col>
                    </Row>
                    <Row style={{marginBottom: "1rem"}}>
                        <Col className="text-center">
                            <small style={{color: "#C2C2C2"}}>{t('views.namespace.list.modals.conflict.title')}</small>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center" style={{padding: "0 5rem"}}>
                            <h5> {t('views.namespace.list.modals.conflict.text')} <br /> </h5>
                            <span style={{color: styles.danger, fontSize: 16}}>{`${namespaceConflictObject.new_name}`}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center" style={{margin: "2rem 0"}}>
                            <Button text={t('views.namespace.list.modals.conflict.backButtonText')} variant="secondary" outline={true} onClickHandler={() => onCloseModal()} style={{width: "8rem"}} /> 
                        </Col>
                    </Row>
                </Modal>
            }
        </React.Fragment>
    );
}

export default ModalNamespaceConflictComponent;
