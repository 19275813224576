const initialState = {
    isPendingRequest: true,
    requestError: null,
    requestResponse: null,
    showModalNamespaceConflict: false,
    showModalNamespaceCreation: false,
    showModalNamespaceMetadata: false,
    showModalNamespaceDeletion: false,
    showModalNamespaceUsersDeletion: false,
    showModalUnregisteredUser: false,
    showFilterUsersSidebar: false,
    showFilterSidebar: false,
    chatsData: [],
    usersData: [],
    namespacesData: [],
    namespacesIcon: [],
    namespaceConflictObject: null,
    selectedNamespaces: [],
    selectedUsers: [],
    activeNamespace: null,
    userToken: null,
    userInfo: null,
    filters: {},
    userFilters: {},
}

export default initialState;