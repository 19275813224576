import { createSlice } from "@reduxjs/toolkit";
import initialState from "./namespace.default-state";

const namespaceSlice = createSlice({
    name: "namespace",
    initialState,
    reducers: {
        setIsPendingRequest: (state, action) => {
            return {
				...state,
                isPendingRequest: action.payload,
                requestError: null,
                requestResponse: null
			}
        },
        setRequestError: (state, action) => {
            return {
                ...state,
                requestError: action.payload,
                isPendingRequest: false
            }
        },
        setRequestResponse: (state, action) => {
            return {
				...state,
                requestResponse: action.payload,
                requestError: null,
                isPendingRequest: false
			}
        },
        setShowModalNamespaceConflict: (state, action) => {
            return {
                ...state,
                showModalNamespaceConflict: action.payload
            }
        },
        setShowModalNamespaceCreation: (state, action) => {
            return {
                ...state,
                showModalNamespaceCreation: action.payload
            }
        },
        setShowModalNamespaceMetadata: (state, action) => {
            return {
                ...state,
                showModalNamespaceMetadata: action.payload
            }
        },
        setShowModalNamespaceDeletion: (state, action) => {
            return {
				...state,
                showModalNamespaceDeletion: action.payload
            }
        },
        setShowModalNamespaceUsersDeletion: (state, action) => {
            return {
                ...state,
                showModalNamespaceUsersDeletion: action.payload
            }
        },
        setShowModalUnregisteredUser: (state, action) => {
            return {
                ...state,
                showModalUnregisteredUser: action.payload
            }
        },
        setShowFilterSidebar: (state, action) => {
            return {
				...state,
                showFilterSidebar: action.payload
            }
        },
        setShowFilterUsersSidebar: (state, action) => {
            return {
                ...state,
                showFilterUsersSidebar: action.payload
            }
        },
        setNamespacesIcon: (state, action) => {
            return {
                ...state,
                namespacesIcon: action.payload
            }
        },
        setChatsData: (state, action) => {
            return {
                ...state,
                chatsData: action.payload
            }
        },
        setUsersData: (state, action) => {
            return {
                ...state,
                usersData: action.payload
            }
        },
        setNamespacesData: (state, action) => {
            return {
				...state,
                namespacesData: action.payload
            }
        },
        setNamespaceConflict: (state, action) => {
            return {
                ...state,
                namespaceConflictObject: action.payload
            }
        },
        setActiveNamespace: (state, action) => {
            return {
                ...state,
                activeNamespace: action.payload
            }
        },
        setSelectedNamespaces: (state, action) => {
            return {
                ...state,
                selectedNamespaces: action.payload
            }
        },
        setSelectedUsers: (state, action) => {
            return {
                ...state,
                selectedUsers: action.payload
            }
        },
        setUserToken: (state, action) => {
            return {
                ...state,
                userToken: action.payload
            }
        },
        setUserInfo: (state, action) => {
            return {
                ...state,
                userInfo: action.payload
            }
        },
        setFilters: (state, action) => {
            return {
				...state,
                filters: action.payload
            }
        },
        setUserFilters: (state, action) => {
            return {
				...state,
                userFilters: action.payload
            }
        }
    }
});

// Exports actions
export const {
    setIsPendingRequest,
    setRequestError,
    setRequestResponse, 
    setShowFilterSidebar,
    setShowFilterUsersSidebar,
    setShowModalNamespaceConflict,
    setShowModalNamespaceCreation,
    setShowModalNamespaceMetadata,
    setShowModalNamespaceDeletion,
    setShowModalNamespaceUsersDeletion,
    setShowModalUnregisteredUser,
    setSelectedNamespaces,
    setSelectedUsers,
    setActiveNamespace,
    setNamespacesIcon,
    setUsersData,
    setChatsData,
    setUserToken,
    setUserInfo,
    setNamespacesData,
    setNamespaceConflict,
    setFilters,
    setUserFilters
    
} = namespaceSlice.actions;

export default namespaceSlice.reducer;