import React from "react";
import Form from "react-bootstrap/Form";
//Import button types
import type { Label, Placeholder, Rows,Name, Value, Disabled, Required, Style, OnChangeHandler } from "./types";

interface InputTextareaProps {
    label?: Label,
    placeholder?: Placeholder,
    rows?: Rows,
    name?: Name,
    value?: Value,
    disabled?: Disabled,
    required?: Required,
    style?: Style,
    labelStyle?: Style,
    onChangeHandler?: OnChangeHandler
}

/*
 * InputText component
*/
const InputTextarea: React.FC<InputTextareaProps> = ({
    label = "Textarea",
    placeholder = "Type a text",
    rows = 4,
    name = "",
    value = "",
    disabled = false,
    required = false,
    style = {},
    labelStyle = {},
    onChangeHandler = event => console.log("onChange event fired")
}) => {

    return (
        
        <React.Fragment>
            
            {label !== "" &&
                <Form.Label style={labelStyle}>
                {label}
                </Form.Label>
            }
            
            <Form.Control
                as="textarea"
                className="input-text-area"
                rows={rows}
                name={name}
                maxLength={256}
                disabled={disabled}
                placeholder={placeholder}
                defaultValue={value}
                required={required}
                style={style}
                onChange={onChangeHandler}
            />

        </React.Fragment>
    );
    
}

export default InputTextarea;