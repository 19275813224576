import React from "react";
import { Button, Modal } from "../../../../core/components/layout";
import { Row, Col } from "react-bootstrap";
import { OnDeleteNamespace, OnSetShowModalNamespaceDeletion, SelectedNamespaces, ShowModalNamespaceDeletion } from "../../types";
import { FaRegTrashCan } from "react-icons/fa6";
import { CgCloseO } from "react-icons/cg";
import { useTranslation } from "react-i18next";

interface ModalNamespacesDeleteProps {
    showModalNamespaceDeletion: ShowModalNamespaceDeletion,
    selectedNamespaces: SelectedNamespaces,
    onDeleteNamespace: OnDeleteNamespace,
    onSetShowModalNamespaceDeletion: OnSetShowModalNamespaceDeletion,
}

const ModalNamespacesDeleteComponents:React.FC<ModalNamespacesDeleteProps> = ({
    showModalNamespaceDeletion,   
    selectedNamespaces,
    onDeleteNamespace,
    onSetShowModalNamespaceDeletion

}) => {

    const { t } = useTranslation();

    /**
     *@descr function used as callback to close the deletion modal
     */
    const onCloseModal = () => {
        onSetShowModalNamespaceDeletion(false);
    }

    /**
     * @descr function used as callback to delete the selected namespaces data
     */
    const onClickDelete = () => {
        onDeleteNamespace(selectedNamespaces);
        onSetShowModalNamespaceDeletion(false);
    }

    return (
        <Modal
            visible={showModalNamespaceDeletion}
            size="lg"     
            onHideHandler={onCloseModal}    
        >
            <Row>
                <Col className="text-center">
                    <FaRegTrashCan style={{fontSize: 24, marginTop: "2rem", color: "#C2C2C2"}} />
                </Col>
            </Row>
            <Row>
                <Col className="text-center" style={{padding: "0 5rem", margin: "2rem 0"}}>
                    <h5>
                        {selectedNamespaces.length === 1 &&
                            <div>
                                <span>{t('views.namespace.list.modals.delete.single')}</span><br/><br/>
                                <small style={{margin: "1rem 0"}}>{selectedNamespaces[0].name}</small>
                            </div>
                        }
                        {selectedNamespaces.length !== 1 &&
                            <div>
                                <span>{t('views.namespace.list.modals.delete.bulk')}</span><br/><br/>
                                <small style={{margin: "1rem 0"}}>
                                    {selectedNamespaces.map((item:any, index:any) => {
                                                                if (index < selectedNamespaces.length-1) { 
                                                                    return (
                                                                        <span style={{margin: "1rem 0"}}>{item.name + ", "}<br/></span>    
                                                                    )
                                                                };
                                                                return item.name + "." })
                                                            }
                                </small>
                            </div>
                        }
                    </h5>
                </Col>
            </Row>
            <Row>
                <Col className="text-center" style={{margin: "0 0 2rem 0"}}>
                    <Button text={t('views.namespace.list.modals.delete.backButtonText')} variant="secondary" outline={true} onClickHandler={() => onCloseModal()} style={{width: "8rem", marginRight: "1rem"}} /> 
                    <Button text={t('views.namespace.list.modals.delete.deleteButtonText')} onClickHandler={() => onClickDelete()} style={{width: "8rem"}} /> 
                </Col>
            </Row>
        </Modal>
    );
}

export default ModalNamespacesDeleteComponents;