import { useEffect, useState } from "react";
import useNamespaceApi from "../../api/NamespaceApi";
import useUserApi from "../../api/UserApi";
import { createCookie, deleteCookie, deleteFromStorage, getFromStorage, saveToStorage } from "../../core/services";
import { ID } from "../../config";
import { REACT_APP_CLIENT_ID, REACT_APP_CODE_VERIFIER, REACT_APP_REDIRECT_URL, REST_API_URL } from "../../custom.config";
import { UnregistedUserModal } from "../../commons";
import UnassignedUserModal from "../../commons/unassigned-user-modal";
import ChatView from "./ChatView";
import { Namespace } from "../../data-structures/NamespaceType";
import { HcError } from "../../api/ApiErrors";

const SESSION_CHECK_TIME:number = 300000;

export default function ChatPage() {
  const [showUnregisteredModal, setShowUnregisteredModal] = useState(false);
  const [showUnassignedModal, setShowUnassignedModal] = useState(false);
  const [namespaces, setNamespaces] = useState<Namespace[]>([]);
  const { getNamespaceList, getNamespaces } = useNamespaceApi();
  const { getUsersData, getUserInfo, getUserTokenData } = useUserApi();

  /**
     * @desc function used to refresh the user session sending refresh_token
    **/
  const onSetNewUserSession = () => {
    let user_tokens = getFromStorage(`user_${ID}`);
    if(user_tokens) {
      const {refresh_token} = user_tokens;
      let user_form_data = new FormData();
      user_form_data.append("client_id", REACT_APP_CLIENT_ID);
      user_form_data.append("grant_type", "refresh_token");
      user_form_data.append("refresh_token", refresh_token);
      user_form_data.append("redirect_uri", REACT_APP_REDIRECT_URL);
      user_form_data.append("code_verifier", REACT_APP_CODE_VERIFIER);
      //Refresh token effect
      let resp = getUserTokenData(user_form_data);
      resp.then((data) => {
        if (data instanceof HcError) {
          onDestroyUserSession();
        } else {
          let old_session_data:any = getFromStorage(`user_${ID}`);
          let new_session_data:any = {};
          const {access_token, id_token, refresh_token, expires_in } = data;
          new_session_data = {
            ...old_session_data,
            access_token: access_token,
            refresh_token: refresh_token,
            expire_date: new Date().getTime() + ((expires_in-600)*1000),
          };
          createCookie(`token_${ID}`, id_token, expires_in*1000);
          saveToStorage(`user_${ID}`, new_session_data);
        };
      });
    }
  }

  /**
     * @desc function used to check the user session status
    **/
  const checkUserSession = () => {
    console.log("Checking user session...");
    let session_data:any = getFromStorage(`user_${ID}`);
    let check_timestamp = new Date().getTime();
    const {expire_date} = session_data;
    //Current time is greater than the cookie expiry time or less than 5 minutes
    if(check_timestamp >= expire_date || (expire_date-check_timestamp < SESSION_CHECK_TIME)) {
      onSetNewUserSession();
    }
  }

  /**
     * @descr function used to empty the storage from the current user session and return to the login page
     */
  const onDestroyUserSession = () => {
    let storage:any = deleteFromStorage(`user_${ID}`),
      cookie:any = deleteCookie(`token_${ID}`);
    if (storage && cookie) window.location.reload();
  }

  useEffect (() => {
    (async () => {
      let sessionData = getFromStorage(`user_${ID}`);
      let namespaceList = await getNamespaceList(sessionData);
      let user = await getUserInfo();
      if (namespaceList instanceof HcError) {
        if (namespaceList.response.code === 401) setShowUnregisteredModal(true);
        return false;
      };

      if (namespaceList.length === 0) {
        getUsersData().then((data) => {
          if (data === undefined) {
            setShowUnassignedModal(true);
            return false;
          } else {
            let session_data:any = getFromStorage(`user_${ID}`);
            //Updating session user data
            let new_session_data:any = {
              ...session_data,
              role: "admin",
              email: user.email,
            };
            saveToStorage(`user_${ID}`, new_session_data);
          };
        });
      } else {
        setNamespaces(await getNamespaces(namespaceList));
      };

      let session_data:any = getFromStorage(`user_${ID}`);
      //Updating session user data
      let new_session_data:any = {
        ...session_data,
        email: user.email,
      };
      saveToStorage(`user_${ID}`, new_session_data);
    })();

    checkUserSession();
    //Check user session every 5 minutes
    const interval = setInterval(() => {
      checkUserSession();
    }, SESSION_CHECK_TIME);

    return () => clearInterval(interval);
  }, [getNamespaceList, getNamespaces, getUserInfo, getUsersData]);

  return (
    <>
      <ChatView namespaces={namespaces}/>

      <UnregistedUserModal
          showModalExpiredSession={showUnregisteredModal}
          onDestroySession={onDestroyUserSession}
      />
      <UnassignedUserModal
          showModal={showUnassignedModal}
          onDestroySession={onDestroyUserSession}
      />
    </>
  );
};