import { Suspense } from 'react';
import Router from './Router';
import "../core/style/App.scss";
import { Provider } from 'react-redux';
import { store } from "../store/app.store";

function App() {

	return (
		<Provider store={store}>
			<Suspense>
				<Router />
			</Suspense>
		</Provider>
  	);
}

export default App;