import React from "react";
import { Modal as ModalComponent } from "react-bootstrap";
//Import panel types
import { Title, Visible, Size, Centered, Fullscreen, Animation, EnforceFocus, Children, OnHideHandler, OnShowHandler } from "./types";

interface ModalProps {
    title?: Title,
    visible?: Visible,
    size?: Size,
    centered?: Centered,
    fullscreen?: Fullscreen,
    animation?: Animation,
    enforceFocus?: EnforceFocus,
    children?: Children,
    isLoading?: boolean,
    onHideHandler?: OnHideHandler
    onShowHandler?: OnShowHandler
}

/*
 * Modal component
*/
const Modal: React.FC<ModalProps> = ({
        //default props
        title = undefined,
        size = "sm",
        centered = false,
        visible =  false,
        fullscreen = false,
        animation = true,
        enforceFocus = false,
        onHideHandler = () => console.log("Hide modal event fired"),
        onShowHandler = () => console.log("Show modal event fired"),
        isLoading = false,
        children
    }) => {

    return (
        <ModalComponent
            show={visible}
            animation={animation}
            size={size}
            centered={centered}
            fullscreen={fullscreen}
            enforceFocus={enforceFocus}
            onHide={onHideHandler}
            onShow={onShowHandler}
            style={{zIndex: 9999}}
            backdrop={isLoading ? "static" : undefined}
        >
            {title !== undefined &&
                <ModalComponent.Header closeButton>
                        <ModalComponent.Title>{title}</ModalComponent.Title>
                </ModalComponent.Header>
            }

            <ModalComponent.Body>
                {children}
            </ModalComponent.Body>
        </ModalComponent>
    );
}

export default Modal;
