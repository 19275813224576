import MenuItem from "@mui/material/MenuItem";
import { useCallback, useState } from "react";
import { useTranslation } from 'react-i18next';
import dayjs from "dayjs";
import CustomPopover, { usePopover } from "./custom-popover";
import Iconify from "../../components/iconify";
import { Stack, Typography } from "@mui/material";
import english from "../../assets/images/english.png";

export const allLangs = [
  {
    label: 'Italiano',
    value: 'it',
    icon: 'flagpack:it',
  },
  {
    label: 'English',
    value: 'en',
    icon: 'flagpack:gb-nir',
  },
];

export default function LanguagePopover() {
  const popover = usePopover();
  const { i18n, t } = useTranslation();

  const [currentLang, setCurrentLang] = useState(allLangs.filter((l) => i18n.language.includes(l.value))[0]);

  const handleChangeLang = useCallback((newLang: string) => {
    const selectedLanguage = allLangs.findIndex((option) => option.value === newLang);
    setCurrentLang(allLangs[selectedLanguage]);

    i18n.changeLanguage(allLangs[selectedLanguage].value);
    dayjs.locale(allLangs[selectedLanguage].value);
    popover.onClose();
  }, [i18n, popover]);

  return (
    <>
      <Stack direction={"row"} onClick={popover.onOpen} sx={{marginRight: "auto", width: "100%"}}>
        <Iconify icon="ooui:language" />
        <Typography variant="subtitle2">
          {t("settings.language")}
        </Typography>
      </Stack>

      <CustomPopover
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 160, marginLeft: "0px", p: 1 }}>
        {allLangs.map((option) => {
          return (
            option.value === 'en' ?
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={()=>handleChangeLang(option.value)}
              sx={{borderRadius: 50}}
            >
              <img width={28} height="auto" src={english} style={{marginRight: "1rem"}} />

              {option.label}
            </MenuItem> :
            <MenuItem
            key={option.value}
            selected={option.value === currentLang.value}
            onClick={()=>handleChangeLang(option.value)}
            sx={{borderRadius: 50}}
            >
              <Iconify icon={option.icon} sx={{ borderRadius: 0.65, width: 28 }} />

              {option.label}
            </MenuItem>
          );
        })}
      </CustomPopover>
    </>
  );
}
