import { getNamespacesMetadata } from "../../documents/sources/services";
import { setShowModalNamespaceConflict, setShowModalNamespaceCreation, setShowModalNamespaceMetadata } from "../../namespace/sources/namespace.reducer";
import { addUser, addUserGrantToNamespace, deleteAllChat, deleteUser, deleteUserGrantFromNamespace, getChats, getNamespacesData, getUserGrants, getUserInfo, getUserTokenData, getUsersData } from "./services";
import {
    setFilters,
    setIsPendingRequest,
    setRequestResponse,
    setRequestError,
    setUsersData,
    setSelectedUsers,
    setShowFilterSidebar,
    setActiveUser,
    setNamespacesData,
    setShowModalUsersDeletion,
    setShowFilterUserNamespacesSidebar,
    setSelectedNamespaces,
    setShowModalNamespaceDeletion,
    setShowModalUserConflict,
    setUserConflictedObject,
    setUserToken,
    setChatsData,
    setUserInfo,
    setNamespaceFilters,
    setShowModalUnregisteredUser,

} from "./users.reducer";

/**
 * @descr Effect used to invoke the service used for refresh the user session cookie
 * @param {Object<any>} session_data user session form data to send for the refresh
 */
export function requestSetUserSession (session_data:any) {
    return async (dispatch:any) => {
        dispatch (setIsPendingRequest(true));

        let refresh_user_session_response = await getUserTokenData(session_data);
        if (refresh_user_session_response.error !== undefined) {
            return(dispatch(setRequestError(refresh_user_session_response.error_description)));
        }

        dispatch (setUserToken(refresh_user_session_response));
        dispatch (setRequestResponse({code: 200, type: "session"}));
    }
}


/**
 * @descr This effects retrieves all users from the service.
 */
export function requestGetUsersData() {
    return async (dispatch:any) => {
        dispatch (setIsPendingRequest(true));

        let user_info:any = null;
        let retrieve_user_email_response:any = await getUserInfo();
        if (retrieve_user_email_response.status !== undefined) {
            return dispatch (setRequestError(retrieve_user_email_response));
        }

        let admin_namespaces_data_response:any = await getNamespacesData({role: "admin"});
        if (admin_namespaces_data_response.status !== undefined) {
            return dispatch ((setRequestError(admin_namespaces_data_response)));
        }

        if (admin_namespaces_data_response.namespaces.length > 0) {
            user_info = {role: "admin", email: retrieve_user_email_response.email};
            dispatch (setUserInfo(user_info));
        }

        if (admin_namespaces_data_response.namespaces.length === 0) {
            let supervisor_namespaces_data_response = await getNamespacesData({role: "supervisor"});
            if (supervisor_namespaces_data_response.status !== undefined) {
                return dispatch ((setRequestError(supervisor_namespaces_data_response)));
            }
            if (supervisor_namespaces_data_response.namespaces.length > 0) {
                user_info = {role: "supervisor", email: retrieve_user_email_response.email};
                dispatch (setUserInfo(user_info));

            } else {
                let user_namespaces_data_response = await getNamespacesData({role: "user"});
                if (user_namespaces_data_response.status !== undefined) {
                    return dispatch ((setRequestError(user_namespaces_data_response)));
                }
                user_info = {role: "user", email: retrieve_user_email_response.email};
                dispatch (setUserInfo(user_info));
            }
        }

        let chats_data_response = await getChats();
        if (chats_data_response !== undefined && chats_data_response.status !== undefined) {
            return dispatch ((setRequestError(chats_data_response)));
        }

        dispatch (setChatsData(chats_data_response.chats));

        let users_data_response:any = await getUsersData();
        if (users_data_response !== undefined && users_data_response.status !== undefined) {
            return dispatch (setRequestError(users_data_response));
        }

        if (users_data_response !== undefined) dispatch (setUsersData(users_data_response));
        dispatch (setIsPendingRequest(false));
    }
}

/**
 * @descr Effect use to invoke to request a creation of a new user to the service
 * @param {Object} data user data to create.
 */
export function requestAddUser(data:any) {
    return async (dispatch:any) => {
        dispatch (setIsPendingRequest(true));

        console.log(data)
        let add_user_response:any = await addUser(data);
        if (add_user_response !== null && add_user_response.status !== undefined && add_user_response.code !== 409) {
            return dispatch (setRequestError(add_user_response));
        }

        if (add_user_response !== null && add_user_response.status !== undefined && add_user_response.code === 409) {
            dispatch (setShowModalUserConflict(true));
            dispatch (setUserConflictedObject(data));
            return dispatch (setRequestError(add_user_response));
        }

        let users_data_response:any = await getUsersData();
        if (users_data_response.code !== undefined && !users_data_response.success) {
            return dispatch (setRequestError(users_data_response));
        }

        dispatch (setUsersData(users_data_response));
        dispatch (setRequestResponse({code: '200', type: 'add'}));
    }
}

/**
 * @descr Effect used to handle the visualization of the add users conflits modal
 * @param {boolean} modal TT show the modal, hide it otherwise
 */
export function requestSetShowModalUserConflict(modal:boolean) {
    return async (dispatch:any) => {
        if (!modal) dispatch (setUserConflictedObject(null));
        dispatch (setShowModalUserConflict(modal));
    }
}


/**
 * @descr Effect used to handle the users deletion modal.
 * @param {boolean} modal Show the modal if its value is true, hide it otherwise
 * @param {Array<Object>} users_data users data that handle the modal content
 */
export function requestSetShowModalUsersDeletion(modal:boolean, users_data?:any) {
    return async (dispatch:any) => {
        if (users_data && modal) dispatch (setSelectedUsers(users_data));
        if (!modal) dispatch (setSelectedUsers([]));
        dispatch (setShowModalUsersDeletion(modal));
    }
}

/**
 * @descr Effect that invokes the delete users service, then updates the global view statement
 * @param {Array<Object>} users_data user data to delete
 */
export function requestDeleteUsers(users_data:any) {
    return async (dispatch:any) => {
        dispatch (setIsPendingRequest(true));

        for (const user of users_data) {
            let delete_user_response = await deleteUser({email: user.email});
            if (delete_user_response !== undefined && delete_user_response.status !== undefined) {
                return dispatch (setRequestError(delete_user_response));
            }
        }

        let users_data_response:any = await getUsersData();
        if (users_data_response.code !== undefined && !users_data_response.success) {
            return dispatch (setRequestError(users_data_response));
        }

        dispatch (setUsersData(users_data_response));
        dispatch (setRequestResponse({code: '204', type: 'delete'}));
    }
}

/**
 * @descr Effect used to delete the active user grants from the selected namespaces.
 * @param {Object} user_data active user data
 * @param {Array<Object>} namespaces_data selected namespaces data
 */
export function requestDeleteUserGrantsFromNamespaces(user_data:any, namespaces_data:any) {
    return async (dispatch:any) => {
        dispatch (setIsPendingRequest(true));

        for (const namespace of namespaces_data) {
            let data:any = {
                email: user_data.email,
                namespace: namespace._id,
                policy: user_data.namespaces.filter((ns:any) => ns.namespace === namespace._id)[0].policy
            };

            let remove_user_grant_from_namespace:any = await deleteUserGrantFromNamespace(data);
            if (remove_user_grant_from_namespace !== undefined && remove_user_grant_from_namespace !== null && remove_user_grant_from_namespace.status !== undefined) {
                 return dispatch (setRequestError(remove_user_grant_from_namespace));
            }
        }

        // Retrieve the updated grants for the active user
        let user_grants_response:any = await getUserGrants(user_data.email);
        if (user_grants_response !== undefined && user_grants_response.status !== undefined) { return dispatch (setRequestError(user_grants_response)); }

        dispatch (setActiveUser({...user_data, namespaces: user_grants_response}));
        dispatch (setRequestResponse({code: '204', type: 'delete'}));
    }
}

/**
 * @descr Effect used to retrieve roles and generals data on namespaces assigned to the user.
 * @param {Object<any>} user_data The active user selected from the user table.
 */
export function requestSetActiveUser (user_data:any) {
    return async (dispatch:any) =>  {
        dispatch (setIsPendingRequest(true));
        dispatch (setNamespaceFilters({}));

        if (user_data !== null) {

            let user_grants_response:any = await getUserGrants(user_data.email);
            if (user_grants_response !== undefined && user_grants_response.status !== undefined) {
                return dispatch (setRequestError(user_grants_response));
            }

            let get_namespaces_data_response:any = await getNamespacesData({role: 'admin'});
            if (get_namespaces_data_response !== undefined && get_namespaces_data_response.status !== undefined) {
                return dispatch (setRequestError(get_namespaces_data_response));
            }

            if (get_namespaces_data_response !== undefined && get_namespaces_data_response !== null) {
                let get_namespaces_metadata_response:any = await getNamespacesMetadata(get_namespaces_data_response);
                if (get_namespaces_metadata_response !== undefined && get_namespaces_metadata_response.status !== undefined) {
                    return dispatch (setRequestError(get_namespaces_metadata_response));
                }
                if (get_namespaces_metadata_response !== undefined) {
                    dispatch (setNamespacesData(get_namespaces_metadata_response));
                }
            }

            dispatch (setActiveUser({...user_data, namespaces: user_grants_response}));

        }
        if (user_data === null) {
            dispatch (setActiveUser(null));
        }
        dispatch (setIsPendingRequest(false));
    }
}

/**
 * @descr Effect used to grant to the active user a base policy on the selected namespace
 * @param {Object} data active user data
 */
export function requestAddUserToNamespace (data:any) {
    return async (dispatch:any) =>  {
        dispatch (setIsPendingRequest(true));

        // add new user grant to the namespace
        let add_user_grant_to_namespace:any = await addUserGrantToNamespace(data);
        if (add_user_grant_to_namespace !== undefined && add_user_grant_to_namespace !== null && add_user_grant_to_namespace.status !== undefined) { return dispatch (setRequestError(add_user_grant_to_namespace)); }

        // Retrieve the updated grants for the active user
        let user_grants_response:any = await getUserGrants(data.email);
        if (user_grants_response.status !== undefined) { return dispatch (setRequestError(user_grants_response)); }

        dispatch (setActiveUser({...data, namespaces: user_grants_response}));
        dispatch (setRequestResponse({code: 201, type: "grants"}));

    }
}

/**
 * @descr Effect used to set on the global state the selected namespace list
 * @param {Array<Object>} namespaces list of namespace data
 */
export function requestSetSelectedNamespaces (namespaces:any) {
    return (dispatch:any) => {
        dispatch (setSelectedNamespaces(namespaces));
    }
}

/**
 * @descr Effect used to set on the global state the selected user list
 * @param {Array<Object>} users list of user data
 */
export function requestSetSelectedUsers (users:any) {
    return (dispatch:any) => {
        dispatch (setSelectedUsers(users));
    }
}

/**
 * @descr Effect used to handle the users advanced filters sidebar
 * @param {boolean} sidebar TT show the sidebar, hide it otherwise
 */
export function requestSetShowFilterSidebar (sidebar:boolean) {
    return (dispatch:any) =>  {
        dispatch (setShowFilterSidebar(sidebar));
    }
}

/**
 * @descr Effect used to handle the active user namespace advanced filters sidebar
 * @param {boolean} sidebar TT show the sidebar, hide it otherwise
 */
export function requestSetShowFilterUserNamespacesSidebar (sidebar:boolean) {
    return (dispatch:any) =>  {
        dispatch (setShowFilterUserNamespacesSidebar(sidebar));
    }
}

/**
 * @descr Effect used to handle the namespace deletion modal
 * @param {Array<Object>} namespaces_data namespaces data to delete
 * @param {boolean} modal TT show the modal, hide it otherwise
 */
export function requestSetShowModalNamespaceDeletion (modal:boolean, namespaces_data?:any) {
    return (dispatch:any) =>  {
        if (namespaces_data && modal) dispatch (setSelectedNamespaces(namespaces_data));
        if (!modal) dispatch (setSelectedNamespaces([]));
        dispatch (setShowModalNamespaceDeletion(modal));
    }
}

/**
 * @descr Effect used to invoke the servicies that update the active user policy on a set of namespace.
 * @param {Object} data active user data
 */
export function requestUpdateUserRoles(data:any) {
    return async (dispatch:any) => {
        dispatch (setIsPendingRequest(true));

        for (const item of data.namespaces) {
            if (item.pending_policy !== null && item.pending_policy !== undefined) {
                let data = {
                    email: item.email,
                    namespace: item.namespace,
                    policy: item.policy
                }

                // remove old user grants from a namespace
                let remove_user_grant_from_namespace:any = await deleteUserGrantFromNamespace(data);
                if (remove_user_grant_from_namespace !== undefined && remove_user_grant_from_namespace !== null && remove_user_grant_from_namespace.status !== undefined) {
                    return dispatch (setRequestError(remove_user_grant_from_namespace));
                }

                // add new user grant to the namespace
                let add_user_grant_to_namespace:any = await addUserGrantToNamespace({...data, policy: item.pending_policy});
                if (add_user_grant_to_namespace !== undefined && add_user_grant_to_namespace !== null && add_user_grant_to_namespace.status !== undefined) {
                    return dispatch (setRequestError(add_user_grant_to_namespace));
                }
            }
        }

        // Retrieve the updated grants for the active user.
        let user_grants_response:any = await getUserGrants(data.email);
        if (user_grants_response.status !== undefined) { return dispatch (setRequestError(user_grants_response)); }
        dispatch (setActiveUser({...data, namespaces: user_grants_response}));
        dispatch (setRequestResponse({code: 201, type: "grants"}));

    }
}

/**
 * @descr Effect used to update the advanced filters global statement
 * @param {Object} data advanced filter data
 */
export function requestSetFilters(filter_data:any) {
    return (dispatch:any) => {
        dispatch (setFilters(filter_data));
    }
}

/**
 * @descr Effect used to update the advanced filters global statement
 * @param {Object} data advanced namespace filter data
 */
export function requestSetNamespaceFilters(filter_data:any) {
    return (dispatch:any) => {
        dispatch (setNamespaceFilters(filter_data));
    }
}

/**
 *
 * @param user_data
 * @returns
 */
export function requestDeleteAllChat (user_data:any) {
    return async (dispatch:any) => {
        dispatch (setIsPendingRequest(true));

        let delete_all_user_chat_response:any = await deleteAllChat({owner_email: user_data});
        if (delete_all_user_chat_response.status !== undefined) {
            return dispatch(setRequestError(delete_all_user_chat_response));
        }

        let chats_data_response = await getChats();
        if (chats_data_response.status !== undefined) {
            return dispatch((setRequestError(chats_data_response)))
        }

        dispatch (setChatsData(chats_data_response.chats));
        dispatch (setRequestResponse({code: 200, type: "delete"}));
    }
}

/**
 * @descr Effect used to show an unregistered user modal hanlder
 * @param {boolean} modal TT show | FF hide
 */
export function requestSetShowModalUnregisteredUser (modal:any) {
    return async (dispatch:any) => {
        dispatch (setShowModalUnregisteredUser(modal));
    }
}