import {
    setActiveNamespace,
    setChatsData,
    setFilters,
    setIsPendingRequest,
    setNamespaceConflict,
    setNamespacesData,
    setNamespacesIcon,
    setRequestError,
    setRequestResponse,
    setSelectedNamespaces,
    setSelectedUsers,
    setShowFilterSidebar,
    setShowFilterUsersSidebar,
    setShowModalNamespaceConflict,
    setShowModalNamespaceCreation,
    setShowModalNamespaceDeletion,
    setShowModalNamespaceMetadata,
    setShowModalNamespaceUsersDeletion,
    setShowModalUnregisteredUser,
    setUserFilters,
    setUserInfo,
    setUserToken,
    setUsersData,
} from "./namespace.reducer";
import { addNamespace, addUserGrantToNamespace, deleteAllChat, deleteNamespace, deleteUserGrantFromNamespace, getChats, getNamespacesData, getNamespacesMetadata, getUserInfo, getUserTokenData, getUsersData, getUsersGrantsFromNamespace, updateNamespaceMetadata } from "./services";


const NAMESPACE_ICONS = [
    {
        icon: "#FF4F16",
    },
    {
        icon: "#EB8A06",
    },
    {
        icon: "#E2E200",
    },
    {
        icon: "#9BCA42",
    },
    {
        icon: "#2C950F",
    },
    {
        icon: "#19E5EE",
    },
    {
        icon: "#313DFF",
    },
    {
        icon: "#9831FF",
    }
];


/**
 * @descr Effect used to invoke the service used for refresh the user session cookie
 * @param {Object<any>} session_data user session form data to send for the refresh
 */
export function requestSetUserSession(session_data:any) {
    return async (dispatch:any) => {
        dispatch (setIsPendingRequest(true));

        let refresh_user_session_response = await getUserTokenData(session_data);
        if (refresh_user_session_response.error !== undefined) {
            return(dispatch(setRequestError(refresh_user_session_response.error_description)));
        }

        dispatch (setUserToken(refresh_user_session_response));
        dispatch (setRequestResponse({code: 200, type: "session"}));
    }
}

/**
 * @descr Effect used to retrieve the namespace metadata starting from the user role
 */
export function requestGetNamespacesData() {
    return async (dispatch:any) => {
        dispatch (setIsPendingRequest(true));

        let user_info:any = null;
        let retrieve_user_email_response:any = await getUserInfo();
        if (retrieve_user_email_response.status !== undefined) {
            return dispatch (setRequestError(retrieve_user_email_response));
        }

        let admin_namespaces_data_response:any = await getNamespacesData({role: "admin"});
        if (admin_namespaces_data_response.status !== undefined) {
            return dispatch ((setRequestError(admin_namespaces_data_response)));
        }

        if (admin_namespaces_data_response.namespaces.length > 0) {
            user_info = {role: "admin", email: retrieve_user_email_response.email};
            dispatch (setUserInfo(user_info));
        }

        if (admin_namespaces_data_response.namespaces.length === 0) {
            let supervisor_namespaces_data_response = await getNamespacesData({role: "supervisor"});
            if (supervisor_namespaces_data_response.status !== undefined) {
                return dispatch ((setRequestError(supervisor_namespaces_data_response)));
            }
            if (supervisor_namespaces_data_response.namespaces.length > 0) {
                user_info = {role: "supervisor", email: retrieve_user_email_response.email};
                dispatch (setUserInfo(user_info));

            } else {
                let user_namespaces_data_response = await getNamespacesData({role: "user"});
                if (user_namespaces_data_response.status !== undefined) {
                    return dispatch ((setRequestError(user_namespaces_data_response)));
                }
                user_info = {role: "user", email: retrieve_user_email_response.email};
                dispatch (setUserInfo(user_info));
            }
        }

        dispatch (setNamespacesIcon(NAMESPACE_ICONS));

        // Get user chats data
        let chats_data_response = await getChats();
        if (chats_data_response !== undefined && chats_data_response.status !== undefined) {
            return dispatch ((setRequestError(chats_data_response)));
        }

        dispatch (setChatsData(chats_data_response.chats));

        // Get the namespace data
        let retrieve_namespaces_data_response:any = await getNamespacesData({role: user_info.role});
        if (retrieve_namespaces_data_response !== undefined && retrieve_namespaces_data_response.status !== undefined) {
            return dispatch (setRequestError(retrieve_namespaces_data_response));
        }

        if (retrieve_namespaces_data_response === undefined) return dispatch (setRequestError({code: "500", type: "retrieve"}));
        if (retrieve_namespaces_data_response !== undefined) {
            // Get the metadata from the retrieved namespaces
            let retrieve_namespaces_metadata_response:any = await getNamespacesMetadata(retrieve_namespaces_data_response);
            if (retrieve_namespaces_metadata_response !== undefined && retrieve_namespaces_metadata_response.status !== undefined) {
                return dispatch (setRequestError(retrieve_namespaces_metadata_response));
            }

            dispatch (setNamespacesData(retrieve_namespaces_metadata_response));
            dispatch (setIsPendingRequest(false));
        }
    }
}


/**
 * @descr effect used to set the namespace icon global state
 */
export function requestGetNamespacesIcon() {
    return (dispatch:any) => {
        dispatch (setNamespacesIcon(NAMESPACE_ICONS));
    }
}

/**
 * @descr effect used to set the list of selected namespace on the global view state
 * @param {Array<any>} namespaces_data namespaces data
 */
export function requestSetSelectedNamespaces(namespaces_data:any) {
    return (dispatch:any) => {
        dispatch (setSelectedNamespaces(namespaces_data));
    }
}

/**
 * @descr effect used to set the list of selected users on the global view state
 * @param {Array<any>} users_data users data
 */
export function requestSetSelectedUsers(users_data:any) {
    return (dispatch:any) => {
        dispatch (setSelectedUsers(users_data));
    }
}

/**
 * @descr effect used to handle the visualization of the advanced namespaces filter sidebar
 * @param {boolean} sidebar TT show the sidebar, hide it otherwise
 */
export function requestSetShowFilterSidebar (sidebar:boolean) {
    return (dispatch:any) =>  {
        dispatch (setShowFilterSidebar(sidebar));
    }
}

/**
 * @descr effect used to handle the visualization of the advanced users filter sidebar
 * @param {boolean} sidebar TT show the sidebar, hide it otherwise
 */
export function requestSetShowFilterUsersSidebar (sidebar:boolean) {
    return (dispatch:any) =>  {
        dispatch (setShowFilterUsersSidebar(sidebar));
    }
}

/**
 * @descr effect used to show the users role details for the selected namespace.
 * @param {Object} namespace_data selected namespace data
 */
export function requestSetActiveNamespace (namespace_data:any) {
    return async (dispatch:any) => {
        dispatch (setIsPendingRequest(true));
        dispatch (setUserFilters({}));

        if (namespace_data === null) dispatch (setActiveNamespace(namespace_data));
        if (namespace_data !== null) {

            let get_users_grants_from_namespace_response:any = await getUsersGrantsFromNamespace(namespace_data._id);
            if (get_users_grants_from_namespace_response !== undefined && get_users_grants_from_namespace_response.status !== undefined) {
                return dispatch (setRequestError(get_users_grants_from_namespace_response));
            }

            let get_ns_users_roles = {...namespace_data, users: get_users_grants_from_namespace_response}
            let get_users_data_response:any = await getUsersData();
            if (get_users_data_response !== undefined && get_users_data_response.status !== undefined) {
                return (setRequestError(get_users_data_response));
            }

            dispatch (setUsersData(get_users_data_response));
            dispatch (setActiveNamespace(get_ns_users_roles));
        }

        dispatch (setIsPendingRequest(false));
    }
}

/**
 * @descr effect used to hanlde the visualization of the namespace creation modal
 * @param {boolean} modal TT show the modal, hide it otherwise
 */
export function requestSetShowModalNamespaceCreation (modal:boolean) {
    return (dispatch:any) =>  {
        dispatch (setShowModalNamespaceCreation(modal));
        if (!modal) dispatch (setNamespaceConflict(null));
    }
}

/**
 * @descr effect used to hanlde the visualization of the namespace deletion modal
 * @param {boolean} modal TT show the modal, hide it otherwise
 */
export function requestSetShowModalNamespaceConflict (modal:boolean) {
    return (dispatch:any) =>  {
        dispatch (setShowModalNamespaceConflict(modal));
    }
}

/**
 * @descr effect used to handle the visualization of the namespace metadata edit modal.
 *        if a namespace data is provided then show the modal initialize with that namespace data.
 * @param {booelan} modal TT show the modal, hide it otherwise
 * @param {Object} namespace_data namespace data
 */
export function requestSetShowModalNamespaceMetadata (modal:boolean, namespace_data?:any) {
    return (dispatch:any) =>  {
        if (namespace_data && modal) dispatch (setActiveNamespace(namespace_data));
        if (!modal) {
            dispatch (setActiveNamespace(null));
            dispatch (setNamespaceConflict(null));
        }
        dispatch (setShowModalNamespaceMetadata(modal));
    }
}


/**
 * @descr effect used to handle the visualization of the namespaces single/bulk deletion modal.
 * @param {boolean} modal TT show the modal, hide it otherwise
 * @param {Array<any>} namespaces_data selected namespaces data
 */
export function requestSetShowModalNamespaceDeletion (modal:boolean, namespaces_data?:any) {
    return (dispatch:any) =>  {
        if (namespaces_data && modal) dispatch (setSelectedNamespaces(namespaces_data));
        if (!modal) dispatch (setSelectedNamespaces([]));
        dispatch (setShowModalNamespaceDeletion(modal));
    }
}

/**
 * @descr effect used to handle the visualization of the users single/bulk deletion modal.
 * @param {boolean} modal TT show the modal, hide it otherwise
 * @param users_data selected users data
 */
export function requestSetShowModalNamespaceUsersDeletion (modal:boolean, users_data?:any) {
    return (dispatch:any) =>  {
        if (users_data && modal) dispatch (setSelectedUsers(users_data));
        if (!modal) dispatch (setSelectedUsers([]));
        dispatch (setShowModalNamespaceUsersDeletion(modal));
    }
}


/**
 * @descr effect used to grant a role to a user on a namespace
 * @param {Object} data
 */
export function requestAddNamespaceUser(data:any, namespace_data:any) {
    return async (dispatch:any) => {
        dispatch (setIsPendingRequest(true));
        let user_data = {
            email: data.user[0].email,
            policy: data.user_policy,
            namespace: namespace_data._id
        }

        let add_user_namespace_grant_response:any = await addUserGrantToNamespace(user_data);
        if (add_user_namespace_grant_response !== null && add_user_namespace_grant_response !== undefined && add_user_namespace_grant_response.status !== undefined) {
            return dispatch (setRequestError(add_user_namespace_grant_response));
        }

        let get_users_grants_from_namespace_response:any = await getUsersGrantsFromNamespace(namespace_data._id);
        if (get_users_grants_from_namespace_response !== undefined && get_users_grants_from_namespace_response.status !== undefined) {
            return dispatch (setRequestError(get_users_grants_from_namespace_response));
        }

        let active_namespace_data = {
            ...namespace_data,
            users: get_users_grants_from_namespace_response
        }

        dispatch (setActiveNamespace(active_namespace_data));
        dispatch (setRequestResponse({code: "201", type: "grants"}));
    }
}


/**
 * @descr effect used to update users grants on the active namespace
 * @param {Object} data object containing both namespace data and selected users grants to udpate
 */
export function requestUpdateNamespaceUsersGrant(data:any) {
    return async (dispatch:any) => {
        dispatch (setIsPendingRequest(true));

        for (const item of data.users) {
            if (item.pending_policy !== null) {

                let data = {
                    email: item.email,
                    namespace: item.namespace,
                    policy: item.policy
                }

                // remove old user grants from a namespace
                let remove_user_grant_from_namespace:any = await deleteUserGrantFromNamespace(data);
                if (remove_user_grant_from_namespace !== undefined && remove_user_grant_from_namespace !== null && remove_user_grant_from_namespace.status !== undefined) {
                    return dispatch (setRequestError(remove_user_grant_from_namespace));
                }

                // add new user grant to the namespace
                let add_user_grant_to_namespace:any = await addUserGrantToNamespace({...data, policy: item.pending_policy});
                if (add_user_grant_to_namespace !== undefined && add_user_grant_to_namespace !== null && add_user_grant_to_namespace.status !== undefined) {
                    return dispatch (setRequestError(add_user_grant_to_namespace));
                }
            }
        }

        let get_users_grants_from_namespace_response:any = await getUsersGrantsFromNamespace(data._id);
        if (get_users_grants_from_namespace_response !== undefined && get_users_grants_from_namespace_response.status !== undefined) {
            return dispatch (setRequestError(get_users_grants_from_namespace_response));
        }

        let active_namespace_data = {
            ...data,
            users: get_users_grants_from_namespace_response
        }

        dispatch (setActiveNamespace(active_namespace_data));
        dispatch (setRequestResponse({code: "201", type: "grants"}));
    }
}


/**
 * @descr effect used to delete the selected users grant from the active namespace
 * @param {Object} namespace_data active namespace data
 * @param {Array<any>} users_data selected user data
 */
export function requestDeleteUsersGrantsFromNamespace(namespace_data:any, users_data:any) {
    return async (dispatch:any) => {
        dispatch (setIsPendingRequest(true));
        for (const user of users_data) {
            let user_data:any = {
                namespace: namespace_data._id,
                email: user.email,
                policy: user.policy
            }

            let delete_user_grant_from_namespace_response:any = await deleteUserGrantFromNamespace(user_data);
            if (delete_user_grant_from_namespace_response !== undefined && delete_user_grant_from_namespace_response.status !== undefined) {
                return dispatch (setRequestError(delete_user_grant_from_namespace_response));
            }
        }

        let get_users_grants_from_namespace_response:any = await getUsersGrantsFromNamespace(namespace_data._id);
        if (get_users_grants_from_namespace_response !== undefined && get_users_grants_from_namespace_response.status !== undefined) {
            return dispatch (setRequestError(get_users_grants_from_namespace_response));
        }

        let active_namespace_data = {
            ...namespace_data,
            users: get_users_grants_from_namespace_response
        }

        dispatch (setActiveNamespace(active_namespace_data));
        dispatch (setRequestResponse({code:"204", type: "delete"}));
    }
}


/**
 * @descr effect used to send an add request of a new namespace to the service
 * @param {Object<any>} data namespace data
 */
export function requestAddNamespace(data:any) {
    return async (dispatch:any) => {

        dispatch (setIsPendingRequest(true));

        let add_namespace_response:any = await addNamespace(data);
        if (add_namespace_response !== undefined && add_namespace_response.status !== undefined && add_namespace_response.code !== 409) {
            return dispatch (setRequestError(add_namespace_response));
        }

        if (add_namespace_response !== undefined &&add_namespace_response.status !== undefined && add_namespace_response.code === 409) {
            let conflict_data = {
                ...data,
                conflict: true,
                new_name: data.name
            };

            dispatch (setShowModalNamespaceCreation(false));
            dispatch (setShowModalNamespaceConflict(true));
            dispatch (setIsPendingRequest(false));
            return dispatch (setNamespaceConflict(conflict_data));
        }

        // Get the namespace data
        let retrieve_namespaces_data_response:any = await getNamespacesData({role: "admin"});
        if (retrieve_namespaces_data_response !== undefined && retrieve_namespaces_data_response.status !== undefined) {
            return dispatch (setRequestError(retrieve_namespaces_data_response));
        }

        if (retrieve_namespaces_data_response === undefined) return dispatch (setRequestError({code: "500", type: "retrieve"}));
        if (retrieve_namespaces_data_response !== undefined) {
            // Get the metadata from the retrieved namespaces
            let retrieve_namespaces_metadata_response:any = await getNamespacesMetadata(retrieve_namespaces_data_response);
            if (retrieve_namespaces_metadata_response !== undefined && retrieve_namespaces_metadata_response.status !== undefined) {
                return dispatch (setRequestError(retrieve_namespaces_metadata_response));
            }

            dispatch (setNamespacesData(retrieve_namespaces_metadata_response));
            dispatch (setShowModalNamespaceCreation(false));
            dispatch (setRequestResponse({code: "201", type: "add"}));
        }

    }
}

/**
 * @descr Effect used to update the selected namespace matadata.
 *        Before performing the update, a check is made on the new name (if specified), notifying the user in case of conflict.
 * @param {Object<any>} data namespace data
 * @param {Array<any>} namespaces_data namespaces data
 */
export function requestUpdateNamespaceMetadata(data:any, namespaces_data:any) {
    console.log(data, namespaces_data)
    return async (dispatch:any) => {
        dispatch (setIsPendingRequest(true));

        // Local check on possible namespaces name conflict
        let local_check_conflicted_namespace = namespaces_data.filter((item:any) => item.name === data.new_name)[0];
        if (local_check_conflicted_namespace !== undefined && Object.keys(local_check_conflicted_namespace).length > 0) {
            dispatch ( setNamespaceConflict ({
                ...data,
                conflict: true,
            }));

            dispatch (setShowModalNamespaceConflict(true));
            dispatch (setShowModalNamespaceMetadata(false));
            return dispatch (setRequestError({code: "409", type: "update"}));
        }

        // Update the namespace metadata

        let ic: string = "";
        if (data.new_icon !== null) ic = data.new_icon[0];
        let namespace_data:any = {
                _id: data.id,
                new_name: data.new_name,
                new_icon: ic,
            }

        let udpate_namespace_metadata_response:any = await updateNamespaceMetadata(namespace_data);
        if (udpate_namespace_metadata_response !== undefined && udpate_namespace_metadata_response.status !== undefined) {
            return dispatch (setRequestError(udpate_namespace_metadata_response));
        }

        // Get the namespace data
        let user_data:any = {role: 'admin'};
        let retrieve_namespaces_data_response:any = await getNamespacesData(user_data);
        if (retrieve_namespaces_data_response !== undefined && retrieve_namespaces_data_response.status !== undefined) {
            return dispatch (setRequestError(retrieve_namespaces_data_response));
        }

        if (retrieve_namespaces_data_response === undefined) return dispatch (setRequestError({code: "500", type: "metadata"}));
        if (retrieve_namespaces_data_response !== undefined) {
            // Get the metadata from the retrieved namespaces
            let retrieve_namespaces_metadata_response:any = await getNamespacesMetadata(retrieve_namespaces_data_response);
            if (retrieve_namespaces_metadata_response !== undefined && retrieve_namespaces_metadata_response.status !== undefined) {
                return dispatch (setRequestError(retrieve_namespaces_metadata_response));
            }

            dispatch (setNamespacesData(retrieve_namespaces_metadata_response));
            dispatch (setActiveNamespace(null));
            dispatch (setNamespaceConflict(null));
            dispatch (setShowModalNamespaceMetadata(false));
            dispatch (setRequestResponse({code: "200", type: "metadata"}));
        }

    }
}

/**
 * @desct effect used to request the deletions of a list of namespace
 * @param {Array<any>} namespaces_data namespaces data
 */
export function requestDeleteNamespace(namespaces_data:any) {
    return async (dispatch:any) => {
        dispatch (setIsPendingRequest(true));

        for (const namespace of namespaces_data) {
            let namespace_data:any = {
                _id: namespace._id
            }

            let delete_namespace_response:any = await deleteNamespace(namespace_data);
            if (delete_namespace_response !== undefined && delete_namespace_response.status !== undefined) {
                return dispatch (setRequestError(delete_namespace_response));
            }
        }

        // Get the namespace data
        let user_data:any = {role: 'admin'};
        let retrieve_namespaces_data_response:any = await getNamespacesData(user_data);
        if (retrieve_namespaces_data_response !== undefined && retrieve_namespaces_data_response.status !== undefined) {
            return dispatch (setRequestError(retrieve_namespaces_data_response));
        }

        if (retrieve_namespaces_data_response === undefined) return dispatch (setRequestError({code: "500", type: "delete"}));
        if (retrieve_namespaces_data_response !== undefined) {
            // Get the metadata from the retrieved namespaces
            let retrieve_namespaces_metadata_response:any = await getNamespacesMetadata(retrieve_namespaces_data_response);
            if (retrieve_namespaces_metadata_response !== undefined && retrieve_namespaces_metadata_response.status !== undefined) {
                return dispatch (setRequestError(retrieve_namespaces_metadata_response));
            }

            dispatch (setNamespacesData(retrieve_namespaces_metadata_response));
            dispatch (setActiveNamespace(null));
            dispatch (setNamespaceConflict(null));
            dispatch (setShowModalNamespaceMetadata(false));
            dispatch (setRequestResponse({code: "204", type: "delete"}));
        }

    }
}


/**
 * @descr Effect used to set the advanced filters global state
 * @param {Object<any>} filter_data advanced filters data
 */
export function requestSetFilters(filter_data:any) {
    return (dispatch:any) => {
        dispatch (setFilters(filter_data));
    }
}

/**
 * @descr Effect used to set the advanced filters global state
 * @param {Object<any>} filter_data advanced user filters data
 */
export function requestSetUserFilters(filter_data:any) {
    return (dispatch:any) => {
        dispatch (setUserFilters(filter_data));
    }
}


export function requestDeleteAllChat (user_data:any) {
    return async (dispatch:any) => {
        dispatch (setIsPendingRequest(true));

        let delete_all_user_chat_response:any = await deleteAllChat({owner_email: user_data});
        if (delete_all_user_chat_response.status !== undefined) {
            return dispatch (setRequestError(delete_all_user_chat_response));
        }

        let chats_data_response = await getChats();
        if (chats_data_response.status !== undefined) {
            return dispatch ((setRequestError(chats_data_response)))
        }

        dispatch (setChatsData(chats_data_response.chats));
        dispatch (setRequestResponse({code: 200, type: "delete"}));
    }
}

/**
 * @descr Effect used to show an unregistered user modal hanlder
 * @param {boolean} modal TT show | FF hide
 */
export function requestSetShowModalUnregisteredUser (modal:any) {
    return async (dispatch:any) => {
        dispatch (setShowModalUnregisteredUser(modal));
    }
}