import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import InputSearch from "../../../../core/components/form/InputSearch/InputSearch";
import { Button, Loader } from "../../../../core/components/layout";
import { InputText } from "../../../../core/components/form";
import {
    NamespacesData,
    SelectedNamespaces,
    Filters,
    OnSetFilters,
    OnSetSelectedNamespaces,
    ActiveUser,
    OnSetActiveUser,
    OnSetShowFilterUserNamespacesSidebar,
    OnSetShowModalNamespaceDeletion,
    OnUpdateUserRoles,
    IsPendingRequest,
    OnAddUserToNamespace,
} from "../../types";
import { IoSearch } from "react-icons/io5";
import { FaFilter, FaRegUser } from "react-icons/fa6";
import { FiFilter } from "react-icons/fi";

// services
import { dateToTimestamp } from "../../../../core/services/date.services";
import { sortJsonByKey } from "../../../../core/services";
import { useTranslation } from "react-i18next";
import UsersNamespaceTable from "./UserNamespaceTable";
import { UserNamespaceData } from "./UserNamespaceType";
import { deleteUserGrantFromNamespace } from "../../sources/services";
import CustomModal from "../../../../commons/CustomModal";
import { Grid } from "@mui/material";



const DEFAULT_FORM = {
    namespace: [],
    email: null,
    policy: 'user'
}

interface TableUsersNamespaceProps {
    isPendingRequest: IsPendingRequest,
    filters: Filters,
    namespacesData: NamespacesData,
    activeUser: ActiveUser,
    selectedNamespaces: SelectedNamespaces,
    onSetFilters: OnSetFilters,
    onSetActiveUser: OnSetActiveUser,
    onUpdateUserRoles: OnUpdateUserRoles,
    onSetSelectedNamespaces: OnSetSelectedNamespaces,
    onSetShowFilterUserNamespacesSidebar: OnSetShowFilterUserNamespacesSidebar,
    onSetShowModalNamespaceDeletion: OnSetShowModalNamespaceDeletion,
    onAddUserToNamespace: OnAddUserToNamespace,
}

const TableUsersNamespaceComponent:React.FC<TableUsersNamespaceProps> = ({
        isPendingRequest,
        filters,
        activeUser,
        namespacesData,
        selectedNamespaces,
        onSetFilters,
        onSetSelectedNamespaces,
        onUpdateUserRoles,
        onSetShowFilterUserNamespacesSidebar,
        onSetShowModalNamespaceDeletion,
        onSetActiveUser,
        onAddUserToNamespace,

    }) => {

    const { t } = useTranslation();
    const form_ref:any = useRef(null);
    const [tableData, setTableData] = useState<any>([]);
    const [formData, setFormData] = useState<any>(DEFAULT_FORM);
    const [namespacesOpt, setNamespacesOpt] = useState<any>([]);
    const [batchDeleteModal, setBatchDeleteModal] = useState<boolean>(false);
    const [itemsToBeDeleted, setItemsToBeDeleted] = useState<UserNamespaceData[]>([]);
    const [batchDeleteError, setBatchDeleteError] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [roleSwitch, setRoleSwitch] = useState<boolean>(false);
    const [filterSet, setFilterSet] = useState<boolean>(false);

    const batchDelete = useCallback((selected: UserNamespaceData[]) => {
        setBatchDeleteModal(true);
        setItemsToBeDeleted(selected);
    }, []);

    const confirmedBatchDelete = () => {
        setBatchDeleteModal(false);
        let notDeleted: UserNamespaceData[] = [];
        itemsToBeDeleted.every(async (item) => {
            let resp = await deleteUserGrantFromNamespace({
                email: item.email,
                namespace: item.namespace,
                policy: item.policy
            });
            if (resp !== undefined && resp.code !== undefined) {
                notDeleted.push(item);
            }
            setBatchDeleteModal(false);
            setItemsToBeDeleted([]);
            if (notDeleted.length > 0) {
                setErrors(notDeleted.map((item) => item.namespace_name));
                setBatchDeleteError(true);
            } else {
                window.location.reload();
            }
        });
    };

    /**
     * @descr function used to contains the selected namespace row data
     * @param namespace namespace data used to check if it need to be insert or filter from the list
     * @returns list of namespaces data
     */
    const setSelectedNamespaces = (namespace:number) => {
        if (selectedNamespaces.some((item:any) => item._id === namespace)) {
            let selected_namespaces = selectedNamespaces.filter((item:any) => item._id !== namespace);
            return(selected_namespaces);
        } else {
            let selected_namespaces = namespacesData.filter((item:any) => item._id === namespace);
            return(selectedNamespaces.concat(selected_namespaces));
        }
    }

    /**
     * @descr function used to delete user grants from a single or multiple (on bulk selection) namespaces.
     * @param event click event trigger
     * @param {Object} namespace_data selected namespace data
     */
    const onClickDeleteNamespace = (event:any, namespace_data?:any) => {
        event.preventDefault();
        if (namespace_data !== undefined) {
            const { namespace } = namespace_data;
            let selected_namespaces = setSelectedNamespaces(namespace);
            onSetShowModalNamespaceDeletion(true, selected_namespaces);
        }
        if (namespace_data === undefined) {
            onSetShowModalNamespaceDeletion(true);
        }
        onSetShowModalNamespaceDeletion(true);
    }

    /**
     * @descr function used to notify and track the pending users policy change
     * @param {string} namespace_id namespace identificator
     * @param {string} policy user pendant policy
    */
    const onChangeUserPolicy = (namespace:any, policy:any) => {
        let table_data = tableData.map((user:any) => {
            if (user.namespace === namespace && user.policy !== policy) { return {...user, pending_policy: policy }; }
            if (user.namespace === namespace && user.policy === policy) { return {...user, pending_policy: null }; }
            if (user.namespace !== namespace) return user;
        });
        setTableData(table_data);
    }

    /**
     * @descr Omit a specific field from an object passed as an argument
     * @param key field to omit
     * @param obj target object
     * @returns object without the field identify
    */
    function omit(key:any, obj:any) {
        const { [key]: omitted, ...rest } = obj;
        return rest;
    }

    /**
     * @descr function used to set the advanced filters
     * @param {string} field identify the advanced filter field
     * @param {any} data data to assign to the relative field
     */
    const onChangeAdvancedFilter = (field:any, data:any) => {
        let advanced_fitlers = (data === "") ? omit('namespace', filters) : {...filters, [field]: data}
        onSetFilters(advanced_fitlers);
        setFilterSet(!filterSet);
    }

    /**
     * @descr function used to assign the active user to the selected namespace
     * @param {any} data namespace data
     */
    const onChangeInputSearch = (data:any) => {
        if (form_ref.current !== null) form_ref.current.reset();
        if (data.length === 0) {
            setFormData(DEFAULT_FORM);

        } else {
            let form_data = {
                ...formData,
                email: activeUser.email,
                namespace: data
            };
            setFormData(form_data);
        }
    }

    /**
     * @descr function used to handle the visualization of the advanced filters sidebar modal
     * @param {Object} event click event trigger
     */
    const onClickFilter = (event:any) => {
        event.preventDefault();
        onSetShowFilterUserNamespacesSidebar(true);
    }

    /**
     * @descr function used to grant to the active user a role on the selected namespace.
     * @param {Object} event click event trigger
     */
    const onClickAddNamespace = (event:any) => {
        event.preventDefault();

        let form_data:any = {
            email: formData.email,
            namespace: formData.namespace[0]._id,
            policy: formData.policy
        }

        onAddUserToNamespace(form_data);
        setFormData(DEFAULT_FORM);
        if (form_ref.current !== null) form_ref.current.reset();
    }

    /**
     * @descr function used to update to the active user a role on the selected namespaces.
     * @param {Object} event click event trigger
     */
    const onClickConfirm = (event:any) => {
        event.preventDefault();
        onUpdateUserRoles({...activeUser, namespaces: tableData});
        setRoleSwitch(!roleSwitch);
    }

    /**
     * @descr function use to reset the active user statement and return to the previews view content.
     * @param {Object} event click event trigger
     */
    const onClickBack = (event:any) => {
        event.preventDefault();
        onSetActiveUser(null);
        setFormData(DEFAULT_FORM);
        onSetSelectedNamespaces([]);
        if (form_ref.current !== null) form_ref.current.reset();
    }

    /**
     * @descr function used to handle the selection of the table rows.
     */
    useEffect (() => {
        if (selectedNamespaces.length > 0) {
            let table_data = tableData
                                .map((item:any) => {
                                    if (selectedNamespaces.some((el:any) => item.namespace === el._id) === true) return {...item, selected: true};
                                    if (selectedNamespaces.some((el:any) => item.namespace === el._id) === false) return {...item, selected: false};
                                });
            setTableData(table_data);
        }
        if (selectedNamespaces.length === 0) {
            let table_data:any = tableData.map((item:any) => { return {...item, selected: false}})
            setTableData(table_data);
        }
    }, [selectedNamespaces]);


    /**
     * @descr function used to show the user the filtered list of possible ns to which the active user can be inserted.
     */
    useEffect (() => {
        if (form_ref.current !== null) form_ref.current.reset();
        if (activeUser !== null && namespacesData.length > 0) {
            if (activeUser.namespaces.length > 0) {
                let get_user_namespaces_id = activeUser.namespaces.map((ns:any) => ns.namespace);
                let namespaces_diff = namespacesData.filter((ns:any) => !(get_user_namespaces_id.includes(ns._id)));
                setNamespacesOpt(namespaces_diff);
            } else {
                setNamespacesOpt(namespacesData);
            }
        }

    }, [activeUser, namespacesData]);

    /**
    * @descr useEffect callback used to filter the data retrive from the service and set the table content
    */
    useEffect (() => {
        if (form_ref.current !== null) form_ref.current.reset();
        if (activeUser !== null) {

            const {namespace, policy, date_start, date_end} = filters;
            let table_data:any = sortJsonByKey(
                                                activeUser.namespaces
                                                .map((item:any) => {
                                                    let retreivedNamespace = namespacesData.filter((ns:any) => ns._id === item.namespace)[0];

                                                    if (retreivedNamespace === undefined) return null;

                                                    return {
                                                        ...item,
                                                        namespace_name: retreivedNamespace.name,
                                                        icon: retreivedNamespace.icon,
                                                        timestamp: dateToTimestamp(item.creation_date, {format: "YYYY_MM_DD"}),
                                                        pending_policy: null
                                                }})
                                                .map((item:any) => {
                                                    if (selectedNamespaces.some((el:any) => item.namespace === el._id) === true) return {...item, selected: true};
                                                    if (selectedNamespaces.some((el:any) => item.namespace === el._id) === false) return {...item, selected: false};
                                                })
                                                .filter((item:any) => {
                                                    if (item.namespace_name !== undefined) {
                                                        if (namespace === '' || namespace === undefined || item.namespace_name.toLowerCase().includes(namespace.toLowerCase())) { return item; }
                                                    } else {
                                                        if (namespace === '' || namespace === undefined) { return item; }
                                                    }
                                                })
                                                .filter((item:any) => {
                                                    if (policy === undefined || policy.length === 0 || policy[0].toLowerCase() === item.policy.toLowerCase()) { return item; }
                                                })
                                                .filter((item:any) => {
                                                    if (date_start === undefined || date_start === null || item.timestamp >= date_start) { return item; }
                                                })
                                                .filter((item:any) => {
                                                    if (date_end === undefined || date_end === null || item.timestamp <= date_end) { return item; }
                                                }),
                                                'timestamp'
                                    );
            setTableData(table_data);
        }
    }, [activeUser, filters]);

    const clearValue = () => {
        let advanced_fitlers:any = omit('namespace', filters);
        onSetFilters(advanced_fitlers);
    };

    return (
        <Container>
            {isPendingRequest &&
                <Loader absolute={true} />
            }

            {/* Header info */}
            <Row style={{margin:"0 0 1rem -1rem"}}>
                <div style={{display: "flex"}}>
                    <h5> <FaRegUser style={{fontSize: 16, margin: "-5px 0 0 0"}} /> {`${activeUser.email} ${t('views.users.grants.title')} `} </h5>
                </div>
            </Row>

            {/* Namespace name filter */}
            <Row>
                <Grid container>
                    <Grid item xs={6}>
                        <InputText
                            label=""
                            value={filters.namespace !== undefined ? filters.namespace : ''}
                            placeholder={t('views.users.grants.inputFilterPlaceholder')}
                            headComponent={<IoSearch style={{color: "#15ccff", marginLeft: ".25rem"}}/>}
                            onChangeHandler={(e:any) => onChangeAdvancedFilter('namespace', e.target.value)}
                            action={clearValue}
                        />
                    </Grid>
                    <Grid item xs={2} sx={{marginLeft: "auto"}}>
                        <Button variant="light" onClickHandler={onClickFilter} style={{marginBottom: "1rem"}}>
                                <small style={{fontSize: 14}}>{t('views.users.grants.filterButtonText')}</small>
                                {Object.keys(filters).length === 0 && <FiFilter style={{marginLeft: ".3rem", marginBottom: ".1rem"}}/>}
                                {Object.keys(filters).length > 0 && <FaFilter style={{marginLeft: ".3rem", marginBottom: ".1rem"}}/>}
                        </Button>
                    </Grid>
                </Grid>
            </Row>

            {/* Add an existing namespace to the active user */}
            <Form ref={form_ref}>
                <Row>
                    <Grid container>
                        <Grid item xs={6}>
                            <InputSearch
                                label=""
                                placeholder={t('views.users.grants.inputNamespacePlaceholder')}
                                options={namespacesOpt}
                                labelKey={(ns:any) => ns.name}
                                selected={formData.namespace.length > 0 ? formData.namespace : []}
                                onChangeHandler={(data:any) => onChangeInputSearch(data)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                disabled={formData.email === null}
                                variant={formData.email === null ? "secondary" : "primary"}
                                style={{width: "75%"}}
                                onClickHandler={(e:any) => onClickAddNamespace(e)}
                            >
                                <small>{t('views.users.grants.namespaceButtonText')}</small>
                            </Button>
                        </Grid>
                    </Grid>
                </Row>
            </Form>
            <Row>
                <Col>
                    <UsersNamespaceTable
                        data={tableData.filter((item:any) => item.namespace_name !== undefined)}
                        onChangeUserPolicy={onChangeUserPolicy}
                        onClickDeleteNamespace={onClickDeleteNamespace}
                        batchDelete={batchDelete}
                        roleSwitch={roleSwitch}
                        filterSet={filterSet}
                        />
                </Col>
            </Row>
            <Row>
                <div style={{display: "flex", paddingTop: "10px"}}>
                    <Button text={t('views.users.grants.backButtonText')} variant="secondary" outline onClickHandler={(e:any) => onClickBack(e)} style={{padding: "9px 45px", margin: "0 10px 0 0", color: "#000"}} />
                    <Button text={t('views.users.grants.saveButtonText')} disabled={!(tableData.some((item:any) => item.pending_policy !== null))} onClickHandler={(e:any) => onClickConfirm(e)} style={{padding: "9px 45px"}} />
                </div>
            </Row>

            <CustomModal
                singleButton={false}
                textList={[
                    'views.users.grants.modals.delete.bulk',
                    itemsToBeDeleted.map((item) => ` ${item.namespace_name}`).toString(),
                ]}
                showModal={batchDeleteModal}
                onConfirm={confirmedBatchDelete}
                onCloseModal={() => {
                    setBatchDeleteModal(false);
                    setItemsToBeDeleted([]);
                }}
            />

            <CustomModal
                singleButton={true}
                button1Text="OK"
                textList={[
                    "modal.users.userError",
                    errors.toString()
                ]}
                showModal={batchDeleteError}
                onConfirm={() => {
                    setBatchDeleteError(false);
                    setErrors([]);
                    window.location.reload();
                }}
            />

        </Container>

    )

}

export default TableUsersNamespaceComponent;
