import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { default as translations_data } from "../assets/translations/translation.json";


const DETECTION_OPTIONS:any = {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage']
};

export const I18N = i18n
                        .use(LanguageDetector) // check the default browser language
                        .use(initReactI18next) // passes i18n down to react-i18next
                        .init({
                            resources: translations_data,
                            detection: DETECTION_OPTIONS,
                            fallbackLng: "it", // tmp
                            interpolation: {
                                escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
                            }
                        });
