import React from "react";
import { Spinner } from "react-bootstrap";
//Import alert types
import type { Animation, Size, Absolute, Variant, As } from "./types";

interface LoaderProps {
    variant?: Variant,
    animation?: Animation,
    size?: Size,
    absolute?: Absolute,
    as?: As
}

/*
 * Loader component
*/
const Loader: React.FC<LoaderProps> = ({
        //default props
        animation = "grow",
        size = "sm",
        absolute = false,
        variant = "primary",
        as = "div"
    }) => {

    //Animation types
    const animation_types = ["grow", "border"];
    const animation_type = (animation_types.includes(animation) ? animation : "grow");
    //Variant type
    const variant_types = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"];
    const variant_type = (variant_types.includes(variant) ? variant : "primary");

    return(
        <div className={`loader ${absolute ? "absolute" : ""}`}>
            <Spinner animation={animation_type} size={size} variant={variant_type} as={as} />
        </div>
    );
}

export default Loader;
