import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { LoginContent } from "./content";
import { REACT_APP_CLIENT_ID, REACT_APP_REDIRECT_URL, REACT_APP_CODE_VERIFIER  } from "../../custom.config";
import withLogin from "./sources/connectors/login.connector";

const REDIRECT_URL:string = REACT_APP_REDIRECT_URL ? REACT_APP_REDIRECT_URL : `${window.location.origin}/login`;

const LoginContainer:React.FC = (props:any) => {

    const [ searchParams ] = useSearchParams();

    /**
     * useEffect callback used to check the code from Microsoft login service and get the ids
    */
    useEffect (() => {

        let token = searchParams.get("code");

        if (token !== null) {
            let user_data:any = new FormData();
            user_data.append("client_id", REACT_APP_CLIENT_ID);
            user_data.append("code", token);
            user_data.append("grant_type", "authorization_code");
            user_data.append("redirect_uri", REDIRECT_URL);
            user_data.append("code_verifier", REACT_APP_CODE_VERIFIER);
            //Perform login request
            props.requestLoginUser(user_data);
        }

    }, []);
    
    return (
        <LoginContent 
            {...props}
        />
    );

}

export default withLogin(LoginContainer);