import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { LuSendHorizonal } from "react-icons/lu";
import { Button } from "../../core/components/layout";
import { InputText } from "../../core/components/form";
import { Namespace } from "../../data-structures/NamespaceType";
import logo from "../../core/images/HYPERCHAT_LOGO_pittogramma.svg";
import "./chat.scss";
import { trimStr } from "../../core/services";

type Props = {
  namespace: Namespace | undefined;
  onSendFirstMessage: (message: string) => void;
  handleResetHistory: () => void;
};

export default function NewChat({ namespace, onSendFirstMessage, handleResetHistory }: Props) {
  const { t } = useTranslation();
  const [userQuestion, setUserQuestion] = useState<string>("");
  const form_ref = useRef<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (namespace === undefined) navigate("/chat");
  }, [namespace, navigate]);

  useEffect(() => {
    handleResetHistory();
  }, [handleResetHistory]);

  const onChangeInputTex = (event:any) => {
    setUserQuestion(event.target.value);
  };

  const onClickSendFirstMessage = (event: any) => {
    event.preventDefault();

    let question = trimStr(userQuestion);
    if (question !== "") {
      if (form_ref !== null) form_ref.current.reset();
      onSendFirstMessage(question);
      setUserQuestion('');
    }
  };

  const chatInput = (namespace !== undefined &&
    <Form
    ref={form_ref}
    style={{width: "70%", paddingLeft: "100px"}}
    onSubmit={(event:any) => onClickSendFirstMessage(event)}>
      <InputText
        hideAction={true}
        label=""
        style={{fontSize: "1rem"}}
        value={userQuestion}
        placeholder={t('views.chat.inputPlaceholder')}
        onChangeHandler={onChangeInputTex}
        headComponent={<div
          style={{
            height: ".75rem",
            width: ".75rem",
            marginLeft: "0.75rem",
            backgroundColor: namespace!.icon,
            borderRadius: 50,
          }}
        />}
        tailComponent={<Button
          variant={"primary"}
          outline={false}
          type="submit"
          style={{
            borderRadius: "50%",
            padding: "10px"
          }}
        >
          <LuSendHorizonal size={20} />
        </Button>}
      />
    </Form>
  );

  return (
    <Container maxWidth={false} className="chat-content">
      <Stack
        spacing={5}
        sx={{
          paddingLeft: "100px",
          height: "90vh",
          width: "70%",
          justifyContent: "center"
          }}>
        <Stack sx={{alignItems: "center", justifyContent: "center"}}>
          <img src={logo} alt="bot" width={50} height="auto" />
          <h1>{t("views.chat.backgroundText")}</h1>
        </Stack>
      </Stack>

      {chatInput}

    </Container>
  );
};