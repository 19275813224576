import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Order } from '../../components/table/TableOrder';
import EnhancedTableHead from '../../components/table/CustomTableHead';
import { DocumentWithStatus, DocumentWithStatusHeadCell } from '../../data-structures/DocumentType';
import Iconify from '../../components/iconify';

const headCells: readonly DocumentWithStatusHeadCell[] = [
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: "views.documents.list.table.status",
    width: "10%",
  },
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: "views.documents.list.table.documentName",
    width: "15%",
  },
  {
    id: 'namespace_name',
    numeric: false,
    disablePadding: false,
    label: "views.documents.list.table.namespace",
    width: "15%",
  },
  {
    id: 'user_id',
    numeric: false,
    disablePadding: false,
    label: "views.documents.list.sidebar.userInputLabel",
    width: "15%",
  },
  {
    id: 'start_upload_date',
    numeric: true,
    disablePadding: false,
    label: "views.documents.list.table.uploadedOn",
    width: "15%",
  },
  {
    id: 'end_upload_date',
    numeric: true,
    disablePadding: false,
    label: "views.documents.list.table.uploadTime",
    width: "10%",
  },
];

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

type Props = {
    data: DocumentWithStatus[];
};

export default function StatusTable({
    data,
  }: Props) {
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof DocumentWithStatus>('start_upload_date');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { t } = useTranslation();

  React.useEffect(() => {
    setOrder('desc');
    setOrderBy('start_upload_date');
    setPage(0);
  }, [data]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DocumentWithStatus,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(data, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, data],
  );

  return (
    <Box sx={{ width: '100%', border: "none", overflow: "auto", maxHeight: "450px" }}>
      <TableContainer sx={{ overflowX: 'initial' }}>
        <Table
          stickyHeader
          sx={{ minWidth: 750, tableLayout: "fixed" }}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
        >
          <EnhancedTableHead
            selectable={false}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            headCells={headCells}
          />
          <TableBody>
            {visibleRows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                >
                  <TableCell title={t(`views.documents.status.${row.status}`)} align="left">
                    <Stack direction={"row"} spacing={1}>
                      {row.status === "DONE" &&
                        <Iconify icon={"material-symbols:done"} sx={{color: "green"}}/>
                      }
                      {row.status === "ERROR" &&
                        <Iconify icon={"material-symbols-light:error"} sx={{color: "red"}}/>
                      }
                      {row.status === "PROCESSING" &&
                        <Iconify icon={"eos-icons:loading"} sx={{color: "yellow"}}/>
                      }
                      <Typography variant="body2" sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap"}}>
                        {t(`views.documents.status.${row.status}`)}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                    title={row.title} align="left">
                    <Typography variant="body2" sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap"}}>
                      {row.title}
                    </Typography>
                  </TableCell>
                  <TableCell title={row.namespace_name} align="left">
                    <Typography variant="body2" sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap"}}>
                      {row.namespace_name}
                    </Typography>
                  </TableCell>
                  <TableCell title={row.user_id} align="left">
                    <Typography variant="body2" sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap"}}>
                      {row.user_id}
                    </Typography>
                  </TableCell>
                  <TableCell title={dayjs(row.start_upload_date).format("YYYY-MM-DD HH:mm:ss")} align="left">
                    <Typography variant="body2" sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap"}}>
                      {dayjs(row.start_upload_date).format("YYYY-MM-DD HH:mm:ss")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    title={
                      row.end_upload_date === null ? "" :
                      `${dayjs(row.end_upload_date).diff(dayjs(row.start_upload_date), 'seconds')} s`
                      }
                    align="left">
                    {row.end_upload_date === null ? <Iconify icon={"eos-icons:loading"} /> :
                      <Typography variant="body2" sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap"}}>
                        {dayjs(row.end_upload_date).diff(dayjs(row.start_upload_date), 'seconds')} s
                      </Typography>
                    }
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("views.namespace.list.table.options.pagination")}
        sx={{'& .MuiTablePagination-toolbar': {alignItems: "baseline"}}}
      />
    </Box>
  );
}
