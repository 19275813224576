import { useState } from "react";
import { t } from "i18next";
import { Grid, Stack, TextField, Typography } from "@mui/material";
import { Button, Modal } from "../core/components/layout"
import styles from "../core/style/global/global.module.scss";


type Props = {
    showModal: boolean,
    onConfirm: (input: string) => void,
    onCloseModal?: () => void,
    title?: string,
    textList?: string[],
    singleButton?: boolean,
    button1Text?: string,
    button2Text?: string,
};

const UserInputModal = ({
    singleButton=true,
    button2Text='views.namespace.list.modals.delete.backButtonText',
    button1Text='views.namespace.list.modals.delete.deleteButtonText',
    showModal,
    title='',
    textList=[''],
    onConfirm,
    onCloseModal}: Props) => {

    const [input, setInput] = useState<string>("");

    const changeNameHandler = (event: any) => {
        setInput(event.target.value.trim());
    };

    return (
        <Modal visible={showModal} size="lg">
            <Stack p={2} spacing={5}>
                <Typography variant="body1" style={{color: styles.light, textAlign: "center"}}>{t(title)}</Typography>
                <Stack>
                    {textList.map((text, i) => (
                        <h6 key={i}>{t(text)}</h6>
                    ))}
                </Stack>
                <TextField
                autoComplete="off"
                onChange={changeNameHandler}
                variant="outlined"/>
                <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center">
                    <Grid item xs={3} sx={{display: "flex", justifyContent: "center"}}>
                    {!singleButton && (
                        <Button
                        text={t(button2Text)}
                        variant="primary" outline={true}
                        onClickHandler={onCloseModal}
                        style={{width: "8rem", marginRight: "1rem"}} />
                    )}
                    </Grid>
                    <Grid item xs={3} sx={{display: "flex", justifyContent: "center"}}>
                        <Button
                        text={t(button1Text)}
                        onClickHandler={() => onConfirm(input)}
                        style={{width: "8rem"}} />
                    </Grid>
                </Grid>
            </Stack>
        </Modal>
    );
}

export default UserInputModal;