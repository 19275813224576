//////////////////////////////////////////////
//////////////////TO IMPROVE//////////////////
//////////////////////////////////////////////

import { toast, Flip, Zoom, Slide, Bounce } from "react-toastify";
import { IoWarningOutline, IoInformationCircleOutline } from "react-icons/io5";
import { IoIosThumbsUp } from "react-icons/io";
import { FaFire } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";

toast.configure({});

const TOAST_LIST = new Set();
const MAX_TOAST_NUMBER = 3;


const Toast = (variant:string = "", text:string = "", animation = "") => {

    //Variant type
    const variant_types = ["INFO", "SUCCESS", "ERROR", "WARNING"];
    const variant_type = (variant_types.includes(variant.toUpperCase()) ? variant.toUpperCase() : "INFO");

    const switchIcon = (variant:string) => {
      switch(variant) {
        case "SUCCESS":
          return <IoIosThumbsUp />;
        case "ERROR":
          return <FaFire />;
        case "WARNING":
          return <IoWarningOutline />;  
        default:
          return <IoInformationCircleOutline />;
      }
  }

    const switchAnimation = (animation:any) => {
      switch(animation) {
        case "flip":
          return Flip;
        case "slide":
          return Slide;
        case "bounce":
          return Bounce; 
        case "zoom":
          return Zoom; 
        default:
          return Flip;
      }
    }

    if (TOAST_LIST.size < MAX_TOAST_NUMBER) {

        let toast_id:any = null;

        if(variant_type === "SUCCESS") {
          toast_id = toast.success(<div>{switchIcon(variant_type)} {text}</div>, {
            transition: switchAnimation(animation),
            onClose: () => TOAST_LIST.delete(toast_id)
          });
        }

        if(variant_type === "INFO") {
          toast_id = toast.info(<div>{switchIcon(variant_type)} {text}</div>, {
            transition: switchAnimation(animation),
            onClose: () => TOAST_LIST.delete(toast_id)
          });
        }

        if(variant_type === "WARNING") {
          toast_id = toast.warn(<div>{switchIcon(variant_type)} {text}</div>, {
            transition: switchAnimation(animation),
            onClose: () => TOAST_LIST.delete(toast_id)
          });
        }

        if(variant_type === "ERROR") {
          toast_id = toast.error(<div>{switchIcon(variant_type)} {text}</div>, {
            transition: switchAnimation(animation),
            onClose: () => TOAST_LIST.delete(toast_id)
          });
        }

        TOAST_LIST.add(toast_id);
    }

}

export default Toast;
