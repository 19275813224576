/**
  * @desc this function encrypts a string passed as input with 256 SHA encryption
  * 
  * @param string, string value
  * 
  * @return encoded object
*/
export const sha256Encode = (string:string) => {
    const encoder = new TextEncoder();
    let data = encoder.encode(string);
    return window.crypto.subtle.digest("SHA-256", data);
}
  
/**
  * @desc this function encrypts a string passed as input with base64 url encryption
  * 
  * @param string, string value
  * 
  * @return encoded string
*/
export const base64UrlEncode = (a:any) => {
    let str = "",
        bytes = new Uint8Array(a),
        len = bytes.byteLength;

    for (var i = 0; i < len; i++) {
        str += String.fromCharCode(bytes[i]);
    }

    return btoa(str)
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
}