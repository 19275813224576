import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Offcanvas  } from "react-bootstrap";
import { Button } from "../../../../core/components/layout";
import { Scrollbar } from "react-scrollbars-custom";
import Calendar from "react-calendar";
import {
    Filters,
    ShowFilterSidebar,
    OnSetFilters,
    OnSetShowFilterSidebar
} from "../../types";
import { BsCalendar4 } from "react-icons/bs";
import { CgCloseO } from "react-icons/cg";


const DEFAULT_FILTER = {
    date_start: new Date(),
    date_end: new Date(),
}


interface SidebarFiltersProps {
    filters: Filters,
    showFilterSidebar: ShowFilterSidebar,
    onSetFilters: OnSetFilters,
    onSetShowFilterSidebar: OnSetShowFilterSidebar
}


const SidebarFiltersComponent:React.FC<SidebarFiltersProps> = ({
        filters,
        showFilterSidebar,
        onSetFilters,
        onSetShowFilterSidebar,

    }) => {

    const {t, i18n} = useTranslation();
    const [advancedFilter, setAdvancedFilter] = useState<any>(DEFAULT_FILTER);

    /**
     * @descr
     * @param event
     */
    const onClickCloseFilter = (event:any) => {
        onSetShowFilterSidebar(false);
    }

    /**
     * @descr
     * @param field
     * @param data
     */
    const onChangeAdvancedFilter = (field:any, data:any) => {
        let advanced_fitlers = {...advancedFilter, [field]: data}
        setAdvancedFilter(advanced_fitlers);
    }

    /**
     *
     */
    const onClickConfirm = () => {
        onSetFilters(advancedFilter);
        onSetShowFilterSidebar(false);
    }

    /**
     *
     */
    const onClickBack = () => {
        onSetFilters({});
        setAdvancedFilter(DEFAULT_FILTER);
        onSetShowFilterSidebar(false);
    }

    useEffect (() => {
        if (Object.keys(filters).length > 0) setAdvancedFilter(filters);
        if (Object.keys(filters).length === 0) setAdvancedFilter(DEFAULT_FILTER);
    }, [filters]);


    return (
        <Offcanvas placement="end" show={showFilterSidebar} style={{zIndex: 99999}} onHide={onClickCloseFilter}>
            <Row style={{margin: "0 0.15rem", padding: "1rem 0 0.5rem 0", borderBottom: "1px solid #CCC"}}>
                <Col>
                    <h5>Filtra</h5>
                </Col>
                <Col style={{textAlign: "right"}}>
                    <Button onClickHandler={onClickCloseFilter} variant="light">
                        <CgCloseO style={{fontSize: 20}}/>
                    </Button>
                </Col>
            </Row>

            {/* Filters inputs */}
            <Scrollbar style={{height: "calc(100vh - 115px)"}}>
                <Row style={{margin: "15px 0 0 15px"}}>
                    <Col>
                        <small style={{fontSize: 12}}>
                            <BsCalendar4 style={{marginRight: ".3rem", fontSize: 14}}/>
                            <b>Da: </b>
                            {`${advancedFilter.date_start}`}
                        </small>
                    </Col>
                </Row>
                <Row style={{margin: "15px 15px"}}>
                    <Col>
                        <div style={{transform: "scale(0.8)"}}>
                            <Calendar
                                value={advancedFilter.date_start}
                                onChange={(e:any) => onChangeAdvancedFilter('date_start', e)}
                            />
                        </div>
                    </Col>
                </Row>
                <Row style={{margin: "0 15px"}}>
                    <Col >
                        <small style={{fontSize: 12}}>
                            <BsCalendar4 style={{marginRight: ".3rem", fontSize: 14}}/>
                            <b>A: </b>
                            {`${advancedFilter.date_end}`}
                        </small>
                    </Col>
                </Row>
                <Row style={{margin: "15px"}}>
                    <Col>
                        <div style={{transform: "scale(0.8)"}}>
                            <Calendar
                                value={advancedFilter.date_end}
                                onChange={(e:any) => onChangeAdvancedFilter('date_end', e)}
                            />
                        </div>
                    </Col>
                </Row>
            </Scrollbar>

            {/** Footer buttons */}
            <Row style={{marginTop: "15px"}}>
                <Col>
                    <Button variant="secondary" style={{color: "#FFF", width: "45%", marginLeft: "3%", marginRight: "2%"}} onClickHandler={() => onClickBack()}>
                        Elimina
                    </Button>
                    <Button variant="primary" style={{width: "45%", marginLeft: "2%"}} onClickHandler={() => onClickConfirm()}>
                        Fatto
                    </Button>
                </Col>
            </Row>
        </Offcanvas>
    );
}

export default SidebarFiltersComponent;