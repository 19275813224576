import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Toast } from "../../../core/components/layout";
import { ModalNamespaceDelete, ModalUsersConflict, ModalUsersDelete, SidebarFilter, SidebarFilterNamespaces, TableUsers, TableUsersNamespace } from "./components";
import {
    ActiveUser,
    Filters,
    OnAddUser,
    OnSetShowModalUsersDeletion,
    OnSetFilters,
    OnSetSelectedNamespaces,
    OnSetSelectedUsers,
    OnSetShowFilterSidebar,
    SelectedUsers,
    ShowFilterSidebar,
    UsersData,
    OnDeleteUsers,
    ShowModalUsersDeletion,
    NamespacesData,
    SelectedNamespaces,
    OnSetActiveUser,
    OnAddUserToNamespace,
    ShowFilterUserNamespacesSidebar,
    OnSetShowFilterUserNamespacesSidebar,
    ShowModalNamespaceDeletion,
    OnSetShowModalNamespaceDeletion,
    OnDeleteUserGrantsFromNamespaces,
    OnUpdateUserRoles,
    UserConflictedObject,
    OnSetShowModalUserConflict,
    ShowModalUserConflict,
    RequestError,
    RequestResponse,
    IsPendingRequest,
    NamespaceFilters,
    OnSetNamespaceFilters,
    UserInfo,
    OnSetShowModalUnregisteredUser,
} from "../types";
import { useTranslation } from "react-i18next";
import { store } from "../../../store/app.store";



interface UsersProps {
    isPendingRequest: IsPendingRequest,
    requestError: RequestError,
    requestResponse: RequestResponse,
    usersData: UsersData,
    namespacesData: NamespacesData,
    activeUser: ActiveUser,
    userConflictedObject: UserConflictedObject,
    selectedUsers: SelectedUsers,
    selectedNamespaces: SelectedNamespaces,
    showFilterSidebar: ShowFilterSidebar,
    showFilterUserNamespacesSidebar: ShowFilterUserNamespacesSidebar,
    showModalUserConflict: ShowModalUserConflict,
    showModalUsersDeletion: ShowModalUsersDeletion,
    showModalNamespaceDeletion: ShowModalNamespaceDeletion,
    userInfo: UserInfo,
    filters: Filters,
    namespaceFilters: NamespaceFilters,
    requestSetActiveUser: OnSetActiveUser,
    requestAddUser: OnAddUser,
    requestUpdateUserRoles: OnUpdateUserRoles,
    requestDeleteUsers: OnDeleteUsers,
    requestDeleteUserGrantsFromNamespaces: OnDeleteUserGrantsFromNamespaces,
    requestSetFilters: OnSetFilters,
    requestSetNamespaceFilters: OnSetNamespaceFilters,
    requestSetSelectedUsers: OnSetSelectedUsers
    requestSetSelectedNamespaces: OnSetSelectedNamespaces,
    requestSetShowFilterSidebar: OnSetShowFilterSidebar,
    requestSetShowFilterUserNamespacesSidebar: OnSetShowFilterUserNamespacesSidebar,
    requestSetShowModalUnregisteredUser: OnSetShowModalUnregisteredUser,
    requestSetShowModalUserConflict: OnSetShowModalUserConflict,
    requestSetShowModalUsersDeletion: OnSetShowModalUsersDeletion,
    requestSetShowModalNamespaceDeletion: OnSetShowModalNamespaceDeletion
    requestAddUserToNamespace: OnAddUserToNamespace,
}

const UsersContent:React.FC<UsersProps> = ({
    isPendingRequest,
    requestError,
    requestResponse,
    usersData,
    namespacesData,
    activeUser,
    userConflictedObject,
    selectedUsers,
    selectedNamespaces,
    showFilterSidebar,
    showFilterUserNamespacesSidebar,
    showModalUserConflict,
    showModalUsersDeletion,
    showModalNamespaceDeletion,
    filters,
    namespaceFilters,
    userInfo,
    requestSetActiveUser,
    requestAddUser,
    requestUpdateUserRoles,
    requestDeleteUsers,
    requestDeleteUserGrantsFromNamespaces,
    requestSetFilters,
    requestSetNamespaceFilters,
    requestSetSelectedUsers,
    requestSetSelectedNamespaces,
    requestSetShowFilterSidebar,
    requestSetShowFilterUserNamespacesSidebar,
    requestSetShowModalUnregisteredUser,
    requestSetShowModalUserConflict,
    requestSetShowModalUsersDeletion,
    requestSetShowModalNamespaceDeletion,
    requestAddUserToNamespace,

}) => {
    const [orderedData, setOrderedData] = useState([]);
    const { t } = useTranslation();

    /**
     * useEffect callback used to display a Toast with success or error message
    **/
	useEffect (() => {
        let data: UsersData = store.getState().users.usersData;
        setOrderedData(data.map((n: any, i: any) => ({...n, id: i})));
		if (requestError !== null) {
            const {code, type} = requestError;
            if (code === 401 && userInfo === null) { requestSetShowModalUnregisteredUser(true); }
            if (userInfo !== null) { Toast("error", t(`errors.users.${type}.${code}`)); }
        }
        if (requestResponse !== null) {
            const {code, type} = requestResponse;
            Toast("success", t(`errors.users.${type}.${code}`));
        }
	}, [requestError, requestResponse, usersData, userInfo]);

    return (
        <Container fluid={true} className="document-content">
            <Row className="justify-content-md-center">
                {(activeUser === null) &&
                    <Col lg={10} md={10} style={{paddingTop: "2rem"}}>
                        <TableUsers
                            isPendingRequest={isPendingRequest}
                            usersData={orderedData}
                            selectedUsers={selectedUsers}
                            filters={filters}
                            onSetActiveUser={requestSetActiveUser}
                            onSetShowFilterSidebar={requestSetShowFilterSidebar}
                            onAddUser={requestAddUser}
                            onSetFilters={requestSetFilters}
                            onSetSelectedUsers={requestSetSelectedUsers}
                            onSetShowModalUsersDeletion={requestSetShowModalUsersDeletion}
                        />
                    </Col>
                }
            </Row>
            <Row className="justify-content-md-center">
                {(activeUser !== null) &&
                    <Col lg={10} md={10} style={{paddingTop: "2rem"}}>
                        <TableUsersNamespace
                            isPendingRequest={isPendingRequest}
                            filters={namespaceFilters}
                            namespacesData={namespacesData}
                            activeUser={activeUser}
                            selectedNamespaces={selectedNamespaces}
                            onSetActiveUser={requestSetActiveUser}
                            onSetFilters={requestSetNamespaceFilters}
                            onAddUserToNamespace={requestAddUserToNamespace}
                            onUpdateUserRoles={requestUpdateUserRoles}
                            onSetSelectedNamespaces={requestSetSelectedNamespaces}
                            onSetShowFilterUserNamespacesSidebar={requestSetShowFilterUserNamespacesSidebar}
                            onSetShowModalNamespaceDeletion={requestSetShowModalNamespaceDeletion}
                        />
                    </Col>
                }
            </Row>

            {/* Advanced filters */}
            <SidebarFilter
                showFilterSidebar={showFilterSidebar}
                filters={filters}
                onSetFilters={requestSetFilters}
                onSetShowFilterSidebar={requestSetShowFilterSidebar}
            />

            {/* Advanced user namespaces filters */}
            <SidebarFilterNamespaces
                filters={namespaceFilters}
                showFilterUserNamespacesSidebar={showFilterUserNamespacesSidebar}
                onSetFilters={requestSetNamespaceFilters}
                onSetShowFilterUserNamespacesSidebar={requestSetShowFilterUserNamespacesSidebar}
            />

            {/* Modal user name conflicts */}
            <ModalUsersConflict
                showModalUserConflict={showModalUserConflict}
                userConflictedObject={userConflictedObject}
                onSetShowModalUserConflict={requestSetShowModalUserConflict}
            />

            {/* Modal selected users deletion */}
            <ModalUsersDelete
                showModalUsersDeletion={showModalUsersDeletion}
                selectedUsers={selectedUsers}
                onDeleteUsers={requestDeleteUsers}
                onSetShowModalUsersDeletion={requestSetShowModalUsersDeletion}
            />

            {/* Modal selected active user deletion */}
            <ModalNamespaceDelete
                showModalNamespaceDeletion={showModalNamespaceDeletion}
                activeUser={activeUser}
                selectedNamespaces={selectedNamespaces}
                onDeleteUserGrantsFromNamespaces={requestDeleteUserGrantsFromNamespaces}
                onSetShowModalNamespaceDeletion={requestSetShowModalNamespaceDeletion}
            />

        </Container>
    );
}

export default UsersContent;