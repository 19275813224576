import { connect } from "react-redux";
import { RootState } from "../../../../store/app.store";
import { 
    requestGetUsersData,
    requestAddUser,
    requestDeleteUsers,
    requestUpdateUserRoles,
    requestDeleteUserGrantsFromNamespaces,
    requestSetSelectedUsers,
    requestSetSelectedNamespaces,
    requestSetShowModalUserConflict,
    requestSetShowModalUsersDeletion,
    requestSetShowModalNamespaceDeletion,
    requestSetShowFilterSidebar,
    requestSetShowFilterUserNamespacesSidebar,
    requestSetShowModalUnregisteredUser,
    requestSetFilters,
    requestSetNamespaceFilters,
    requestSetActiveUser,
    requestAddUserToNamespace,
    requestSetUserSession,
    requestDeleteAllChat,
} from "../users.effects";


const withUsers = connect(
    (state:RootState) => state.users, 
    {
        requestGetUsersData,
        requestAddUser,
        requestDeleteUsers,
        requestUpdateUserRoles,
        requestDeleteUserGrantsFromNamespaces,
        requestSetSelectedNamespaces,
        requestSetShowModalUserConflict,
        requestSetShowModalUsersDeletion,
        requestSetShowModalNamespaceDeletion,
        requestSetSelectedUsers,
        requestSetShowFilterSidebar,
        requestSetShowFilterUserNamespacesSidebar,
        requestSetShowModalUnregisteredUser,
        requestSetFilters,
        requestSetNamespaceFilters,
        requestSetActiveUser,
        requestAddUserToNamespace,
        requestSetUserSession,
        requestDeleteAllChat,
    }
);

export default withUsers;