import { Col, Container, Row } from "react-bootstrap";
import { SidebarFilter, TableNamespace, ModalNamespacesDelete, ModalNamespaceCreation, ModalNamespaceMetadata, TableNamespaceUsersRole, ModalNamespaceUsersDelete, SidebarFilterUsers, ModalNamespaceConflict, } from "./components";
import {
    ActiveNamespace,
    Filters,
    IsPendingRequest,
    NamespaceConflictObject,
    NamespacesData,
    NamespacesIcon,
    OnAddNamespace,
    OnAddNamespaceUser,
    OnDeleteNamespace,
    OnDeleteUsersGrantsFromNamespace,
    OnSetActiveNamespace,
    OnSetFilters,
    OnSetSelectedNamespaces,
    OnSetSelectedUsers,
    OnSetShowFilterSidebar,
    OnSetShowFilterUsersSidebar,
    OnSetShowModalNamespaceConflict,
    OnSetShowModalNamespaceCreation,
    OnSetShowModalNamespaceDeletion,
    OnSetShowModalNamespaceMetadata,
    OnSetShowModalNamespaceUsersDeletion,
    OnSetShowModalUnregisteredUser,
    OnSetUserFilters,
    OnUpdateNamespaceMetadata,
    OnUpdateNamespaceUsersGrant,
    RequestError,
    RequestResponse,
    SelectedNamespaces,
    SelectedUsers,
    ShowFilterSidebar,
    ShowFilterUsersSidebar,
    ShowModalNamespaceConflict,
    ShowModalNamespaceCreation,
    ShowModalNamespaceDeletion,
    ShowModalNamespaceMetadata,
    ShowModalNamespaceUsersDeletion,
    UserFilters,
    UserInfo,
    UsersData
} from "../types";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Toast } from "../../../core/components/layout";
import { store } from "../../../store/app.store";

interface NamespaceProps {
    isPendingRequest: IsPendingRequest,
    requestError: RequestError,
    requestResponse: RequestResponse,
    showFilterSidebar: ShowFilterSidebar,
    showFilterUsersSidebar: ShowFilterUsersSidebar,
    showModalNamespaceConflict: ShowModalNamespaceConflict,
    showModalNamespaceCreation: ShowModalNamespaceCreation,
    showModalNamespaceMetadata: ShowModalNamespaceMetadata,
    showModalNamespaceDeletion: ShowModalNamespaceDeletion,
    showModalNamespaceUsersDeletion: ShowModalNamespaceUsersDeletion,
    usersData: UsersData,
    namespacesData: NamespacesData,
    namespacesIcon: NamespacesIcon,
    activeNamespace: ActiveNamespace,
    selectedNamespaces: SelectedNamespaces,
    selectedUsers: SelectedUsers,
    namespaceConflictObject: NamespaceConflictObject,
    filters: Filters,
    userFilters: UserFilters,
    userInfo: UserInfo,
    requestSetFilters: OnSetFilters,
    requestSetShowFilterSidebar: OnSetShowFilterSidebar,
    requestSetShowFilterUsersSidebar: OnSetShowFilterUsersSidebar,
    requestSetSelectedNamespaces: OnSetSelectedNamespaces,
    requestSetSelectedUsers: OnSetSelectedUsers,
    requestSetShowModalNamespaceConflict: OnSetShowModalNamespaceConflict,
    requestSetShowModalNamespaceCreation: OnSetShowModalNamespaceCreation,
    requestSetShowModalNamespaceMetadata: OnSetShowModalNamespaceMetadata,
    requestSetShowModalNamespaceDeletion: OnSetShowModalNamespaceDeletion,
    requestSetShowModalNamespaceUsersDeletion: OnSetShowModalNamespaceUsersDeletion,
    requestSetShowModalUnregisteredUser: OnSetShowModalUnregisteredUser,
    requestAddNamespace: OnAddNamespace,
    requestAddNamespaceUser: OnAddNamespaceUser,
    requestUpdateNamespaceUsersGrant: OnUpdateNamespaceUsersGrant,
    requestDeleteNamespace: OnDeleteNamespace,
    requestDeleteUsersGrantsFromNamespace: OnDeleteUsersGrantsFromNamespace,
    requestUpdateNamespaceMetadata: OnUpdateNamespaceMetadata,
    requestSetActiveNamespace: OnSetActiveNamespace,
    requestSetUserFilters: OnSetUserFilters
}

const NamespaceContent:React.FC<NamespaceProps> = ({
    isPendingRequest,
    requestError,
    requestResponse,
    showFilterSidebar,
    showFilterUsersSidebar,
    showModalNamespaceConflict,
    showModalNamespaceCreation,
    showModalNamespaceMetadata,
    showModalNamespaceDeletion,
    showModalNamespaceUsersDeletion,
    usersData,
    namespacesData,
    namespacesIcon,
    activeNamespace,
    selectedNamespaces,
    selectedUsers,
    namespaceConflictObject,
    filters,
    userFilters,
    userInfo,
    requestSetFilters,
    requestSetShowFilterSidebar,
    requestSetShowFilterUsersSidebar,
    requestSetSelectedNamespaces,
    requestSetSelectedUsers,
    requestSetShowModalNamespaceConflict,
    requestSetShowModalNamespaceCreation,
    requestSetShowModalNamespaceMetadata,
    requestSetShowModalNamespaceDeletion,
    requestSetShowModalNamespaceUsersDeletion,
    requestSetShowModalUnregisteredUser,
    requestAddNamespace,
    requestAddNamespaceUser,
    requestUpdateNamespaceUsersGrant,
    requestDeleteNamespace,
    requestDeleteUsersGrantsFromNamespace,
    requestUpdateNamespaceMetadata,
    requestSetActiveNamespace,
    requestSetUserFilters

}) => {
    const [orderedData, setOrderedData] = useState([]);
    const [orderedNamespace, setOrderedNamespace] = useState<any[] | null>([]);
    const { t } = useTranslation();

    /**
     * useEffect callback used to display a Toast with success or error message
    **/
	useEffect (() => {
        let data: NamespacesData = store.getState().namespace.namespacesData;
        setOrderedData(data.map((n: any, i: any) => ({...n, id: i})));
		if (requestError !== null) {
            const {code, type} = requestError;
            if (code === 401 && userInfo === null) { requestSetShowModalUnregisteredUser(true); }
            if (userInfo !== null) { Toast("error", t(`errors.namespace.${type}.${code}`)); }
        }
        if (requestResponse !== null) {
            const {code, type} = requestResponse;
            Toast("success", t(`errors.namespace.${type}.${code}`));
        }
	}, [requestError, requestResponse, namespacesData, userInfo, requestSetShowModalUnregisteredUser, t, requestSetShowModalUnregisteredUser]);

    useEffect(() => {
        if (activeNamespace !== null && activeNamespace.users !== undefined) {
            setOrderedNamespace({
                ...activeNamespace,
                users: activeNamespace.users.map((n: any, i: any) => ({...n, id: i}))
            });
        } else {
            setOrderedNamespace(null);
        }
    }, [activeNamespace]);
    return (
        <Container fluid={true} className="document-content">
            <Row className="justify-content-md-center">
                {(activeNamespace === null) &&
                    <Col lg={11} md={11} style={{paddingTop: "2rem"}}>
                        <TableNamespace
                            isPendingRequest={isPendingRequest}
                            filters={filters}
                            namespacesData={orderedData}
                            selectedNamespaces={selectedNamespaces}
                            onSetShowFilterSidebar={requestSetShowFilterSidebar}
                            onSetFilters={requestSetFilters}
                            onSetSelectedNamespaces={requestSetSelectedNamespaces}
                            onSetActiveNamespace={requestSetActiveNamespace}
                            onSetShowModalNamespaceDeletion={requestSetShowModalNamespaceDeletion}
                            onSetShowModalNamespaceCreation={requestSetShowModalNamespaceCreation}
                            onSetShowModalNamespaceMetadata={requestSetShowModalNamespaceMetadata}
                        />
                    </Col>
                }
            </Row>
            <Row className="justify-content-md-center">
                {(activeNamespace !== null) && (!showModalNamespaceMetadata) && (!showModalNamespaceConflict) &&
                    <Col lg={10} md={10} style={{paddingTop: "2rem"}}>
                        <TableNamespaceUsersRole
                            isPendingRequest={isPendingRequest}
                            userFilters={userFilters}
                            namespacesData={orderedData}
                            usersData={usersData}
                            activeNamespace={orderedNamespace}
                            selectedUsers={selectedUsers}
                            onSetShowFilterUsersSidebar={requestSetShowFilterUsersSidebar}
                            onSetActiveNamespace={requestSetActiveNamespace}
                            onSetUserFilters={requestSetUserFilters}
                            onSetSelectedUsers={requestSetSelectedUsers}
                            onSetShowModalNamespaceUsersDeletion={requestSetShowModalNamespaceUsersDeletion}
                            onAddNamespaceUser={requestAddNamespaceUser}
                            onUpdateNamespaceUsers={requestUpdateNamespaceUsersGrant}
                        />
                    </Col>
                }
            </Row>

            {/* Advanced filters */}
            <SidebarFilter
                showFilterSidebar={showFilterSidebar}
                filters={filters}
                onSetFilters={requestSetFilters}
                onSetShowFilterSidebar={requestSetShowFilterSidebar}
            />

            {/* Advanced namespace users filters */}
            <SidebarFilterUsers
                userFilters={userFilters}
                showFilterUsersSidebar={showFilterUsersSidebar}
                onSetUserFilters={requestSetUserFilters}
                onSetShowFilterUsersSidebar={requestSetShowFilterUsersSidebar}
            />

            {/* Modal namespace name conflict creation */}
            <ModalNamespaceConflict
                showModalNamespaceConflict={showModalNamespaceConflict}
                namespaceConflictObject={namespaceConflictObject}
                onSetShowModalNamespaceCreation={requestSetShowModalNamespaceCreation}
                onSetShowModalNamespaceConflict={requestSetShowModalNamespaceConflict}
                onSetShowModalNamespaceMetadata={requestSetShowModalNamespaceMetadata}
            />

            {/* Modal delete document */}
            <ModalNamespacesDelete
                showModalNamespaceDeletion={showModalNamespaceDeletion}
                selectedNamespaces={selectedNamespaces}
                onDeleteNamespace={requestDeleteNamespace}
                onSetShowModalNamespaceDeletion={requestSetShowModalNamespaceDeletion}
            />

            {/* Modal namespace creation */}
            <ModalNamespaceCreation
                isPendingRequest={isPendingRequest}
                namespacesIcon={namespacesIcon}
                namespacesData={namespacesData}
                namespaceConflictObject={namespaceConflictObject}
                showModalNamespaceCreation={showModalNamespaceCreation}
                onAddNamespace={requestAddNamespace}
                onSetShowModalNamespaceCreation={requestSetShowModalNamespaceCreation}
            />

            {/* Modal namespace metadata update */}
            <ModalNamespaceMetadata
                namespacesIcon={namespacesIcon}
                activeNamespace={activeNamespace}
                namespacesData={namespacesData}
                namespaceConflictObject={namespaceConflictObject}
                showModalNamespaceMetadata={showModalNamespaceMetadata}
                onUpdateNamespaceMetadata={requestUpdateNamespaceMetadata}
                onSetShowModalNamespaceMetadata={requestSetShowModalNamespaceMetadata}
            />

            {/* Modal namespace users deletion */}
            <ModalNamespaceUsersDelete
                showModalNamespaceUsersDeletion={showModalNamespaceUsersDeletion}
                activeNamespace={activeNamespace}
                selectedUsers={selectedUsers}
                onDeleteUsers={requestDeleteUsersGrantsFromNamespace}
                onSetShowModalNamespaceUsersDeletion={requestSetShowModalNamespaceUsersDeletion}
            />

        </Container>
    );
}

export default NamespaceContent;