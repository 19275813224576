import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export type DocumentWithStatus = {
    id: number;
    namespace_name: string;
    _id_namespace: string;
    title: string;
    user_id: string;
    status: DocumentStatus;
    start_upload_date: string;
    end_upload_date: string;
};

export type DocumentWithStatusList = {
    documents: DocumentWithStatus[];
};

export interface DocumentWithStatusHeadCell {
    disablePadding: boolean;
    id: keyof DocumentWithStatus;
    label: string;
    numeric: boolean;
    width: string;
};

export interface TableData {
    id: number;
    namespace_name: string;
    _id_namespace: string;
    description: string;
    original_link: string;
    title: string;
    user_id: string;
    upload_date: string;
};

export interface DocumentHeadCell {
    disablePadding: boolean;
    id: keyof TableData;
    label: string;
    numeric: boolean;
    width: string;
};

export type DocumentList = {
    documents: TableData[];
};

export type DocumentStatus = "PROCESSING" | "DONE" | "ERROR";

export type DocumentFilter = {
    title: string | undefined,
    from_date: undefined | string,
    to_date: undefined | string,
    status: DocumentStatus[] | undefined,
    _id_namespaces: string[] | undefined,
    namespaces_names: string[] | undefined,
    users: string[] | undefined,
};

dayjs.extend(utc)
dayjs.extend(timezone)
export const DEFAULT_FILTER: DocumentFilter = {
    namespaces_names: undefined,
    title: undefined,
    from_date: dayjs().tz('Europe/Rome').subtract(7, "days").startOf("day").format(),
    to_date: dayjs().tz('Europe/Rome').endOf("day").format(),
    status: undefined,
    _id_namespaces: undefined,
    users: undefined,
};