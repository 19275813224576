import { useEffect, useRef, useState } from "react";
import React from "react";
import { IoStopOutline } from "react-icons/io5";
import { Box, Container, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FaCircle, FaRegUser } from "react-icons/fa";
import Markdown from "react-markdown";
import { Accordion, Form, Spinner } from "react-bootstrap";
import { LuFileSearch2, LuSendHorizonal } from "react-icons/lu";
import { AiOutlineExport } from "react-icons/ai";
import { Button, Loader } from "../../core/components/layout";
import { InputText } from "../../core/components/form";
import { Namespace } from "../../data-structures/NamespaceType";
import logo from "../../core/images/HYPERCHAT_LOGO_pittogramma.svg";
import { BotMessage, Chunks, Message } from "../../data-structures/ChatType";
import "./chat.scss";
import { trimStr } from "../../core/services";

type Props = {
  messages: (Message | BotMessage)[];
  chatId: string;
  namespace: Namespace | undefined;
  streaming: "stop" | "streaming" | "pending";
  onSendMessage: (message: string, chatId: string) => void;
  stopStreaming: (chatId: string, messageId: string) => void;
};

export default function ChatContent({ messages, chatId, namespace, streaming, stopStreaming, onSendMessage }: Props) {
  const { t } = useTranslation();
  const [chunksIndex, setChunksIndex] = useState<number[]>([]);
  const [userQuestion, setUserQuestion] = useState<string>("");
  const form_ref = useRef<any>(null);
  const lastMessageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.requestAnimationFrame(() =>
      lastMessageRef?.current?.scrollIntoView({ behavior: 'smooth' }));
  }, [messages]);

  const onChangeInputTex = (event:any) => {
    setUserQuestion(event.target.value);
  };

  const onClickSendRequest = (event: any, action: string) => {
    event.preventDefault();
    if(action === "send") {
      let question = trimStr(userQuestion);
      if (question !== "" && chatId !== null) {
        if (form_ref !== null) form_ref.current.reset();
        onSendMessage(question, chatId);
        setUserQuestion('');
      }
    }
    if(action === "stop") {
      stopStreaming(chatId, messages.pop()!.message_id);
    }
  };

  const handleDownload = (event:any, chunk: Chunks) => {
    event?.preventDefault();
    if (chunk.document_type === "application/pdf" && chunk.document_page !== null) {
      window.open(`${chunk.document_original_link}#page=${chunk.document_page}`, '_blank');
    } else {
      window.open(chunk.document_original_link!, '_blank');
    };
  }

  const filterChunksIndex = (index:number) => {
    let chunks_index:any = [];
    if (!chunksIndex.includes(index)) chunks_index = chunksIndex.concat(index);
    if (chunksIndex.includes(index)) chunks_index = chunksIndex.filter((item:any) => item !== index);
    setChunksIndex(chunks_index);
  }

  const messageTexts = messages.map((message: Message | BotMessage, index) => {
    return (
      <div key={index}>
        {message.message_author !== "CHATBOT" ?
          <Grid container>
            <Grid item xs={2}>
              <FaRegUser style={{fontSize: 50, color: "#343a40", background: "#efefef", padding: "1rem", borderRadius: 50}} />
            </Grid>
            <Grid item xs={10}>
              <p className="text-start" style={{marginTop: "15px"}}>
                {message.message_text}
              </p>
            </Grid>
          </Grid>
          :
          <Stack>
            <Grid container>
              <Grid item xs={2}>
                <img src={logo} alt="bot" width={50} height="auto" />
              </Grid>
              <Grid item xs={10} sx={message.message_text === "" ? {display: "flex", alignItems: "center"} : {}}>
                {(message.message_text === "" && streaming === "pending") ? <div className="chat-loader" /> :
                <Markdown>{message.message_text}</Markdown>
                }
              </Grid>
            </Grid>
            {('chunks' in message) && message.chunks !== undefined && message.chunks.length > 0 &&
            <Grid container>
              <Grid item xs={10} sx={{marginLeft: "auto"}}>
                <Stack>
                  <Button style={{alignSelf: "flex-start"}} variant="light" outline={true} onClickHandler={() => filterChunksIndex(index)}>
                    <LuFileSearch2 className={message.chunks !== undefined && message.chunks.length > 0 ? "icon active" : "icon"} />
                  </Button>
                  {chunksIndex.includes(index) &&
                    <Accordion flush style={{marginTop: "1rem", backgroundColor: "rgb(233, 236, 239)"}}>
                      {message.chunks.map((chunk: Chunks, index_chunk:number) => {
                        return(
                          <Accordion.Item style={{}} eventKey={index_chunk.toString()} key={index_chunk}>
                            <Accordion.Header>
                              <Grid container sx={{alignItems: "center", gap: "5px"}}>
                                <Grid item>
                                  <small style={{color: "black"}}>
                                    <b>{chunk.document_title}</b>
                                  </small>
                                </Grid>
                                <Grid item>
                                {(chunk.document_original_link !== undefined && chunk.document_original_link !== null) &&
                                  <Button
                                  variant="light"
                                  disabled={chunk.document_original_link === null}
                                  style={{borderColor: "transparent"}} onClickHandler={(e:any) =>
                                    handleDownload(e, chunk)}>
                                    <AiOutlineExport
                                    style={{color: chunk.document_original_link !== null ? "#00ccff" : "#C3C3C3"}} />
                                  </Button>
                                }
                                </Grid>
                              </Grid>
                            </Accordion.Header>
                            <Accordion.Body>
                              <small style={{fontSize: "0.75rem"}}>
                                {chunk.chunk_content}
                              </small>
                            </Accordion.Body>
                          </Accordion.Item>
                        )
                      })}
                    </Accordion>
                  }
                </Stack>
              </Grid>
            </Grid>
            }
          </Stack>
        }
      </div>
    );
  });

  const headComponend = (
    <React.Fragment>
      {streaming !== "stop"  &&
        <Spinner variant="primary" size="sm" style={{marginLeft: "0.75rem"}}/>
      }
      {(namespace !== undefined && streaming === "stop") &&
        <div
          style={{
            height: ".75rem",
            width: ".75rem",
            marginLeft: "0.75rem",
            backgroundColor: namespace.icon,
            borderRadius: 50,
          }}
        />
      }
    </React.Fragment>
  );

  const tailComponent = (
    <React.Fragment>
      {streaming !== "streaming" &&
        <Button
          variant={chatId === null ? "secondary" : "primary"}
          outline={chatId === null ? true : false}
          disabled={streaming === "pending"}
          type="submit"
          style={{
            borderRadius: "50%",
            padding: "10px"
          }}
        >
          <LuSendHorizonal size={20} />
        </Button>
      }
      {streaming === "streaming" &&
        <Button
          variant="primary"
          outline={true}
          disabled={chatId === null}
          onClickHandler={(event:any) => onClickSendRequest(event, streaming !== "streaming" ? "send" : "stop")}
          style={{
              borderRadius: "50%",
              padding: "10px"
          }}
        >
          <IoStopOutline size={20} />
        </Button>
      }
    </React.Fragment>
  );

  const chatInput = (
    <Form
    ref={form_ref}
    style={{width: "70%", paddingLeft: "100px"}}
    onSubmit={(event:any) => onClickSendRequest(event, streaming !== "streaming" ? "send" : "stop")}>
      <InputText
        hideAction={true}
        label=""
        style={{fontSize: "1rem"}}
        value={userQuestion}
        disabled={streaming !== "stop"}
        placeholder={t('views.chat.inputPlaceholder')}
        onChangeHandler={onChangeInputTex}
        headComponent={headComponend}
        tailComponent={tailComponent}
      />
    </Form>
  );

  return (
    <Container maxWidth={false} className="chat-content">
      {(namespace === undefined || messages === undefined) ? <Loader absolute={true} /> :
        <>
          <Stack
            spacing={5}
            sx={{
              paddingLeft: "100px",
              height: "90vh",
              width: "70%",
              justifyContent: "center"
              }}>

            {(messages === undefined || messages.length === 0) &&
            <Stack sx={{alignItems: "center", justifyContent: "center"}}>
              <img src={logo} alt="bot" width={50} height="auto" />
              <h1>{t("views.chat.backgroundText")}</h1>
            </Stack>
            }

            {(namespace !== undefined && messages !== undefined && messages.length > 0) &&
            <>
              <Stack>
                <h3 style={{fontSize: "1.3rem"}}>
                  <FaCircle style={{marginRight: ".5rem", marginBottom: ".3rem", color: namespace.icon}} />
                  {namespace.name}
                </h3>
              </Stack>
              <Box
              sx={{
                scrollbarGutter: "stable",
                paddingRight: "30px",
                height: "calc(-200px + 100vh)",
                overflow: "auto",
                '&::-webkit-scrollbar': {
                  width: '6px'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  borderRadius: '6px',
                }
              }}>
                <Stack spacing={3} sx={{paddingRight: "10px"}}>
                  {messageTexts}
                  <div ref={lastMessageRef} style={{height: "5px"}}/>
                </Stack>
              </Box>
            </>
            }
          </Stack>

          {chatInput}

        </>
      }
    </Container>
  );
};