import Box from '@mui/material/Box';
import Header from './header';

type Props = {
  children: React.ReactNode;
};

export default function MainLayout({ children }: Props) {

  return (
    <>
      <Header />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {children}
      </Box>
    </>
  );
}
